/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import Switch from "react-switch";
import { Button } from "@material-ui/core";
import { useForm, useWatch } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import { registerLocale } from "react-datepicker";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import config from "react-global-configuration";
import moment from "moment";
import th from "date-fns/locale/th";
registerLocale("th", th);

const MissionForm = () => {
  const [keycloak] = useKeycloak();
  const { register, handleSubmit, setValue, control } = useForm();
  const status = useWatch({ control, name: "active" });
  var calendarThai = new Date(
    new Date().getFullYear() + 543,
    new Date().getMonth(),
    new Date().getDate()
  );

  const history = useHistory();
  const id = history.location.state?.id;

  const [startDate, setStartDate] = useState(calendarThai);
  const [endDate, setEndDate] = useState(calendarThai);

  const [listEmpMission, setListEmpMission] = useState([]);
  const [listEmpMissionTempDel, setListEmpMissionTempDel] = useState([]);
  const [listEmpSearch, setListEmpSearch] = useState([]);

  const [textMsg, setTextMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const handleClose = () => setShowMsg(false);
  const handleShow = () => setShowMsg(true);

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview("init");
    dateChange();
    // leaveSection();
    fetchData();
  }, [keycloak.token]);

  const fetchData = async () => {
    const rsDataSearch = await axios.get(
      config.get("apiUrl") + "/api/master/mission/employee/search",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (rsDataSearch.data.status === "success") {
      setListEmpSearch(rsDataSearch.data.data);
    }

    if (id) {
      let rsData = await axios.get(
        config.get("apiUrl") + "/api/master/mission/detail/" + id,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );

      setValue("id", rsData.data.masMisId);
      setValue("name", rsData.data.masMisName);
      setValue("nameThShort", rsData.data.nameThShort);
      setValue("nameEn", rsData.data.nameEn);
      setValue("nameEnShort", rsData.data.nameEnShort);

      setValue(
        "start_date",
        rsData.data.startDate
          ? moment(rsData.data.startDate).add(+543, "year").format("DD/MM/YYYY")
          : null
      );
      setValue(
        "end_date",
        rsData.data.endDate
          ? moment(rsData.data.endDate).add(+543, "year").format("DD/MM/YYYY")
          : null
      );
      setValue("active", rsData.data.masMisActive == "1" ? true : false);

      let rsDataEmpMM = await axios.get(
        config.get("apiUrl") + "/api/emp-mission/get/" + id,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      setListEmpMission(rsDataEmpMM.data);
    }
  };

  const dateChange = async () => {
    $(".start_date")
      .datepicker({
        todayBtn: false,
        autoclose: true,
        format: "dd/mm/yyyy",
        language: "th",
        thaiyear: true,
      })
      .datepicker("setDate", new Date())
      .on("changeDate", function (selected) {
        var minDate = new Date(selected.date.valueOf());
        minDate = new Date(
          minDate.getFullYear(),
          minDate.getMonth(),
          minDate.getDate()
        );
        var eDate = new Date($(".end_date").datepicker("getDate"));
        eDate = new Date(
          eDate.getFullYear(),
          eDate.getMonth(),
          eDate.getDate()
        );
        $(".end_date").datepicker("setStartDate", minDate);
        $(".end_date").datepicker("setDate", eDate < minDate ? minDate : eDate);
        setStartDate($(".start_date").val());
        // leaveSection();
      });
    $(".end_date")
      .datepicker({
        todayBtn: false,
        autoclose: true,
        format: "dd/mm/yyyy",
        language: "th",
        thaiyear: true,
      })
      .datepicker("setStartDate", new Date())
      .datepicker("setDate", new Date())
      .on("changeDate", function (selected) {
        setEndDate($(".end_date").val());
        // leaveSection();
      });
  };

  const onSubmit = async (data) => {
    console.log(data);
    let params = {
      mission_id: parseInt(data.id),
      name: data.name,
      nameEn: data.nameEn,
      nameThShort: data.nameThShort,
      nameEnShort: data.nameEnShort,
      start_date: moment(data.start_date, "DD/MM/YYYY")
        .add(-543, "year")
        .format("YYYY-MM-DD"),
      end_date: moment(data.end_date, "DD/MM/YYYY")
        .add(-543, "year")
        .format("YYYY-MM-DD"),
      active: data.active ? "1" : "0",
      mission_del: listEmpMissionTempDel,
      mission: listEmpMission,
    };

    try {
      let rsSave = await axios.post(
        config.get("apiUrl") + "/api/master/mission/save",
        params,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      if (rsSave.data.status === "success") {
        handleShow();
        setTextMsg("บันทึกข้อมูลเรียบร้อย");
        history.push("/admin-main-mas-mission");
      } else {
        handleShow();
        setTextMsg("ไม่สามารถข้อมูลได้");
      }
    } catch {
      handleShow();
      setTextMsg("บันทึกไม่สำเร็จ");
    }
  };

  const handleAddBudget = () => {
    setListEmpMission((prev) => [
      ...prev,
      {
        id: "",
        name: "",
        duty: "",
      },
    ]);
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">
                    โรค/ภัยสุขภาพ{" "}
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    เพิ่ม โรค/ภัยสุขภาพ
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <span className="text-header-x2 ddc-color">
                      ข้อมูลกลุ่มภารกิจ
                    </span>
                    <br /> <br />
                    <div className="row mt-3">
                      <div className="col-12 col-md-2 pt-2">
                        <span className="text-color-form align-middle">
                          รหัสกลุ่มภารกิจ
                        </span>
                      </div>
                      <div className="col-12 col-md-2 pt-1">
                        <input
                          className="form-control shadow-none"
                          type="text"
                          name="id"
                          id="id"
                          ref={register}
                          defaultValue={id}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-md-2 pt-2">
                        <span className="text-color-form align-middle">
                          ชื่อกลุ่มภารกิจ
                        </span>
                      </div>
                      <div className="col-12 col-md-10 pt-1">
                        <input
                          type="text"
                          className="form-control shadow-none"
                          name="name"
                          id="name"
                          ref={register}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-md-2 pt-2">
                        <span className="text-color-form align-middle">
                          ชื่อกลุ่มภารกิจ (ชื่อไทยย่อ)
                        </span>
                      </div>
                      <div className="col-12 col-md-10 pt-1">
                        <input
                          type="text"
                          className="form-control shadow-none"
                          name="nameThShort"
                          id="nameThShort"
                          ref={register}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-md-2 pt-2">
                        <span className="text-color-form align-middle">
                          ชื่อกลุ่มภารกิจ (ภาษาอังกฤษ)
                        </span>
                      </div>
                      <div className="col-12 col-md-10 pt-1">
                        <input
                          type="text"
                          className="form-control shadow-none"
                          name="nameEn"
                          id="nameEn"
                          ref={register}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-md-2 pt-2">
                        <span className="text-color-form align-middle">
                          ชื่อกลุ่มภารกิจ (ชื่ออังกฤษย่อ)
                        </span>
                      </div>
                      <div className="col-12 col-md-10 pt-1">
                        <input
                          type="text"
                          className="form-control shadow-none"
                          name="nameEnShort"
                          id="nameEnShort"
                          ref={register}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-md-2 pt-2">
                        <span className="text-color-form align-middle">
                          วันที่เริ่ม
                        </span>
                      </div>
                      <div className="col-8 col-md-2 pt-1">
                        <div className="form-control-wrapper form-control-icon-right">
                          <input
                            type="text"
                            defaultValue={startDate}
                            name="start_date"
                            ref={register}
                            data-date-format="dd/MM/yyyy"
                            className="datepicker start_date form-control shadow-none"
                          />
                          <i className="far fa-calendar-alt"></i>
                        </div>
                      </div>

                      <div className="col-12 col-md-1 pt-2">
                        <span className="text-color-form align-middle">
                          วันที่สิ้นสุด
                        </span>
                      </div>
                      <div className="col-8 col-md-2 pt-1">
                        <div className="form-control-wrapper form-control-icon-right">
                          <input
                            type="text"
                            defaultValue={endDate}
                            name="end_date"
                            id="end_date"
                            ref={register}
                            data-date-format="dd/MM/yyyy"
                            className="datepicker end_date form-control shadow-none"
                          />
                          <i className="far fa-calendar-alt"></i>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-2 pt-2">
                        <span className="text-color-form align-middle">
                          สถานะ
                        </span>
                      </div>
                      <div className="col-12 col-sm-1 pt-1">
                        <Switch
                          name="active"
                          id="active"
                          onChange={(e) => setValue("active", e)}
                          checked={status}
                          ref={register}
                          className="react-switch"
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <span className="text-header-x2 ddc-color">คณะทำงาน</span>
                    <br /> <br />
                    <div className="row">
                      <div className="col-12 col-md-12 pt-3">
                        <div className="text-right">
                          <Button
                            type="button"
                            className="btn-no-border btn-color-search btn btn-contained"
                            onClick={handleAddBudget}
                          >
                            + เพิ่ม
                          </Button>
                        </div>

                        <table
                          id="data-table"
                          className="table table-striped table-bordered display w-100 margin-bottom-30"
                        >
                          <thead style={{ backgroundColor: "#f3f3f4" }}>
                            <tr>
                              <th className="text-center">ลำดับ</th>
                              <th className="text-center">ชื่อ-นามสกุล</th>
                              <th className="text-center">ตำแหน่ง</th>
                              <th className="text-center">จัดการ</th>
                            </tr>
                          </thead>

                          <tbody>
                            {listEmpMission.map((val, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                  {/* <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name={`budget.${i}.name`}
                                    ref={register}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      setListEmpMission((perv) => {
                                        const newCourse = [...perv];

                                        newCourse[i] = {
                                          ...newCourse[i],
                                          name: newValue,
                                        };
                                        return newCourse;
                                      });
                                    }}
                                    value={val.fullName}
                                  /> */}
                                  <select
                                    className="form-control shadow-none"
                                    name={`budget.${i}.name`}
                                    ref={register}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      setListEmpMission((perv) => {
                                        const newCourse = [...perv];

                                        newCourse[i] = {
                                          ...newCourse[i],
                                          empId: newValue,
                                        };
                                        return newCourse;
                                      });
                                    }}
                                    value={val.empId}
                                  >
                                    <option value="">กรุณาเลือก</option>
                                    {listEmpSearch.map((item, n) => (
                                      <option key={n} value={item.employee_id}>
                                        {item.fullName}
                                      </option>
                                    ))}
                                  </select>
                                </td>

                                <td>
                                  <select
                                    className="form-control shadow-none"
                                    name={`budget.${i}.duty`}
                                    ref={register}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      setListEmpMission((perv) => {
                                        const newCourse = [...perv];

                                        newCourse[i] = {
                                          ...newCourse[i],
                                          duty: newValue,
                                        };
                                        return newCourse;
                                      });
                                    }}
                                    value={val.duty}
                                  >
                                    <option value="">กรุณาเลือก</option>
                                    <option value={1}>หัวหน้าคณะทำงาน</option>
                                    <option value={2}>เลขานุการคณะทำงาน</option>
                                    <option value={3}>คณะทำงาน</option>
                                    <option value={4}>ที่ปรึกษาคณะทำงาน</option>
                                  </select>
                                </td>
                                <td align="center">
                                  <Button
                                    type="button"
                                    variant="contained"
                                    className="btn-no-border btn-color-delete-small"
                                    onClick={(e) => {
                                      if (val.id != '') {
                                        setListEmpMissionTempDel((prev) => {
                                          const newDel = [...prev, val];
                                          return newDel;
                                        });
                                      }

                                      setListEmpMission((prev) => {
                                        const newCourse = prev.filter(
                                          (_, index) => index !== i
                                        );
                                        return newCourse;
                                      });
                                    }}
                                  >
                                    <i className="fas fa-trash-alt pr-2"></i>
                                    ลบ
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-2 pt-3">
                        <Button
                          type="button"
                          onClick={handleSubmit(onSubmit)}
                          variant="contained"
                          className="btn-no-border btn-color-submit"
                        >
                          <i className="far fa-save pr-2"></i>
                          บันทึก
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>

      <Modal show={showMsg} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">
          {textMsg}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MissionForm;
