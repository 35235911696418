/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import DatePicker, { registerLocale } from "react-datepicker";
import th from "date-fns/locale/th";
import { Controller } from "react-hook-form";
import moment from "moment";

registerLocale("th", th);

const PersonelCourseTab = ({ register, employee, control }) => {
  const [keycloak] = useKeycloak();

  const [empCourse, setEmpCourse] = useState([]);

  useEffect(() => {
    fetchData();
  }, [keycloak.token]);

  useEffect(() => {
    if (employee.emp_course) {
      setEmpCourse(employee.emp_course);
    }
  }, [employee]);

  const fetchData = async () => {};

  function addNewRow() {
    setEmpCourse((prev) => [
      ...prev,
      {
        id: "",
        course: "",
        start_date: "",
        end_date: "",
        cert_date: "",
      },
    ]);
  }

  useEffect(() => {
    dateChange();
  }, [empCourse]);

  const dateChange = async () => {
    $(".datepicker").datepicker({
      todayBtn: false,
      autoclose: true,
      format: "dd/mm/yyyy",
      language: "th",
      // thaiyear: true,
    });
  };

  return (
    <div
      className="tab-pane fade pl-3 pr-3"
      id="custom-content-course-settings"
      role="tabpanel"
      aria-labelledby="custom-content-course-settings-tab"
    >
      <div className="row">
        <div className="col-12 col-md-12 pt-3">
          <div className="row">
            <div className="col-12 col-md-12 pt-3">
              <div className="text-right">
                <Button
                  type="button"
                  className="btn-no-border btn-color-search btn btn-contained"
                  onClick={addNewRow}
                >
                  + เพิ่ม
                </Button>
              </div>

              <table
                id="data-table"
                className="table table-striped table-bordered display w-100 margin-bottom-30"
              >
                <thead style={{ backgroundColor: "#f3f3f4" }}>
                  <tr>
                    <th className="text-center">ลำดับ</th>
                    <th className="text-center">ชื่อหลักสูตร</th>
                    <th className="text-center">
                      วันที่เริ่มต้นฝึกอบรม/หลักสูตร
                    </th>
                    <th className="text-center">
                      วันที่สิ้นสุดฝึกอบรม/หลักสูตร
                    </th>
                    <th className="text-center">วันหมดอายุใบรับรอง</th>
                  </tr>
                </thead>

                <tbody>
                  {empCourse.map((val, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        <input
                          className="form-control shadow-none"
                          type="text"
                          name={`empCourse.${i}.course`}
                          ref={register}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setEmpCourse((perv) => {
                              const newCourse = [...perv];

                              newCourse[i] = {
                                ...newCourse[i],
                                course: newValue,
                              };
                              return newCourse;
                            });
                          }}
                          defaultValue={val.course}
                        />
                      </td>
                      <td>
                        <div className="form-control-wrapper form-control-icon-right">
                          <input
                            type="text"
                            name={`empCourse.${i}.start_date`}
                            defaultValue={
                              val.start_date &&
                              moment(new Date(val.start_date))
                                .add(543, "year")
                                .format("DD/MM/YYYY")
                            }
                            ref={register}
                            data-date-format="dd/MM/yyyy"
                            className="datepicker form-control shadow-none"
                            // onChange={(e) => {
                            //   // console.log(e);
                            //   // field.onChange(
                            //   //   moment(e, "dd/MM/yyyy").format("YYYY-MM-DD")
                            //   // );

                            //   setEmpCourse((perv) => {
                            //     const newCourse = [...perv];

                            //     newCourse[i] = {
                            //       ...newCourse[i],
                            //       start_date: e,
                            //     };
                            //     return newCourse;
                            //   });
                            // }}
                          />

                          <i className="far fa-calendar-alt"></i>
                        </div>
                      </td>
                      <td>
                        <div className="form-control-wrapper form-control-icon-right">
                          <input
                            type="text"
                            name={`empCourse.${i}.end_date`}
                            defaultValue={
                              val.end_date &&
                              moment(new Date(val.end_date))
                                .add(543, "year")
                                .format("DD/MM/YYYY")
                            }
                            ref={register}
                            data-date-format="dd/MM/yyyy"
                            className="datepicker form-control shadow-none"
                          />
                          <i className="far fa-calendar-alt"></i>
                        </div>
                      </td>
                      <td>
                        <div className="form-control-wrapper form-control-icon-right">
                          <input
                            type="text"
                            name={`empCourse.${i}.cert_date`}
                            defaultValue={
                              val.cert_date &&
                              moment(new Date(val.cert_date))
                                .add(543, "year")
                                .format("DD/MM/YYYY")
                            }
                            ref={register}
                            data-date-format="dd/MM/yyyy"
                            className="datepicker form-control shadow-none"
                          />

                          {/* <Controller
                            control={control}
                            name={`empCourse.${i}.cert_date`}
                            render={(field) => (
                              <DatePicker
                                className="form-control shadow-none"
                                name={`empCourse.${i}.cert_date`}
                                dateFormat="dd/MM/yyyy"
                                locale="th"
                                ref={register}
                                onChange={(e) => {
                                  field.onChange(
                                    moment(e, "dd/MM/yyyy").format("YYYY-MM-DD")
                                  );

                                  setEmpCourse((perv) => {
                                    const newCourse = [...perv];

                                    newCourse[i] = {
                                      ...newCourse[i],
                                      cert_date: e,
                                    };
                                    return newCourse;
                                  });
                                }}
                                selected={
                                  val.cert_date && new Date(val.cert_date)
                                }
                              />
                            )}
                          /> */}
                          <i className="far fa-calendar-alt"></i>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonelCourseTab;
