/* eslint-disable */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import config from "react-global-configuration";
import moment from "moment";
import Webcam from "react-webcam";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { registerLocale } from "react-datepicker";
import SignatureCanvas from "react-signature-canvas";
import th from "date-fns/locale/th";
import { hasAnyRole } from "../util/AuthUtil";
import { useDispatch, useSelector } from "react-redux";
import { setUserId, userId } from "../../app/Admin/PersonnelInfoSlice";
import * as Face48Api from "../api/Face48Api";
import Select from "react-select";
import PersonelExpertTab from "./PersonnelInfoTab/PersonelExpertTab";
import PersonelCourseTab from "./PersonnelInfoTab/PersonelCourseTab";
import PersonelEOCTab from "./PersonnelInfoTab/PersonelEOCTab";

registerLocale("th", th);

const PersonnelInfo = () => {
  const [posId, setPosId] = useState("0");
  const [empTitle, setEmptitle] = useState("");
  const [empSex, setEmpSex] = useState("");
  const [empActive, setEmpActive] = useState("");
  const [empRole, setEmpRole] = useState("3");
  const [empStatusId, setEmpStatusId] = useState("1");
  const [empRootCause, setEmpRootCause] = useState("");
  const [empTypeId, setEmpTypeId] = useState("6");
  const [empShipId, setEmpShipId] = useState("5");
  const [empHolidayGroupId, setEmpHolidayGroupId] = useState("1");
  const [empEduLv, setEmpEduLv] = useState("");
  const [empFac, setEmployeeFac] = useState([]);

  const [empEduLv2, setEmpEduLv2] = useState("");
  const [empEduLv3, setEmpEduLv3] = useState("");
  const [empEduLv4, setEmpEduLv4] = useState("");
  const [empEduLv5, setEmpEduLv5] = useState("");
  const [empEduLv6, setEmpEduLv6] = useState("");
  const [empEduLv7, setEmpEduLv7] = useState("");
  const [empEduLv8, setEmpEduLv8] = useState("");
  const [empEduLv9, setEmpEduLv9] = useState("");
  const [empEduLv10, setEmpEduLv10] = useState("");

  const [employeeEducation2, setEmployeeEducation2] = useState([]);
  const [employeeEducation3, setEmployeeEducation3] = useState([]);
  const [employeeEducation4, setEmployeeEducation4] = useState([]);
  const [employeeEducation5, setEmployeeEducation5] = useState([]);
  const [employeeEducation6, setEmployeeEducation6] = useState([]);
  const [employeeEducation7, setEmployeeEducation7] = useState([]);
  const [employeeEducation8, setEmployeeEducation8] = useState([]);
  const [employeeEducation9, setEmployeeEducation9] = useState([]);
  const [employeeEducation10, setEmployeeEducation10] = useState([]);

  const [eduDate2, setEduDate2] = useState(null);
  const [eduDate3, setEduDate3] = useState(null);
  const [eduDate4, setEduDate4] = useState(null);
  const [eduDate5, setEduDate5] = useState(null);
  const [eduDate6, setEduDate6] = useState(null);
  const [eduDate7, setEduDate7] = useState(null);
  const [eduDate8, setEduDate8] = useState(null);
  const [eduDate9, setEduDate9] = useState(null);
  const [eduDate10, setEduDate10] = useState(null);

  let history = useHistory();
  const dispatch = useDispatch();
  const store_UserId = useSelector(userId);
  const [keycloak] = useKeycloak();
  const navigate = useHistory();
  const [gender, setGender] = useState([]);
  const [wstatus, setWstatus] = useState([]);
  const [title, setTitle] = useState([]);
  const [empType, setEmpType] = useState([]);
  const [role, setRole] = useState([]);
  const [position, setPosition] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [empPosition, setEmpPosition] = useState("");
  const [tmpPositionId, setTmpPositionId] = useState("");
  //console.log(empPosition);
  const [jobId, setMasIdJob] = useState([]);
  const [tmpJobId, settmpJobId] = useState([]);
  const [empJob, setEmpJob] = useState();
  //console.log(empJob);
  const [workbu, setWorkbu] = useState([]);
  const [workbu2, setWorkbu2] = useState([]);
  const [workbu3, setWorkbu3] = useState([]);
  const [workbu4, setWorkbu4] = useState([]);
  const [workbu5, setWorkbu5] = useState([]);
  const [workbu6, setWorkbu6] = useState([]);
  const [lawbu, setLawbu] = useState([]);
  const [lawbu2, setLawbu2] = useState([]);
  const [lawbu3, setLawbu3] = useState([]);
  const [lawbu4, setLawbu4] = useState([]);
  const [lawbu5, setLawbu5] = useState([]);
  const [lawbu6, setLawbu6] = useState([]);
  const [costCenterList, setCostCenterList] = useState([]);
  const [shift, setShift] = useState([]);
  const [holidayGroup, setHolidayGroup] = useState([]);
  const [edu, setEdu] = useState([]);
  const [province, setProvince] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subDistrict, setSubDistrict] = useState([]);
  const [birthDate, setBirthDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [resignDate, setResignDate] = useState(null);
  const [currentSaralyDate, setCurrentSaralyDate] = useState(null);
  const [prevSaralyDate, setPrevSaralyDate] = useState(null);
  const [eduDate, setEduDate] = useState(null);
  const [empSearch, setEmpSearch] = useState("");
  const [useBrake, setUseBrake] = useState(0);
  const [useBox2, setBox2] = useState(1);
  const [useBox3, setBox3] = useState(1);
  const [useBox4, setBox4] = useState(1);
  const [useBox5, setBox5] = useState(1);
  const [useBox6, setBox6] = useState(1);
  const [useBox7, setBox7] = useState(1);
  const [useBox8, setBox8] = useState(1);
  const [useBox9, setBox9] = useState(1);
  const [useBox10, setBox10] = useState(1);
  const [showMsg, setShowMsg] = useState(false);
  const handleClose = () => setShowMsg(false);
  const handleShow = () => setShowMsg(true);
  const [textMsg, setTextMsg] = useState("");
  const [showDelMsg, setShowDelMsg] = useState(false);
  const handleDelClose = () => setShowDelMsg(false);
  const handleDelShow = () => setShowDelMsg(true);

  const [showCamera1, setShowCamera1] = useState(false);
  const camera1Close = () => setShowCamera1(false);
  const camera1Show = () => setShowCamera1(true);

  const [showCamera2, setShowCamera2] = useState(false);
  const camera2Close = () => setShowCamera2(false);
  const camera2Show = () => setShowCamera2(true);

  const [showCamera3, setShowCamera3] = useState(false);
  const camera3Close = () => setShowCamera3(false);
  const camera3Show = () => setShowCamera3(true);

  const [showCamera4, setShowCamera4] = useState(false);
  const camera4Close = () => setShowCamera4(false);
  const camera4Show = () => setShowCamera4(true);

  const [showCamera5, setShowCamera5] = useState(false);
  const camera5Close = () => setShowCamera5(false);
  const camera5Show = () => setShowCamera5(true);

  const [showCamera6, setShowCamera6] = useState(false);
  const camera6Close = () => setShowCamera6(false);
  const camera6Show = () => setShowCamera6(true);

  const [employee, setEmployee] = useState([]);
  const [employeeAddress, setEmployeeAddress] = useState([]);
  const [employeeSaraly, setEmployeeSaraly] = useState([]);
  const [employeeSaralyPrev, setEmployeeSaralyPrev] = useState([]);
  const [employeeEducation, setEmployeeEducation] = useState([]);
  const [employeeMission, setEmployeeMission] = useState([]);

  const [flagCenter, setFlagCenter] = useState(0);
  const [editSalary, setEditSalary] = useState(0);
  const [editPassword, setEditPassword] = useState(0);
  const [isAdmin, setIsAdmin] = useState(0);
  const [btnSave, setBtnSave] = useState("");
  const [btnDel, setBtnDel] = useState("");
  const [hasSearchUser, setHasSearchUser] = useState(false);

  const { register, handleSubmit, reset, control } = useForm();
  const [face48Token, setFace48Token] = useState("");
  const [face48PeopleId, setFace48PeopleId] = useState(null);
  const [face48People, setFace48People] = useState(null);
  const [avatarImgFile, setAvatarImgFile] = useState(null);
  const [photoImgFile, setPhotoImgFile] = useState(null);
  const [jobPosition, setJobPosition] = useState([]);
  const [titleFace, setTitleFace] = useState([]);
  const [setTitleData, setTitleDataFace] = useState(null);
  const [setJobPositionData, setJobPositionFaceData] = useState(null);

  const [isLoading, setIsLoading] = useState({
    pic1: false,
    pic2: false,
    pic3: false,
    pic4: false,
    pic5: false,
    pic6: false,
    signature: false,
    pic1Del: false,
    pic2Del: false,
    pic3Del: false,
    pic4Del: false,
    pic5Del: false,
    pic6Del: false,
    signatureDel: false,
  });
  const showLoading = async () => {
    $(".loading-container").removeClass("d-none");
    $(".loading-container").addClass("d-block");
  };

  const hideLoading = async () => {
    $(".loading-container").removeClass("d-block");
    $(".loading-container").addClass("d-none");
  };

  const forceRedirect = () => {
    // ถ้าไม่มีค่าตัวแปร ของ Store ที่มาจาก Redux ให้ทำการ Redirect กลับหน้า DataTable จัดการข้อมูลบุคลากร
    if (hasAnyRole(keycloak, ["admin", "admin-dep"])) {
      if (store_UserId === "") {
        backPage();
      }
    }
  };

  const fetchData = async () => {
    // let face48Token = await Face48Api.getToken(keycloak);
    // setFace48Token(face48Token);
    axios
      .get(config.get("apiUrl") + "/api/master/title", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        setTitle(res.data.data.records);
      });

    axios
      .get(config.get("apiUrl") + "/api/master/gender", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        setGender(res.data.data.records);
      });

    axios
      .get(config.get("apiUrl") + "/api/master/status", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        setWstatus(res.data.data.records);
        // console.log("master/status : ", res.data.data.records);
      });

    axios
      .get(config.get("apiUrl") + "/api/master/emp-type", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        res.data.data.records.sort((a, b) =>
          a.emp_type_name > b.emp_type_name ? 1 : -1
        );
        setEmpType(res.data.data.records);
      });

    axios
      .get(config.get("apiUrl") + "/api/master/role", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        setRole(res.data.data.records);
      });

    axios
      .get(config.get("apiUrl") + "/api/master/org/1/1001", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        res.data.data.records.sort((a, b) => (a.name > b.name ? 1 : -1));
        setWorkbu(res.data.data.records);
      });

    axios
      .get(config.get("apiUrl") + "/api/master/org/2/5001", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        res.data.data.records.sort((a, b) => (a.name > b.name ? 1 : -1));
        setLawbu(res.data.data.records);
      });

    axios
      .get(config.get("apiUrl") + "/api/master/shift", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        setShift(res.data.data.records);
      });

    axios
      .get(config.get("apiUrl") + "/api/master/holiday-group", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        setHolidayGroup(res.data.data.records);
      });

    axios
      .get(config.get("apiUrl") + "/api/master/education-level", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        setEdu(res.data.data.records);
      });

    axios
      .get(config.get("apiUrl") + "/api/master/province", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        res.data.data.records.sort((a, b) =>
          a.province_tname > b.province_tname ? 1 : -1
        );
        setProvince(res.data.data.records);
      });

    const resJob = await axios.get(
      config.get("apiUrl") + "/api/master/masJobId",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setMasIdJob(resJob.data.data.records);

    const resPosition = await axios.get(
      config.get("apiUrl") + "/api/master/pos",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setPosition(resPosition.data.data.records);

    const objMasterData = {
      jobId: resJob.data.data.records,
      position: resPosition.data.data.records,
    };

    if (hasAnyRole(keycloak, ["admin", "admin-dep"])) {
      // ถ้าสิทธิ์ admin หรือ admin-dep ให้ดึง employee_id ผ่าน Store ของ Redux จากหน้า dataTable ก่อนหน้ามาโชว์
      if (store_UserId !== "") {
        searchUser(store_UserId, objMasterData);
      }
    } else {
      // ถ้าไม่ใช่สิทธิ์ admin หรือ admin-dep ให้ดึงข้อมูลตัวเองจาก keycloak ปกติ
      searchUser(keycloak.tokenParsed.preferred_username, objMasterData);
    }
    // searchUser(store_UserId, objMasterData);
  };

  useEffect(() => {
    // forceRedirect();
    fetchData();
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview("init");
    $(".datepicker")
      .datepicker({
        format: "dd/mm/yyyy",
        todayBtn: false,
        language: "th",
        thaiyear: true,
      })
      .datepicker("setDate", "0")
      .datepicker("setEndDate", "0")
      .bind("keydown", function (e) {
        e.preventDefault();
      });
    $(".resign_date").datepicker("setDate", "");
    dateChange();
    if (!hasAnyRole(keycloak, ["admin", "admin-dep"])) {
      setEmpSearch(keycloak.tokenParsed.preferred_username);
      setSingleSelections([
        {
          employee_id: keycloak.tokenParsed.preferred_username,
          fname: "",
          lname: "",
        },
      ]);
      setIsAdmin(0);
    } else {
      // ถ้าสิทธิ์ admin หรือ admin-dep ให้ดึง employee_id ผ่าน Store ของ Redux จากหน้า dataTable ก่อนหน้ามาโชว์
      if (store_UserId !== "") {
        setEmpSearch(store_UserId);
        setSingleSelections([
          { employee_id: store_UserId, fname: "", lname: "" },
        ]);
      }
      setIsAdmin(1);
    }
  }, [useBrake]);

  const dateChange = async () => {
    $(".start_date")
      .datepicker({
        todayBtn: false,
        autoclose: true,
        format: "dd/mm/yyyy",
        language: "th",
        thaiyear: true,
      })
      .datepicker("setDate", new Date())
      .on("changeDate", function (selected) {
        getAllWorkDate(
          moment($(".start_date").val(), "DD/MM/YYYY")
            .add(-543, "year")
            .format("YYYY-MM-DD"),
          moment(new Date()).format("YYYY-MM-DD")
        );
      });
  };

  const [empWorkBu1, setEmpWorkBu1] = useState("");
  const [empWorkBu2, setEmpWorkBu2] = useState("");
  const [empWorkBu3, setEmpWorkBu3] = useState("");
  const [empWorkBu4, setEmpWorkBu4] = useState("");
  const [empWorkBu5, setEmpWorkBu5] = useState("");
  const [empWorkBu6, setEmpWorkBu6] = useState("");
  const [empLawBu1, setEmpLawBu1] = useState("");
  const [empLawBu2, setEmpLawBu2] = useState("");
  const [empLawBu3, setEmpLawBu3] = useState("");
  const [empLawBu4, setEmpLawBu4] = useState("");
  const [empLawBu5, setEmpLawBu5] = useState("");
  const [empLawBu6, setEmpLawBu6] = useState("");
  const [empOrgNickname, setEmpOrgNickname] = useState("");
  const [empCostId, setEmpCostId] = useState("");
  const [situationId, setSituationId] = useState("1");
  const [hazardId, setHazardId] = useState("");
  const [statusGoGovId, setStatusGoGovId] = useState("");
  const [statusGoGovOrg, setStatusGoGovOrg] = useState("");
  const [statusGoGovOther, setStatusGoGovOther] = useState("");
  const [goWorkBu1, setGoWorkBu1] = useState("");
  const [q, setQ] = useState("");

  const onChangeHandler = async (t, e) => {
    onChangeHandlerCost(t, e);
    let v = e.target.value;
    if (v !== "") {
      let rsWorkbu = await axios.get(
        config.get("apiUrl") + "/api/master/org/1/" + e.target.value,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      if (t === "1") {
        setEmpWorkBu1(v);
        setWorkbu2(rsWorkbu.data.data.records);
      }
      if (t === "2") {
        setEmpWorkBu2(v);
        setWorkbu3(rsWorkbu.data.data.records);
      }
      if (t === "3") {
        setEmpWorkBu3(v);
        setWorkbu4(rsWorkbu.data.data.records);
      }
      if (t === "4") {
        setEmpWorkBu4(v);
        setWorkbu5(rsWorkbu.data.data.records);
      }
      if (t === "5") {
        setEmpWorkBu5(v);
        setWorkbu6(rsWorkbu.data.data.records);
      }
      if (t === "6") {
        setEmpWorkBu6(v);
      }
    }

    if (t === "1") {
      setEmpWorkBu1(v);
    }
    if (t === "2") {
      setEmpWorkBu2(v);
    }
    if (t === "3") {
      setEmpWorkBu3(v);
    }
    if (t === "4") {
      setEmpWorkBu4(v);
    }
    if (t === "5") {
      setEmpWorkBu5(v);
    }
    if (t === "6") {
      setEmpWorkBu6(v);
    }
  };

  const onChangeHandlerLaw = async (t, e) => {
    let v = e.target.value;
    if (v !== "") {
      let rsLawbu = await axios.get(
        config.get("apiUrl") + "/api/master/org/2/" + e.target.value,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      if (t === "1") {
        setEmpLawBu1(v);
        setLawbu2(rsLawbu.data.data.records);
      }
      if (t === "2") {
        setEmpLawBu2(v);
        setLawbu3(rsLawbu.data.data.records);
      }
      if (t === "3") {
        setEmpLawBu3(v);
        setLawbu4(rsLawbu.data.data.records);
      }
      if (t === "4") {
        setEmpLawBu4(v);
        setLawbu5(rsLawbu.data.data.records);
      }
      if (t === "5") {
        setEmpLawBu5(v);
        setLawbu6(rsLawbu.data.data.records);
      }
      if (t === "6") {
        setEmpLawBu6(v);
      }
    }

    if (t === "1") {
      setEmpLawBu1(v);
    }
    if (t === "2") {
      setEmpLawBu2(v);
    }
    if (t === "3") {
      setEmpLawBu3(v);
    }
    if (t === "4") {
      setEmpLawBu4(v);
    }
    if (t === "5") {
      setEmpLawBu5(v);
    }
    if (t === "6") {
      setEmpLawBu6(v);
    }
  };

  const onChangeHandlerCost = async (t, e) => {
    let v = e.target.value;
    let orgIds = "";
    if (v !== "") {
      orgIds = addOrgList(orgIds, v);
    }

    if (t !== "1" && empWorkBu1 !== "") {
      orgIds = addOrgList(orgIds, empWorkBu1);
    }
    if (t !== "2" && empWorkBu2 !== "") {
      orgIds = addOrgList(orgIds, empWorkBu2);
    }
    if (t !== "3" && empWorkBu3 !== "") {
      orgIds = addOrgList(orgIds, empWorkBu3);
    }
    if (t !== "4" && empWorkBu4 !== "") {
      orgIds = addOrgList(orgIds, empWorkBu4);
    }
    if (t !== "5" && empWorkBu5 !== "") {
      orgIds = addOrgList(orgIds, empWorkBu5);
    }
    if (t !== "6" && empWorkBu6 !== "") {
      orgIds = addOrgList(orgIds, empWorkBu6);
    }
    orgIds = "orgIds=" + orgIds;

    let rsCost = await axios.get(
      config.get("apiUrl") + "/api/master/cost-center?" + orgIds,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setCostCenterList(rsCost.data.data);
  };

  const addOrgList = (orgIds, value) => {
    if (orgIds !== "") {
      orgIds += "," + value;
    } else {
      orgIds += value;
    }
    return orgIds;
  };

  const [empSubDistrict, setEmpSubDistrict] = useState("");
  const [empDistrict, setEmpDistrict] = useState("");
  const [empProvince, setEmpProvince] = useState("");

  const onChangePostCode = async (t, e) => {
    let v = e.target.value;
    if (t === "province") {
      setEmpProvince(v);
      let rs = await axios.get(
        config.get("apiUrl") + "/api/master/district/" + e.target.value,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      rs.data.data.records.sort((a, b) =>
        a.district_tname > b.district_tname ? 1 : -1
      );
      setDistrict(rs.data.data.records);
    }
    if (t === "district") {
      setEmpDistrict(v);
      let rs = await axios.get(
        config.get("apiUrl") + "/api/master/sub-district/" + e.target.value,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      rs.data.data.records.sort((a, b) =>
        a.sub_district_tname > b.sub_district_tname ? 1 : -1
      );
      setSubDistrict(rs.data.data.records);
    }
  };

  const [skillLang1, setSkillLang1] = useState("");
  const [skillLang2, setSkillLang2] = useState("");
  const [skillLang3, setSkillLang3] = useState("");
  const [skillLang1LV, setSkillLang1LV] = useState("");
  const [skillLang2LV, setSkillLang2LV] = useState("");
  const [skillLang3LV, setSkillLang3LV] = useState("");
  const [flagSkillComputer1, setFlagSkillcomputer1] = useState(0);
  const [flagSkillComputer2, setFlagSkillcomputer2] = useState(0);
  const [flagSkillComputerOther, setFlagSkillcomputerOther] = useState(0);
  const [flagSkillDrive1, setFlagSkillDrive1] = useState(0);
  const [flagSkillDrive2, setFlagSkillDrive2] = useState(0);
  const [flagSkillDrive3, setFlagSkillDrive3] = useState(0);
  const [flagSkillDrive4, setFlagSkillDrive4] = useState(0);
  const [flagSkillDrive5, setFlagSkillDrive5] = useState(0);
  const [flagSkillDrive6, setFlagSkillDrive6] = useState(0);
  const [flagSkillToeic, setFlagSkillToeic] = useState(0);
  const [flagSkillTofel, setFlagSkillTofel] = useState(0);
  const [flagSkillIelts, setFlagSkillIelts] = useState(0);
  const [flagSkillCutep, setFlagSkillCutep] = useState(0);
  const [flagSkillTutep, setFlagSkillTutep] = useState(0);
  const [empToeic, setEmpToeic] = useState("");
  const [empTofel, setEmpTofel] = useState("");
  const [empIelts, setEmpIelts] = useState("");
  const [empCutep, setEmpCutep] = useState("");
  const [empTutep, setEmpTutep] = useState("");

  const [skillCom1, setSkillCom1] = useState("");
  const [skillCom1LV, setSkillCom1LV] = useState("");
  const [skillCom2, setSkillCom2] = useState("");
  const [skillCom2LV, setSkillCom2LV] = useState("");
  const [skillComServiceLV, setSkillComServiceLV] = useState("");
  const [skillComServerLV, setSkillComServerLV] = useState("");
  const [skillComGraphic, setSkillComGraphic] = useState("");
  const [skillComVedio, setSkillComVedio] = useState("");
  const [skillComOffice, setSkillComOffice] = useState("");
  const [skillComGraphicLV, setSkillComGraphicLV] = useState("");
  const [skillComVedioLV, setSkillComVedioLV] = useState("");
  const [skillComOfficeLV, setSkillComOfficeLV] = useState("");
  const [skillSportLV, setSkillSportLV] = useState("");
  const [skillMusicLV, setSkillMusicLV] = useState("");
  const [skillHobbyLV, setSkillHobbyLV] = useState("");

  const createUser = async (data) => {
    try {
      let params = {
        employee_id: data.employee_id,
        email: data.email,
        password: data.password,
        id_card: data.id_card,
        passport: data.passport,
        title_id: data.title_id,
        fname: data.fname,
        lname: data.lname,
        efname: data.efname,
        elname: data.elname,
        birthday:
          data.birthday === ""
            ? ""
            : moment(data.birthday, "DD/MM/YYYY")
                .add(-543, "year")
                .format("YYYY-MM-DD"),
        telephone: data.telephone,
        sex: data.sex,
        status_id: data.status_id,
        start_date:
          data.start_date === ""
            ? ""
            : moment(data.start_date, "DD/MM/YYYY")
                .add(-543, "year")
                .format("YYYY-MM-DD"),
        resign_date:
          data.resign_date === ""
            ? ""
            : moment(data.resign_date, "DD/MM/YYYY")
                .add(-543, "year")
                .format("YYYY-MM-DD"),
        emp_type_id: data.emp_type_id,
        job_id: empJob ? empJob.label : "",
        job_id_code: empJob ? empJob.value : null,
        position_type: data.position_type,
        mobile: data.mobile,
        law_bu1: data.law_bu1,
        law_bu2: data.law_bu2,
        law_bu3: data.law_bu3,
        law_bu4: data.law_bu4,
        law_bu5: data.law_bu5,
        law_bu6: data.law_bu6,
        work_bu1: data.work_bu1,
        work_bu2: data.work_bu2,
        work_bu3: data.work_bu3,
        work_bu4: data.work_bu4,
        work_bu5: data.work_bu5,
        work_bu6: data.work_bu6,
        emp_position: empPosition ? empPosition.label : "",
        emp_position_id: empPosition ? empPosition.value : null,
        field_of_work: data.field_of_work,
        salary:
          typeof data.salary !== "undefined" && data.salary != null
            ? data.salary.replace(",", "")
            : data.salary,
        position_number: data.position_number,
        card_no: data.card_no,
        shift_id: data.shift_id,
        holiday_group_id: data.holiday_group_id,
        picture: empImg,
        signature: empSign,
        center: flagCenter,
        user_active: data.user_active,
        role_id: [data.role_id],
        org_id: [data.work_bu1, data.work_bu2, data.work_bu3],
        emp_address: {
          address_no: data.address_no,
          village: data.village,
          moo: data.moo,
          road: data.road,
          sub_district: data.sub_district,
          district: data.district,
          province: data.province,
          postcode: data.postcode,
          address_type: 0,
          run_no: 0,
        },
        emp_education: [
          {
            education_level: data.education_level,
            educational_backgroup: data.educational_backgroup,
            abbreviations: data.abbreviations,
            major: data.major,
            institution: data.institution,
            graduation_date:
              data.graduation_date === ""
                ? ""
                : moment(data.graduation_date, "DD/MM/YYYY")
                    .add(-543, "year")
                    .format("YYYY-MM-DD"),
            graduation_year: data.graduation_year,
            attachment: empTranscript,
          },
          {
            education_level: useBox2 == 2 ? data.education_level2 : "",
            educational_backgroup:
              useBox2 == 2 ? data.educational_backgroup2 : "",
            abbreviations: useBox2 == 2 ? data.abbreviations2 : "",
            major: useBox2 == 2 ? data.major2 : "",
            institution: useBox2 == 2 ? data.institution2 : "",
            graduation_date:
              data.graduation_date2 === ""
                ? ""
                : moment(data.graduation_date2, "DD/MM/YYYY")
                    .add(-543, "year")
                    .format("YYYY-MM-DD"),
            graduation_year: useBox2 == 2 ? data.graduation_year2 : "",
            attachment: empTranscript,
          },
          {
            education_level: useBox3 == 2 ? data.education_level3 : "",
            educational_backgroup:
              useBox3 == 2 ? data.educational_backgroup3 : "",
            abbreviations: useBox3 == 2 ? data.abbreviations3 : "",
            major: useBox3 == 2 ? data.major3 : "",
            institution: data.institution3,
            graduation_date:
              data.graduation_date3 === ""
                ? ""
                : moment(data.graduation_date3, "DD/MM/YYYY")
                    .add(-543, "year")
                    .format("YYYY-MM-DD"),
            graduation_year: useBox3 == 2 ? data.graduation_year3 : "",
            attachment: empTranscript,
          },
          {
            education_level: useBox4 == 2 ? data.education_level4 : "",
            educational_backgroup:
              useBox4 == 2 ? data.educational_backgroup4 : "",
            abbreviations: useBox4 == 2 ? data.abbreviations4 : "",
            major: useBox4 == 2 ? data.major4 : "",
            institution: useBox4 == 2 ? data.institution4 : "",
            graduation_date:
              data.graduation_date4 === ""
                ? ""
                : moment(data.graduation_date4, "DD/MM/YYYY")
                    .add(-543, "year")
                    .format("YYYY-MM-DD"),
            graduation_year: useBox4 == 2 ? data.graduation_year4 : "",
            attachment: empTranscript,
          },
          {
            education_level: useBox5 == 2 ? data.education_level5 : "",
            educational_backgroup:
              useBox5 == 2 ? data.educational_backgroup5 : "",
            abbreviations: useBox5 == 2 ? data.abbreviations5 : "",
            major: useBox5 == 2 ? data.major5 : "",
            institution: useBox5 == 2 ? data.institution5 : "",
            graduation_date:
              data.graduation_date5 === ""
                ? ""
                : moment(data.graduation_date5, "DD/MM/YYYY")
                    .add(-543, "year")
                    .format("YYYY-MM-DD"),
            graduation_year: useBox5 == 2 ? data.graduation_year5 : "",
            attachment: empTranscript,
          },
        ],
        emp_prev_salary: {
          salary:
            typeof data.prev_salary !== "undefined" && data.prev_salary != null
              ? data.prev_salary.replace(",", "")
              : data.prev_salary,
          effective_date:
            data.peffective_date === ""
              ? ""
              : moment(data.peffective_date, "DD/MM/YYYY")
                  .add(-543, "year")
                  .format("YYYY-MM-DD"),
        },
        emp_curr_salary: {
          salary:
            typeof data.salary !== "undefined" && data.salary != null
              ? data.salary.replace(",", "")
              : data.salary,
          effective_date:
            data.ceffective_date === ""
              ? ""
              : moment(data.ceffective_date, "DD/MM/YYYY")
                  .add(-543, "year")
                  .format("YYYY-MM-DD"),
        },
        fac_picture: {
          pic_1: empPic1,
          pic_2: empPic2,
          pic_3: empPic3,
          pic_4: empPic4,
          pic_5: empPic5,
          pic_6: empPic6,
        },
        skill_lang_1: data.skill_lang_1,
        skill_lang_2: data.skill_lang_2,
        skill_lang_3: data.skill_lang_3,
        skill_computer_1: flagSkillComputer1,
        skill_computer_2: flagSkillComputer2,
        skill_computer_other: flagSkillComputerOther,
        skill_computer_other_text: data.skill_computer_other_text,
        skill_typing_thai: data.skill_typing_thai,
        skill_typing_eng: data.skill_typing_eng,
        skill_drive_1: flagSkillDrive1,
        skill_drive_2: flagSkillDrive2,
        skill_drive_3: flagSkillDrive3,
        skill_drive_4: flagSkillDrive4,
        skill_drive_5: flagSkillDrive5,
        skill_other_1: data.skill_other_1,
        skill_other_2: data.skill_other_2,
        skill_other_3: data.skill_other_3,
        skill_other_4: data.skill_other_4,
        skill_other_5: data.skill_other_5,
        lawbu_2_text: data.lawbu_2_text,
        lawbu_3_text: data.lawbu_3_text,
        lawbu_4_text: data.lawbu_4_text,
        lawbu_5_text: data.lawbu_5_text,
        lawbu_6_text: data.lawbu_6_text,
        workbu_2_text: data.workbu_2_text,
        workbu_3_text: data.workbu_3_text,
        workbu_4_text: data.workbu_4_text,
        workbu_5_text: data.workbu_5_text,
        workbu_6_text: data.workbu_6_text,
        skill_toeic: flagSkillToeic,
        skill_tofel: flagSkillTofel,
        skill_ielts: flagSkillIelts,
        skill_cutep: flagSkillCutep,
        skill_tutep: flagSkillTutep,
        skill_toeic_file: empToeic,
        skill_tofel_file: empTofel,
        skill_ielts_file: empIelts,
        skill_cutep_file: empCutep,
        skill_tutep_file: empTutep,
        skill_lang_1_level: skillLang1LV,
        skill_lang_2_level: skillLang2LV,
        skill_lang_3_level: skillLang3LV,
        skill_toeic_point: data.skill_toeic_point,
        skill_tofel_point: data.skill_tofel_point,
        skill_ielts_point: data.skill_ielts_point,
        skill_cutep_point: data.skill_cutep_point,
        skill_tutep_point: data.skill_tutep_point,

        skill_com_1: skillCom1,
        skill_com_1_level: skillCom1LV,
        skill_com_2: skillCom2,
        skill_com_2_level: skillCom2LV,
        skill_com_service: data.skill_com_service,
        skill_com_service_level: skillComServiceLV,
        skill_com_server: data.skill_com_server,
        skill_com_server_level: skillComServerLV,
        skill_com_graphic: skillComGraphic,
        skill_com_graphic_level: skillComGraphicLV,
        skill_com_vedio: skillComVedio,
        skill_com_vedio_level: skillComVedioLV,
        skill_com_office: skillComOffice,
        skill_com_office_level: skillComOfficeLV,
        skill_sport: data.skill_sport,
        skill_sport_level: skillSportLV,
        skill_music: data.skill_music,
        skill_music_level: skillMusicLV,
        skill_hobby: data.skill_hobby,
        skill_hobby_level: skillHobbyLV,

        cost_id: data.cost_id,
        place_status: data.statusGoGov_id,
        place_org: data.statusGoGov_org,
        place_other: data.statusGoGov_other,
        situation_status: data.situation_id,
        hazard_id: data.hazard_id,
        emp_mission: employeeMission,
        emp_expert: data.empExpert,
        emp_competency: data.empCompetency,
        emp_course: data.empCourse,
      };

      showLoading();
      // await axios.post(config.get("apiUrl") + "/api/employee", JSON.stringify(params), {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `bearer ${keycloak.token}`,
      //   },
      // }).then((res) => {
      //   setEmpSearch(data.employee_id);
      //   setHasSearchUser(true);
      //   handleShow();
      //   setTextMsg("บันทึกข้อมูลเรียบร้อย");
      //   setTimeout(() => {
      //     handleClose();
      //   }, 3000);
      // }).catch((error) => {
      //   if (error.response) {
      //     setTextMsg(error.response.data.message_th || error.response.data.message);
      //   } else {
      //     setTextMsg("บันทึกไม่สำเร็จ");
      //   }
      //   handleShow();
      // }).finally(() => {
      //   hideLoading();
      //   setTimeout(() => {
      //     handleClose();
      //   }, 3000);
      // });

      let rsCreateUser = await axios.post(
        config.get("apiUrl") + "/api/employee",
        JSON.stringify(params),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      if (rsCreateUser.data.status === "success") {
        try {
          if (data.work_bu1 !== "") {
            let rsNickname = await axios.get(
              config.get("apiUrl") + "/api/master/org/view/" + data.work_bu1,
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  Authorization: `bearer ${keycloak.token}`,
                },
              }
            );

            let division = null;

            if (data.work_bu5 != "") {
              division = workbu2.find((n) =>
                n.org_id.toString() === data.work_bu5.toString() ? n.name : null
              );
            }
            if (data.work_bu4 != "" && division === null) {
              division = workbu2.find((n) =>
                n.org_id.toString() === data.work_bu4.toString() ? n.name : null
              );
            }
            if (data.work_bu3 != "" && division === null) {
              division = workbu2.find((n) =>
                n.org_id.toString() === data.work_bu3.toString() ? n.name : null
              );
            }
            if (data.work_bu2 != "" && division === null) {
              division = workbu2.find((n) =>
                n.org_id.toString() === data.work_bu2.toString() ? n.name : null
              );
            }
            if (data.work_bu1 != "" && division === null) {
              division = workbu.find((n) =>
                n.org_id.toString() === data.work_bu1.toString() ? n.name : null
              );
            }

            const faceTitleData = title.find((d) =>
              d.title_id.toString() === data.title_id.toString()
                ? d.title_tname
                : null
            );

            let titleFaceData = titleFace.find((d) =>
              d.name === faceTitleData?.title_tname ? d.name : null
            );

            let position = null;

            if (empPosition.label !== undefined) {
              position = jobPosition.find((data) =>
                data.name === empPosition.label ? data.id : null
              );
              if (position == null) {
                // position = await Face48Api.createJob({
                //   jobPosition: empPosition.label,
                //   keycloak: keycloak,
                // });
              }
            }

            // if (titleFaceData == null) {
            //   titleFaceData = await Face48Api.createTitle({
            //     titleEmployee: faceTitleData,
            //     keycloak: keycloak,
            //   });
            // }

            let faceBody = {
              _type: "employee",
              title: titleFaceData?.id != null ? titleFaceData.id : null,
              firstName: data.fname,
              lastName: data.lname,
              gender: data.sex === "1" ? "male" : "female",
              birthDay:
                data.birthday === ""
                  ? ""
                  : moment(data.birthday, "DD/MM/YYYY")
                      .add(-543, "year")
                      .format("YYYY-MM-DD"),
              thaiCitizenId: data.id_card,
              mobilePhone: data.mobile,
              email: data.email,
              licensePlate: null,
              company: config.get("face48CompanyId"),
              department: null,
              jobPosition: position?.id != null ? position.id : null,
              employeeId: data.employee_id,
              employeeClass: "ordinary",
              division: division?.name != null ? division.name : null,
              accessControlRuleIds: [config.get("face48AccessControlRoleId")],
            };

            let formData = new FormData();
            formData.append(
              "department",
              rsNickname.data.data.records[0].nickname
            );
            formData.append("firstname_th", data.fname);
            formData.append("lastname_th", data.lname);
            // let updateNickname = await axios.post(config.get("apiFaceReg") + "/main/person/"+data.employee_id, formData, {headers: {"Content-Type": "multipart/form-data",Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,},});

            // let face48employee = await Face48Api.saveEmployee({
            //   employeeId: data.employee_id,
            //   face48People: faceBody,
            //   keycloak: keycloak,
            // });
            setFace48PeopleId(face48employee.id);
            if (avatarImgFile !== null) {
              // Face48Api.uploadAvatarEmployee({
              //   peopleId: face48employee.id,
              //   file: avatarImgFile,
              //   keycloak,
              // });
            }
            if (photoImgFile !== null) {
              // Face48Api.uploadPhotoEmployee({
              //   peopleId: face48employee.id,
              //   file: photoImgFile,
              //   keycloak,
              // });
            }
          }
        } catch (error) {
          console.log("Createface48 : ", error);
          hideLoading();
          setTimeout(() => {
            handleClose();
          }, 3000);
        }
        handleShow();
        setTextMsg("บันทึกข้อมูลเรียบร้อย");
        hideLoading();
        setTimeout(() => {
          handleClose();
        }, 3000);
      } else {
        handleShow();
        setTextMsg("บันทึกไม่สำเร็จ");
        hideLoading();
        setTimeout(() => {
          handleClose();
        }, 3000);
      }
    } catch (error) {
      let msg = "Username หรือ Email ซ้ำ";
      handleShow();
      setTextMsg(msg);
      hideLoading();
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  };

  const updateUser = async (data) => {
    try {
      let empCompetency = data.empCompetency;
      let hasZeroCompetencyLevel = empCompetency.some(
        (item) => item.competency_level === "0"
      );

      let params = {
        employee_id: data.employee_id,
        email: data.email,
        password: data.password,
        id_card: data.id_card,
        passport: data.passport,
        title_id: data.title_id,
        fname: data.fname,
        lname: data.lname,
        efname: data.efname,
        elname: data.elname,
        birthday:
          data.birthday === ""
            ? ""
            : moment(data.birthday, "DD/MM/YYYY")
                .add(-543, "year")
                .format("YYYY-MM-DD"),
        telephone: data.telephone,
        sex: data.sex,
        status_id: data.status_id,
        start_date:
          data.start_date === ""
            ? ""
            : moment(data.start_date, "DD/MM/YYYY")
                .add(-543, "year")
                .format("YYYY-MM-DD"),
        resign_date:
          data.resign_date === ""
            ? ""
            : moment(data.resign_date, "DD/MM/YYYY")
                .add(-543, "year")
                .format("YYYY-MM-DD"),
        emp_type_id: data.emp_type_id,
        job_id: empJob ? empJob.label : "",
        job_id_code: empJob ? empJob.value : null,
        position_type: data.position_type,
        mobile: data.mobile,
        law_bu1: data.law_bu1,
        law_bu2: data.law_bu2,
        law_bu3: data.law_bu3,
        law_bu4: data.law_bu4,
        law_bu5: data.law_bu5,
        law_bu6: data.law_bu6,
        work_bu1: data.work_bu1,
        work_bu2: data.work_bu2,
        work_bu3: data.work_bu3,
        work_bu4: data.work_bu4,
        work_bu5: data.work_bu5,
        work_bu6: data.work_bu6,
        emp_position: empPosition ? empPosition.label : "",
        emp_position_id: empPosition ? empPosition.value : null,
        field_of_work: data.field_of_work,
        salary:
          typeof data.salary !== "undefined" && data.salary != null
            ? data.salary.replace(",", "")
            : data.salary,
        position_number: data.position_number,
        card_no: data.card_no,
        shift_id: data.shift_id,
        holiday_group_id: data.holiday_group_id,
        picture: empImg,
        signature: empSign,
        center: flagCenter,
        user_active: data.user_active,
        role_id: [data.role_id],
        org_id: [data.work_bu1, data.work_bu2, data.work_bu3],
        emp_address: {
          address_no: data.address_no,
          village: data.village,
          moo: data.moo,
          road: data.road,
          sub_district: data.sub_district,
          district: data.district,
          province: data.province,
          postcode: data.postcode,
          address_type: 0,
          run_no: 0,
        },
        emp_education: [
          {
            education_level: data.education_level,
            educational_backgroup: data.educational_backgroup,
            abbreviations: data.abbreviations,
            major: data.major,
            institution: data.institution,
            graduation_date:
              data.graduation_date === ""
                ? ""
                : moment(data.graduation_date, "DD/MM/YYYY")
                    .add(-543, "year")
                    .format("YYYY-MM-DD"),
            graduation_year: data.graduation_year,
            attachment: empTranscript,
          },
          {
            education_level: useBox2 == 2 ? data.education_level2 : "",
            educational_backgroup:
              useBox2 == 2 ? data.educational_backgroup2 : "",
            abbreviations: useBox2 == 2 ? data.abbreviations2 : "",
            major: useBox2 == 2 ? data.major2 : "",
            institution: useBox2 == 2 ? data.institution2 : "",
            graduation_date:
              data.graduation_date2 === ""
                ? ""
                : moment(data.graduation_date2, "DD/MM/YYYY")
                    .add(-543, "year")
                    .format("YYYY-MM-DD"),
            graduation_year: useBox2 == 2 ? data.graduation_year2 : "",
            attachment: empTranscript,
          },
          {
            education_level: useBox3 == 2 ? data.education_level3 : "",
            educational_backgroup:
              useBox3 == 2 ? data.educational_backgroup3 : "",
            abbreviations: useBox3 == 2 ? data.abbreviations3 : "",
            major: useBox3 == 2 ? data.major3 : "",
            institution: data.institution3,
            graduation_date:
              data.graduation_date3 === ""
                ? ""
                : moment(data.graduation_date3, "DD/MM/YYYY")
                    .add(-543, "year")
                    .format("YYYY-MM-DD"),
            graduation_year: useBox3 == 2 ? data.graduation_year3 : "",
            attachment: empTranscript,
          },
          {
            education_level: useBox4 == 2 ? data.education_level4 : "",
            educational_backgroup:
              useBox4 == 2 ? data.educational_backgroup4 : "",
            abbreviations: useBox4 == 2 ? data.abbreviations4 : "",
            major: useBox4 == 2 ? data.major4 : "",
            institution: useBox4 == 2 ? data.institution4 : "",
            graduation_date:
              data.graduation_date4 === ""
                ? ""
                : moment(data.graduation_date4, "DD/MM/YYYY")
                    .add(-543, "year")
                    .format("YYYY-MM-DD"),
            graduation_year: useBox4 == 2 ? data.graduation_year4 : "",
            attachment: empTranscript,
          },
          {
            education_level: useBox5 == 2 ? data.education_level5 : "",
            educational_backgroup:
              useBox5 == 2 ? data.educational_backgroup5 : "",
            abbreviations: useBox5 == 2 ? data.abbreviations5 : "",
            major: useBox5 == 2 ? data.major5 : "",
            institution: useBox5 == 2 ? data.institution5 : "",
            graduation_date:
              data.graduation_date5 === ""
                ? ""
                : moment(data.graduation_date5, "DD/MM/YYYY")
                    .add(-543, "year")
                    .format("YYYY-MM-DD"),
            graduation_year: useBox5 == 2 ? data.graduation_year5 : "",
            attachment: empTranscript,
          },
        ],
        emp_prev_salary: {
          salary:
            typeof data.prev_salary !== "undefined" && data.prev_salary != null
              ? data.prev_salary.replace(",", "")
              : data.prev_salary,
          effective_date:
            data.peffective_date === ""
              ? ""
              : moment(data.peffective_date, "DD/MM/YYYY")
                  .add(-543, "year")
                  .format("YYYY-MM-DD"),
        },
        emp_curr_salary: {
          salary:
            typeof data.salary !== "undefined" && data.salary != null
              ? data.salary.replace(",", "")
              : data.salary,
          effective_date:
            data.ceffective_date === ""
              ? ""
              : moment(data.ceffective_date, "DD/MM/YYYY")
                  .add(-543, "year")
                  .format("YYYY-MM-DD"),
        },
        fac_picture: {
          pic_1: empPic1,
          pic_2: empPic2,
          pic_3: empPic3,
          pic_4: empPic4,
          pic_5: empPic5,
          pic_6: empPic6,
        },
        skill_lang_1: data.skill_lang_1,
        skill_lang_2: data.skill_lang_2,
        skill_lang_3: data.skill_lang_3,
        skill_computer_1: flagSkillComputer1,
        skill_computer_2: flagSkillComputer2,
        skill_computer_other: flagSkillComputerOther,
        skill_computer_other_text: data.skill_computer_other_text,
        skill_typing_thai: data.skill_typing_thai,
        skill_typing_eng: data.skill_typing_eng,
        skill_drive_1: flagSkillDrive1,
        skill_drive_2: flagSkillDrive2,
        skill_drive_3: flagSkillDrive3,
        skill_drive_4: flagSkillDrive4,
        skill_drive_5: flagSkillDrive5,
        skill_other_1: data.skill_other_1,
        skill_other_2: data.skill_other_2,
        skill_other_3: data.skill_other_3,
        skill_other_4: data.skill_other_4,
        skill_other_5: data.skill_other_5,
        lawbu_2_text: data.lawbu_2_text,
        lawbu_3_text: data.lawbu_3_text,
        lawbu_4_text: data.lawbu_4_text,
        lawbu_5_text: data.lawbu_5_text,
        lawbu_6_text: data.lawbu_6_text,
        workbu_2_text: data.workbu_2_text,
        workbu_3_text: data.workbu_3_text,
        workbu_4_text: data.workbu_4_text,
        workbu_5_text: data.workbu_5_text,
        workbu_6_text: data.workbu_6_text,
        skill_toeic: flagSkillToeic,
        skill_tofel: flagSkillTofel,
        skill_ielts: flagSkillIelts,
        skill_cutep: flagSkillCutep,
        skill_tutep: flagSkillTutep,
        skill_toeic_file: empToeic,
        skill_tofel_file: empTofel,
        skill_ielts_file: empIelts,
        skill_cutep_file: empCutep,
        skill_tutep_file: empTutep,
        skill_lang_1_level: skillLang1LV,
        skill_lang_2_level: skillLang2LV,
        skill_lang_3_level: skillLang3LV,
        skill_toeic_point: data.skill_toeic_point,
        skill_tofel_point: data.skill_tofel_point,
        skill_ielts_point: data.skill_ielts_point,
        skill_cutep_point: data.skill_cutep_point,
        skill_tutep_point: data.skill_tutep_point,

        skill_com_1: skillCom1,
        skill_com_1_level: skillCom1LV,
        skill_com_2: skillCom2,
        skill_com_2_level: skillCom2LV,
        skill_com_service: data.skill_com_service,
        skill_com_service_level: skillComServiceLV,
        skill_com_server: data.skill_com_server,
        skill_com_server_level: skillComServerLV,
        skill_com_graphic: skillComGraphic,
        skill_com_graphic_level: skillComGraphicLV,
        skill_com_vedio: skillComVedio,
        skill_com_vedio_level: skillComVedioLV,
        skill_com_office: skillComOffice,
        skill_com_office_level: skillComOfficeLV,
        skill_sport: data.skill_sport,
        skill_sport_level: skillSportLV,
        skill_music: data.skill_music,
        skill_music_level: skillMusicLV,
        skill_hobby: data.skill_hobby,
        skill_hobby_level: skillHobbyLV,

        cost_id: data.cost_id,
        place_status: data.statusGoGov_id,
        place_org: data.statusGoGov_org,
        place_other: data.statusGoGov_other,
        situation_status: data.situation_id,
        hazard_id: data.hazard_id,
        emp_mission: employeeMission,
        emp_expert: data.empExpert,
        emp_competency: hasZeroCompetencyLevel ? null : data.empCompetency,
        emp_course: data.empCourse,
      };

      // console.log("params", params);

      // face48People.EMPID = data.employee_id;
      // face48People.birthDay =
      //   data.birthday === ""
      //     ? ""
      //     : moment(data.birthday, "DD/MM/YYYY")
      //         .add(-543, "year")
      //         .format("YYYY-MM-DD");
      // face48People.email = data.email;
      // face48People.mobilePhone = data.mobile;
      // face48People.passportId = data.passport;
      // face48People.firstName = data.fname;
      // face48People.lastName = data.lname;
      // face48People.company = config.get("face48CompanyId");
      // face48People.accessControlRuleIds = [
      //   config.get("face48AccessControlRoleId"),
      // ];
      // face48People.title = null;

      let division = null;

      if (data.work_bu5 != "") {
        division = workbu2.find((n) =>
          n.org_id.toString() === data.work_bu5.toString() ? n.name : null
        );
      }
      if (data.work_bu4 != "" && division === null) {
        division = workbu2.find((n) =>
          n.org_id.toString() === data.work_bu4.toString() ? n.name : null
        );
      }
      if (data.work_bu3 != "" && division === null) {
        division = workbu2.find((n) =>
          n.org_id.toString() === data.work_bu3.toString() ? n.name : null
        );
      }
      if (data.work_bu2 != "" && division === null) {
        division = workbu2.find((n) =>
          n.org_id.toString() === data.work_bu2.toString() ? n.name : null
        );
      }
      if (data.work_bu1 != "" && division === null) {
        division = workbu.find((n) =>
          n.org_id.toString() === data.work_bu1.toString() ? n.name : null
        );
      }

      const faceTitleData = title.find((d) =>
        d.title_id.toString() === data.title_id.toString()
          ? d.title_tname
          : null
      );

      let titleFaceData = titleFace.find((d) =>
        d.name === faceTitleData?.title_tname ? d.name : null
      );

      let position = null;

      if (empPosition?.label) {
        position = jobPosition.find((data) =>
          data.name === empPosition.label ? data.id : null
        );
        if (position == null) {
          // position = await Face48Api.createJob({
          //   jobPosition: empPosition.label,
          //   keycloak: keycloak,
          // });
        }
      }

      if (titleFaceData == null) {
        // titleFaceData = await Face48Api.createTitle({
        //   titleEmployee: faceTitleData,
        //   keycloak: keycloak,
        // });
      }

      let faceBody = {
        _type: "employee",
        title: titleFaceData?.id != null ? titleFaceData.id : null,
        firstName: data.fname,
        lastName: data.lname,
        gender: data.sex === "1" ? "male" : "female",
        birthDay:
          data.birthday === ""
            ? ""
            : moment(data.birthday, "DD/MM/YYYY")
                .add(-543, "year")
                .format("YYYY-MM-DD"),
        thaiCitizenId: data.id_card,
        mobilePhone: data.mobile,
        email: data.email,
        licensePlate: null,
        company: config.get("face48CompanyId"),
        department: null,
        jobPosition: position?.id != null ? position.id : null,
        employeeId: data.employee_id,
        employeeClass: "ordinary",
        division: division?.name != null ? division.name : null,
        accessControlRuleIds: [config.get("face48AccessControlRoleId")],
      };

      // face48People.thaiCitizenId = data.id_card;
      // face48People.gender = data.sex == "1" ? "male" : "female";
      // if(face48People.title != null){
      //   face48People.title.name = data.title_id;
      // }else{
      //   face48People.title = {
      //     name: data.title_id,
      //     id:
      //   }
      // }
      // if(face48People.jobPosition != null){
      //   face48People.jobPosition.name = empPosition ? empPosition.label : '';
      // }else{
      //   face48People.jobPosition = {
      //     name:empPosition ? empPosition.label : '',
      //   }
      // }

      showLoading();
      // await axios.post(config.get("apiUrl") + "/api/employee/" + data.employee_id, JSON.stringify(params), {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `bearer ${keycloak.token}`,
      //   },
      // }).then((res) => {
      //   setEmpSearch(data.employee_id);
      //   setHasSearchUser(true);
      //   handleShow();
      //   setTextMsg("บันทึกข้อมูลเรียบร้อย");
      //   setTimeout(() => {
      //     handleClose();
      //   }, 3000);
      // }).catch((error) => {
      //   if (error.response) {
      //     setTextMsg(error.response.data.message_th || error.response.data.message);
      //   } else {
      //     setTextMsg("บันทึกไม่สำเร็จ");
      //   }
      //   handleShow();
      // }).finally(() => {
      //   hideLoading();
      //   setTimeout(() => {
      //     handleClose();
      //   }, 3000);
      // });
      let rsUpdateUser = await axios.post(
        config.get("apiUrl") + "/api/employee/" + data.employee_id,
        JSON.stringify(params),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      if (rsUpdateUser.data.status === "success") {
        try {
          if (data.work_bu1 !== "") {
            let rsNickname = await axios.get(
              config.get("apiUrl") + "/api/master/org/view/" + data.work_bu1,
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  Authorization: `bearer ${keycloak.token}`,
                },
              }
            );

            if (data.status_id == 1) {
              if (imgSrc != null) {
                const response = await axios.get(imgSrc, {
                  responseType: "arraybuffer",
                });
                const imageBlob = new Blob([response.data], {
                  type: "image/jpeg",
                });
                let formData = new FormData();
                formData.append("field", "pic1");
                formData.append("file", imageBlob);
                formData.append("employeeId", data.employee_id);

                try {
                  let rsImg = await axios.post(
                    config.get("apiUrl") + "/api/file/face-picture",
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `bearer ${keycloak.token}`,
                      },
                    }
                  );
                  console.log("rsImg", rsImg.status);
                } catch (error) {
                  console.log(error);
                }
                // try {
                //   let formData = new FormData();
                //   formData.append("img", imageBlob);
                //   formData.append("person_id", data.employee_id);
                //   formData.append("isMask", false);
                //   let chkQuality = await axios.post(
                //     config.get("apiFaceReg") + "/main/face/detection",
                //     formData,
                //     {
                //       headers: {
                //         "Content-Type": "multipart/form-data",
                //         Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,
                //       },
                //     }
                //   );
                //   if (chkQuality.data.error === false) {
                //     if (parseInt(chkQuality.data.data.quality) > 80) {
                //       try {
                //         let chkFaceReg = await axios.post(
                //           config.get("apiFaceReg") + "/main/face/storing",
                //           formData,
                //           {
                //             headers: {
                //               "Content-Type": "multipart/form-data",
                //               Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,
                //             },
                //           }
                //         );
                //         let rsFace48QC = await Face48Api.checkQualityPhotoFace({
                //           file: data,
                //           keycloak,
                //         });
                //         if (rsFace48QC.data.code === 0) {
                //           setPhotoImgFile(data);
                //           let formDataImg = new FormData();
                //           formDataImg.append("file", dataURItoBlob(data));
                //           let rsImg = await axios.post(
                //             config.get("apiUrl") + "/api/file",
                //             formDataImg,
                //             {
                //               headers: {
                //                 "Content-Type": "multipart/form-data",
                //                 Authorization: `bearer ${keycloak.token}`,
                //               },
                //             }
                //           );
                //           setEmpPic1(rsImg.data.data.file_id);
                //           $("#overlay").hide();
                //         } else {
                //           setPhotoImgFile(null);
                //           $("#overlay").hide();
                //           handleShow();
                //           setTextMsg(rsFace48QC.data.desc);
                //         }
                //         if (chkFaceReg.data.error === false) {
                //         }
                //       } catch (error) {
                //         let msg = error.response.data.message;
                //         if (msg === "No face detected or more than one face") {
                //           msg = "ตรวจไม่พบหน้าบนรูป";
                //         } else {
                //           msg = error.response.data.message;
                //         }
                //         $("#overlay").hide();
                //         handleShow();
                //         setTextMsg(msg);
                //       }
                //     } else {
                //       $("#overlay").hide();
                //       handleShow();
                //       setTextMsg(
                //         "รูปของท่านไม่ผ่านเกณฑ์ กรุณาทำรายการใหม่อีกครั้ง"
                //       );
                //     }
                //   }
                // } catch (error) {
                //   console.log(error);
                // }
              }

              // let updateNickname = await axios.post(config.get("apiFaceReg") + "/main/person/"+data.employee_id, formData, {headers: {"Content-Type": "multipart/form-data",Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,},});
              // let face48employee = await Face48Api.mainSaveEmployee({
              //   peopleId: data.employee_id,
              //   face48People: faceBody,
              //   keycloak: keycloak,
              //   face48PeopleId: face48PeopleId,
              // });
              // setFace48PeopleId(face48employee.id);
              //
              // if (photoImgFile != null) {
              //   Face48Api.uploadAvatarEmployee({
              //     peopleId: face48employee.id,
              //     file: photoImgFile,
              //     keycloak,
              //   });
              // }
              //
              // if (photoImgFile != null) {
              //   Face48Api.uploadPhotoEmployee({
              //     peopleId: face48employee.id,
              //     file: photoImgFile,
              //     keycloak,
              //   });
              // }
            } else {
              // let face48Responses = await Face48Api.deleteEmployee({
              //   peopleId: face48PeopleId,
              //   keycloak: keycloak,
              // });
              // console.log("delete face48 : ", face48Responses);
              if (empPic1 != "") {
                let formData1 = new FormData();
                formData1.append("field", "pic1");
                formData1.append("employeeId", empUsername);
                await axios
                  .post(
                    config.get("apiUrl") + "/api/file/face-picture/delete",
                    formData1,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `bearer ${keycloak.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    setEmpPic1("");
                    setImgSrc(null);
                  });
              }
              if (empPic2 != "") {
                let formData2 = new FormData();
                formData2.append("field", "pic2");
                formData2.append("employeeId", empUsername);
                await axios
                  .post(
                    config.get("apiUrl") + "/api/file/face-picture/delete",
                    formData2,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `bearer ${keycloak.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    setEmpPic2("");
                    setImgSrc2(null);
                  });
              }
              if (empPic3 != "") {
                let formData3 = new FormData();
                formData3.append("field", "pic3");
                formData3.append("employeeId", empUsername);
                await axios
                  .post(
                    config.get("apiUrl") + "/api/file/face-picture/delete",
                    formData3,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `bearer ${keycloak.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    setEmpPic3("");
                    setImgSrc3(null);
                  });
              }
              if (empPic4 != "") {
                let formData4 = new FormData();
                formData4.append("field", "pic4");
                formData4.append("employeeId", empUsername);
                await axios
                  .post(
                    config.get("apiUrl") + "/api/file/face-picture/delete",
                    formData4,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `bearer ${keycloak.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    setEmpPic4("");
                    setImgSrc4(null);
                  });
              }
              if (empPic5 != "") {
                let formData5 = new FormData();
                formData5.append("field", "pic5");
                formData5.append("employeeId", empUsername);
                await axios
                  .post(
                    config.get("apiUrl") + "/api/file/face-picture/delete",
                    formData5,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `bearer ${keycloak.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    setEmpPic5("");
                    setImgSrc5(null);
                  });
              }
              if (empPic6 != "") {
                let formData6 = new FormData();
                formData6.append("field", "pic6");
                formData6.append("employeeId", empUsername);
                await axios
                  .post(
                    config.get("apiUrl") + "/api/file/face-picture/delete",
                    formData6,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `bearer ${keycloak.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    setEmpPic6("");
                    setImgSrc6(null);
                  });
              }
            }
          }
        } catch (error) {
          console.log("Createface48 : ", error);
          hideLoading();
          setTimeout(() => {
            handleClose();
          }, 3000);
        }
        handleShow();
        setTextMsg("บันทึกข้อมูลเรียบร้อย");
        hideLoading();
        onClickSearch();
        setTimeout(() => {
          handleClose();
        }, 3000);
      } else {
        handleShow();
        setTextMsg("บันทึกไม่สำเร็จ");
        hideLoading();
        setTimeout(() => {
          handleClose();
        }, 3000);
      }
    } catch (error) {
      console.log("update48", error);
      let msg = "Username หรือ Email +ซ้ำ";
      handleShow();
      setTextMsg(msg);
      hideLoading();
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  };

  const deleteUser = async () => {
    handleDelClose();
    showLoading();

    let delImg1 = new FormData();
    delImg1.append("person_id", empSearch);
    delImg1.append("isMask", false);
    // try {await axios.post(config.get("apiFaceReg") + "/main/face/deleting", delImg1, {headers: {"Content-Type": "multipart/form-data",Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,},});} catch (error) {}
    let delImg2 = new FormData();
    delImg2.append("person_id", empSearch);
    delImg2.append("isMask", true);

    try {
      await Face48Api.deleteEmployee({ peopleId: face48PeopleId, keycloak });
      setFace48PeopleId(null);
    } catch (error) {}

    await axios
      .delete(config.get("apiUrl") + "/api/employee/" + empSearch, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then(() => {
        handleShow();
        setTextMsg("ลบข้อมูลเรียบร้อย");
        navigate.push("/admin-personnel-infomation-datatable");
      })
      .catch((error) => {
        hideLoading();
        handleShow();
        setTextMsg("ลบข้อมูลไม่สำเร็จ");
      });
  };
  const [imgPreview, setImgPreview] = useState("img/person_avatar.svg");
  const [signPreview, setSignPreview] = useState("img/sign-pad.svg");
  const [maskPreview, setMaskPreview] = useState("img/person_mask.svg");
  const [allWorkDate, setAllWorkDate] = useState("- ปี");
  const [empTranscript, setEmpTranscript] = useState("");
  const getAllWorkDate = (cdate, sdate) => {
    /*var years = a.diff(b, 'year');
        b.add(years, 'years');

    var months = a.diff(b, 'months');
        b.add(months, 'months');

    var days = a.diff(b, 'days');*/
    //setAllWorkDate(years + ' ปี ' + months + ' เดือน ' + days + ' วัน');
    /*var years = moment(sdate, 'YYYY-MM-DD').startOf('day').diff(moment(cdate, 'YYYY-MM-DD').startOf('day'), 'year');
        moment(cdate, 'YYYY-MM-DD').startOf('day').add(years, 'years');
    var months = moment(sdate, 'YYYY-MM-DD').startOf('day').diff(moment(cdate, 'YYYY-MM-DD').startOf('day'), 'months');
        moment(cdate, 'YYYY-MM-DD').startOf('day').add(months, 'months');
    var days = moment(sdate, 'YYYY-MM-DD').startOf('day').diff(moment(cdate, 'YYYY-MM-DD').startOf('day'), 'days');
    var y_convert = ((days/30)/12);
    var m_convert = (days-(Math.floor(y_convert)*365))/30;
    var d_convert = (days-((Math.floor(y_convert)*365)+(Math.floor(m_convert)*30)));
    //console.log(Math.floor(y_convert), Math.floor(m_convert), Math.floor(d_convert));*/
    var d1 = new Date(sdate); // April 5, 2014
    var d2 = new Date(cdate);
    var diff = new Date(
      d1.getFullYear() - d2.getFullYear(),
      d1.getMonth() - d2.getMonth(),
      d1.getDate() - d2.getDate()
    );
    //return "Year :- " + year + " Month :- " + month + " Days :-" + days;
    setAllWorkDate(
      diff.getYear() +
        " ปี " +
        diff.getMonth() +
        " เดือน " +
        diff.getDate() +
        " วัน "
    );
  };
  const searchUser = async (data, objMasterData) => {
    showLoading();
    reset({});
    setImgSrc(null);
    setImgSrc2(null);
    setImgSrc3(null);
    setImgSrc4(null);
    setImgSrc5(null);
    setImgSrc6(null);
    setEmpPic1("");
    setEmpPic2("");
    setEmpPic3("");
    setEmpPic4("");
    setEmpPic5("");
    setEmpPic6("");
    setEmpSign("");
    setEmptitle("");
    setEmpActive("");
    setEmpEduLv("");
    setEmpSubDistrict("");
    setEmpDistrict("");
    setEmpProvince("");
    setEmpJob("");
    setEmpPosition("");
    setWorkbu2([]);
    setWorkbu3([]);
    setWorkbu4([]);
    setWorkbu5([]);
    setWorkbu6([]);
    setLawbu2([]);
    setLawbu3([]);
    setLawbu4([]);
    setLawbu5([]);
    setLawbu6([]);
    setBirthDate(null);
    setResignDate(null);
    setStartDate(null);
    setCurrentSaralyDate(null);
    setPrevSaralyDate(null);
    setEduDate(null);
    setEmpWorkBu1("");
    setEmpWorkBu2("");
    setEmpWorkBu3("");
    setEmpWorkBu4("");
    setEmpWorkBu5("");
    setEmpWorkBu6("");
    setEmpLawBu1("");
    setEmpLawBu2("");
    setEmpLawBu3("");
    setEmpLawBu4("");
    setEmpLawBu5("");
    setEmpLawBu6("");
    setEmpPassword("");
    setEmployee([]);
    setEmployeeAddress([]);
    setEmployeeSaraly([]);
    setEmployeeSaralyPrev([]);
    setEmployeeEducation([]);
    setEditSalary(0);
    setEditPassword(0);
    setEmpImgView("img/avatar.png");
    setEmpSignView("img/noimage.png");
    setSkillLang1("");
    setSkillLang2("");
    setSkillLang3("");
    setSkillLang1LV("");
    setSkillLang2LV("");
    setSkillLang3LV("");
    setFlagSkillcomputer1(0);
    setFlagSkillcomputer2(0);
    setFlagSkillcomputerOther(0);
    setFlagSkillDrive1(0);
    setFlagSkillDrive2(0);
    setFlagSkillDrive3(0);
    setFlagSkillDrive4(0);
    setFlagSkillDrive5(0);
    setFlagSkillDrive6(0);
    setEmpTranscript("");
    setFlagSkillToeic(0);
    setFlagSkillTofel(0);
    setFlagSkillIelts(0);
    setFlagSkillCutep(0);
    setFlagSkillTutep(0);
    setEmpToeic("");
    setEmpTofel("");
    setEmpIelts("");
    setEmpCutep("");
    setEmpTutep("");
    setAllWorkDate("0 ปี");
    setEmpEduLv2("");
    setEmployeeEducation2([]);
    setEduDate2(null);
    setEduDate3(null);
    setEduDate4(null);
    setEduDate5(null);
    setSkillCom1("");
    setSkillCom1LV("");
    setSkillCom2("");
    setSkillCom2LV("");
    setSkillComServiceLV("");
    setSkillComServerLV("");
    setSkillComGraphic("");
    setSkillComVedio("");
    setSkillComOffice("");
    setSkillComGraphicLV("");
    setSkillComVedioLV("");
    setSkillComOfficeLV("");
    setSkillSportLV("");
    setSkillMusicLV("");
    setSkillHobbyLV("");
    setEmpUsername("");
    setEmpPassword("");
    setEmpCostId("");
    setCostCenterList([]);
    setAvatarImgFile(null);
    setPhotoImgFile(null);
    let rsSearchUser;
    try {
      rsSearchUser = await axios.get(
        config.get("apiUrl") + "/api/employee/" + data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      setHasSearchUser(true);
    } catch (error) {
      // console.log('Error !!')
      rsSearchUser = {
        data: {
          status: "error",
        },
      };
    }

    if (rsSearchUser.data.status === "success") {
      // let face48Token = await Face48Api.getToken(keycloak);
      // setFace48Token(face48Token);
      console.log("after serch user : ", rsSearchUser.data);
      // try {
      //   let face48People = await Face48Api.getPeopleId({
      //     employeeId: data,
      //     keycloak,
      //   });
      //   setFace48People(face48People);
      //   if (face48People == null) {
      //     setFace48PeopleId(null);
      //   } else {
      //     setFace48PeopleId(face48People.id);
      //   }
      // } catch (error) {
      //   hideLoading();
      // }

      // const titleData = await Face48Api.getTitle({ keycloak: keycloak });
      // setTitleFace(titleData);
      //
      // const jobPositionData = await Face48Api.getJob({ keycloak: keycloak });
      // setJobPosition(jobPositionData);

      setFlagCenter(rsSearchUser.data.data.center);
      setEmployeeSaraly(rsSearchUser.data.data.emp_curr_salary);
      setEmployeeSaralyPrev(rsSearchUser.data.data.emp_prev_salary);
      setEmployeeAddress(rsSearchUser.data.data.emp_address);
      setEmployeeEducation(rsSearchUser.data.data.emp_education[0]);

      if (rsSearchUser.data.data.emp_education[1]) {
        if (rsSearchUser.data.data.emp_education[1].education_level !== null) {
          setBox2(2);
          setEmployeeEducation2(rsSearchUser.data.data.emp_education[1]);
          setEmpEduLv2(rsSearchUser.data.data.emp_education[1].education_level);

          if (
            rsSearchUser.data.data.emp_education[1].graduation_date !== "" &&
            rsSearchUser.data.data.emp_education[1].graduation_date !== null
          ) {
            setEduDate2(
              moment(
                new Date(
                  rsSearchUser.data.data.emp_education[1].graduation_date
                )
              )
                .add(543, "year")
                .format("DD/MM/YYYY")
            );
            $(".graduation_date2")
              .datepicker()
              .datepicker(
                "setDate",
                moment(
                  new Date(
                    rsSearchUser.data.data.emp_education[1].graduation_date
                  )
                ).format("DD/MM/YYYY")
              );
          } else {
            setEduDate2(null);
          }
        }
      }

      if (rsSearchUser.data.data.emp_education[2]) {
        if (rsSearchUser.data.data.emp_education[2].education_level !== null) {
          setBox3(2);
          setEmployeeEducation3(rsSearchUser.data.data.emp_education[2]);
          setEmpEduLv3(rsSearchUser.data.data.emp_education[2].education_level);

          if (
            rsSearchUser.data.data.emp_education[2].graduation_date !== "" &&
            rsSearchUser.data.data.emp_education[2].graduation_date !== null
          ) {
            setEduDate3(
              moment(
                new Date(
                  rsSearchUser.data.data.emp_education[2].graduation_date
                )
              )
                .add(543, "year")
                .format("DD/MM/YYYY")
            );
            $(".graduation_date3")
              .datepicker()
              .datepicker(
                "setDate",
                moment(
                  new Date(
                    rsSearchUser.data.data.emp_education[2].graduation_date
                  )
                ).format("DD/MM/YYYY")
              );
          } else {
            setEduDate3(null);
          }
        }
      }

      if (rsSearchUser.data.data.emp_education[3]) {
        if (rsSearchUser.data.data.emp_education[3].education_level !== null) {
          setBox4(2);
          setEmployeeEducation4(rsSearchUser.data.data.emp_education[3]);
          setEmpEduLv4(rsSearchUser.data.data.emp_education[3].education_level);

          if (
            rsSearchUser.data.data.emp_education[3].graduation_date !== "" &&
            rsSearchUser.data.data.emp_education[3].graduation_date !== null
          ) {
            setEduDate4(
              moment(
                new Date(
                  rsSearchUser.data.data.emp_education[3].graduation_date
                )
              )
                .add(543, "year")
                .format("DD/MM/YYYY")
            );
            $(".graduation_date4")
              .datepicker()
              .datepicker(
                "setDate",
                moment(
                  new Date(
                    rsSearchUser.data.data.emp_education[3].graduation_date
                  )
                ).format("DD/MM/YYYY")
              );
          } else {
            setEduDate4(null);
          }
        }
      }

      if (rsSearchUser.data.data.emp_education[4]) {
        if (rsSearchUser.data.data.emp_education[4].education_level !== null) {
          setBox5(2);
          setEmployeeEducation5(rsSearchUser.data.data.emp_education[4]);
          setEmpEduLv5(rsSearchUser.data.data.emp_education[4].education_level);

          if (
            rsSearchUser.data.data.emp_education[4].graduation_date !== "" &&
            rsSearchUser.data.data.emp_education[4].graduation_date !== null
          ) {
            setEduDate5(
              moment(
                new Date(
                  rsSearchUser.data.data.emp_education[4].graduation_date
                )
              )
                .add(543, "year")
                .format("DD/MM/YYYY")
            );
            $(".graduation_date5")
              .datepicker()
              .datepicker(
                "setDate",
                moment(
                  new Date(
                    rsSearchUser.data.data.emp_education[4].graduation_date
                  )
                ).format("DD/MM/YYYY")
              );
          } else {
            setEduDate5(null);
          }
        }
      }

      if (rsSearchUser.data.data.emp_education[5]) {
        if (
          rsSearchUser.data.data.emp_education[5].educational_backgroup !== null
        ) {
          setBox6(2);
          setEmployeeEducation6(rsSearchUser.data.data.emp_education[5]);
          setEmpEduLv6(rsSearchUser.data.data.emp_education[5].education_level);
        }
      }

      if (rsSearchUser.data.data.emp_education[6]) {
        if (rsSearchUser.data.data.emp_education[6].education_level !== null) {
          setBox7(2);
          setEmployeeEducation7(rsSearchUser.data.data.emp_education[6]);
          setEmpEduLv7(rsSearchUser.data.data.emp_education[6].education_level);
        }
      }

      if (rsSearchUser.data.data.emp_education[7]) {
        if (rsSearchUser.data.data.emp_education[7].education_level !== null) {
          setBox8(2);
          setEmployeeEducation8(rsSearchUser.data.data.emp_education[7]);
          setEmpEduLv8(rsSearchUser.data.data.emp_education[7].education_level);
        }
      }

      if (rsSearchUser.data.data.emp_education[8]) {
        if (rsSearchUser.data.data.emp_education[8].education_level !== null) {
          setBox9(2);
          setEmployeeEducation9(rsSearchUser.data.data.emp_education[8]);
          setEmpEduLv9(rsSearchUser.data.data.emp_education[8].education_level);
        }
      }

      if (rsSearchUser.data.data.emp_education[9]) {
        if (rsSearchUser.data.data.emp_education[9].education_level !== null) {
          setBox10(2);
          setEmployeeEducation10(rsSearchUser.data.data.emp_education[9]);
          setEmpEduLv10(
            rsSearchUser.data.data.emp_education[9].education_level
          );
        }
      }

      // console.log(rsSearchUser.data.data);
      setEmployee(rsSearchUser.data.data);
      setEmpUsername(rsSearchUser.data.data.employee_id);
      setBirthDate(
        moment(new Date(rsSearchUser.data.data.birthday))
          .add(543, "year")
          .format("DD/MM/YYYY")
      );
      $(".birthday")
        .datepicker()
        .datepicker(
          "setDate",
          moment(new Date(rsSearchUser.data.data.birthday)).format("DD/MM/YYYY")
        );
      setStartDate(
        moment(new Date(rsSearchUser.data.data.start_date))
          .add(543, "year")
          .format("DD/MM/YYYY")
      );
      $(".start_date")
        .datepicker()
        .datepicker(
          "setDate",
          moment(new Date(rsSearchUser.data.data.start_date)).format(
            "DD/MM/YYYY"
          )
        );
      getAllWorkDate(
        moment(new Date(rsSearchUser.data.data.start_date)).format(
          "YYYY-MM-DD"
        ),
        moment(new Date()).format("YYYY-MM-DD")
      );
      if (
        rsSearchUser.data.data.resign_date !== "" &&
        rsSearchUser.data.data.resign_date !== null
      ) {
        setResignDate(
          moment(new Date(rsSearchUser.data.data.resign_date))
            .add(543, "year")
            .format("DD/MM/YYYY")
        );
        $(".resign_date")
          .datepicker()
          .datepicker(
            "setDate",
            moment(new Date(rsSearchUser.data.data.resign_date)).format(
              "DD/MM/YYYY"
            )
          );
      }
      if (rsSearchUser.data.data.emp_curr_salary) {
        setCurrentSaralyDate(
          moment(
            new Date(rsSearchUser.data.data.emp_curr_salary.effective_date)
          )
            .add(543, "year")
            .format("DD/MM/YYYY")
        );
        $(".ceffective_date")
          .datepicker()
          .datepicker(
            "setDate",
            moment(
              new Date(rsSearchUser.data.data.emp_curr_salary.effective_date)
            ).format("DD/MM/YYYY")
          );
      } else {
        setCurrentSaralyDate(new Date());
        setCurrentSaralyDate(
          moment(new Date()).add(543, "year").format("DD/MM/YYYY")
        );
        $(".ceffective_date")
          .datepicker()
          .datepicker("setDate", moment(new Date()).format("DD/MM/YYYY"));
      }
      if (rsSearchUser.data.data.emp_prev_salary) {
        setPrevSaralyDate(
          moment(
            new Date(rsSearchUser.data.data.emp_prev_salary.effective_date)
          )
            .add(543, "year")
            .format("DD/MM/YYYY")
        );
        $(".peffective_date")
          .datepicker()
          .datepicker(
            "setDate",
            moment(
              new Date(rsSearchUser.data.data.emp_prev_salary.effective_date)
            ).format("DD/MM/YYYY")
          );
      } else {
        setPrevSaralyDate(
          moment(new Date()).add(543, "year").format("DD/MM/YYYY")
        );
        $(".peffective_date")
          .datepicker()
          .datepicker("setDate", moment(new Date()).format("DD/MM/YYYY"));
      }
      setEmployeeFac(rsSearchUser.data.data.fac_picture);
      setEmptitle(rsSearchUser.data.data.title_id);
      setEmpSex(rsSearchUser.data.data.sex);
      setEmpActive(rsSearchUser.data.data.user_active);
      if (rsSearchUser.data.data.role_id[0]) {
        setEmpRole(rsSearchUser.data.data.role_id[0]);
      }

      setEmpJob(
        objMasterData.jobId.find((obj) => {
          if (rsSearchUser.data.data.job_id_code) {
            return obj.value == rsSearchUser.data.data.job_id_code;
          }
          return obj.label == rsSearchUser.data.data.job_id;
        })
      );

      setEmpPosition(
        objMasterData.position.find((obj) => {
          if (rsSearchUser.data.data.emp_position_id) {
            return obj.value == rsSearchUser.data.data.emp_position_id;
          }
          return obj.label == rsSearchUser.data.data.emp_position;
        })
      );

      setEmpStatusId(rsSearchUser.data.data.status_id);
      setEmpImg(rsSearchUser.data.data.picture);
      setEmpSign(rsSearchUser.data.data.signature);
      setEmpTypeId(rsSearchUser.data.data.emp_type_id);
      setEmpWorkBu1(rsSearchUser.data.data.work_bu1);
      setEmpWorkBu2(rsSearchUser.data.data.work_bu2);
      setEmpWorkBu3(rsSearchUser.data.data.work_bu3);
      setEmpWorkBu4(rsSearchUser.data.data.work_bu4);
      setEmpWorkBu5(rsSearchUser.data.data.work_bu5);
      setEmpWorkBu6(rsSearchUser.data.data.work_bu6);
      setEmpLawBu1(rsSearchUser.data.data.law_bu1);
      setEmpLawBu2(rsSearchUser.data.data.law_bu2);
      setEmpLawBu3(rsSearchUser.data.data.law_bu3);
      setEmpLawBu4(rsSearchUser.data.data.law_bu4);
      setEmpLawBu5(rsSearchUser.data.data.law_bu5);
      setEmpLawBu6(rsSearchUser.data.data.law_bu6);
      setEmpShipId(rsSearchUser.data.data.shift_id);
      setEmpHolidayGroupId(rsSearchUser.data.data.holiday_group_id);

      if (rsSearchUser.data.data.fac_picture) {
        if (rsSearchUser.data.data.fac_picture.pic_1) {
          setEmpPic1(rsSearchUser.data.data.fac_picture.pic_1);
          setImgSrc(
            config.get("apiUrl") +
              "/api/file/" +
              rsSearchUser.data.data.fac_picture.pic_1
          );
          fetch(
            config.get("apiUrl") +
              "/api/file/" +
              rsSearchUser.data.data.fac_picture.pic_1
          )
            .then((response) => response.blob())
            .then((blob) => {
              const file = new File([blob], "image.jpg", { type: blob.type });
              setPhotoImgFile(file);
            })
            .catch((error) => console.error("Error fetching the file:", error));
        }

        if (rsSearchUser.data.data.fac_picture.pic_2) {
          setEmpPic2(rsSearchUser.data.data.fac_picture.pic_2);
          setImgSrc2(
            config.get("apiUrl") +
              "/api/file/" +
              rsSearchUser.data.data.fac_picture.pic_2
          );
        }

        if (rsSearchUser.data.data.fac_picture.pic_3) {
          setEmpPic3(rsSearchUser.data.data.fac_picture.pic_3);
          setImgSrc3(
            config.get("apiUrl") +
              "/api/file/" +
              rsSearchUser.data.data.fac_picture.pic_3
          );
        }

        if (rsSearchUser.data.data.fac_picture.pic_4) {
          setEmpPic4(rsSearchUser.data.data.fac_picture.pic_4);
          setImgSrc4(
            config.get("apiUrl") +
              "/api/file/" +
              rsSearchUser.data.data.fac_picture.pic_4
          );
        }

        if (rsSearchUser.data.data.fac_picture.pic_5) {
          setEmpPic5(rsSearchUser.data.data.fac_picture.pic_5);
          setImgSrc5(
            config.get("apiUrl") +
              "/api/file/" +
              rsSearchUser.data.data.fac_picture.pic_5
          );
        }

        if (rsSearchUser.data.data.fac_picture.pic_6) {
          setEmpPic6(rsSearchUser.data.data.fac_picture.pic_6);
          setImgSrc6(
            config.get("apiUrl") +
              "/api/file/" +
              rsSearchUser.data.data.fac_picture.pic_6
          );
        }
      }

      if (rsSearchUser.data.data.emp_education[0]) {
        setEmpEduLv(rsSearchUser.data.data.emp_education[0].education_level);
        if (
          rsSearchUser.data.data.emp_education[0].attachment !== "null" &&
          rsSearchUser.data.data.emp_education[0].attachment !== "" &&
          rsSearchUser.data.data.emp_education[0].attachment !== null
        ) {
          setEmpTranscript(rsSearchUser.data.data.emp_education[0].attachment);
        }
      }
      if (rsSearchUser.data.data.emp_address) {
        setEmpProvince(rsSearchUser.data.data.emp_address.province);
      }
      if (rsSearchUser.data.data.emp_address) {
        setEmpDistrict(rsSearchUser.data.data.emp_address.district);
      }
      if (rsSearchUser.data.data.emp_address) {
        setEmpSubDistrict(rsSearchUser.data.data.emp_address.sub_district);
      }

      if (rsSearchUser.data.data.emp_education[0]) {
        if (
          rsSearchUser.data.data.emp_education[0].graduation_date !== "" &&
          rsSearchUser.data.data.emp_education[0].graduation_date !== null
        ) {
          setEduDate(
            moment(
              new Date(rsSearchUser.data.data.emp_education[0].graduation_date)
            )
              .add(543, "year")
              .format("DD/MM/YYYY")
          );
          $(".graduation_date")
            .datepicker()
            .datepicker(
              "setDate",
              moment(
                new Date(
                  rsSearchUser.data.data.emp_education[0].graduation_date
                )
              ).format("DD/MM/YYYY")
            );
        } else {
          setEduDate(null);
        }
      } else {
        setEduDate(null);
        //$('.graduation_date').datepicker().datepicker('setDate', moment(new Date()).format("DD/MM/YYYY") );
      }

      if (rsSearchUser.data.data.work_bu1 !== null) {
        getListWorkBu(rsSearchUser.data.data.work_bu1, "2");
      }
      if (rsSearchUser.data.data.work_bu2 !== null) {
        getListWorkBu(rsSearchUser.data.data.work_bu1, "2");
        getListWorkBu(rsSearchUser.data.data.work_bu2, "3");
      }
      if (rsSearchUser.data.data.work_bu3 !== null) {
        getListWorkBu(rsSearchUser.data.data.work_bu2, "3");
        getListWorkBu(rsSearchUser.data.data.work_bu3, "4");
      }
      if (rsSearchUser.data.data.work_bu4 !== null) {
        getListWorkBu(rsSearchUser.data.data.work_bu3, "4");
        getListWorkBu(rsSearchUser.data.data.work_bu4, "5");
      }
      if (rsSearchUser.data.data.work_bu5 !== null) {
        getListWorkBu(rsSearchUser.data.data.work_bu4, "5");
        getListWorkBu(rsSearchUser.data.data.work_bu5, "6");
      }
      if (rsSearchUser.data.data.work_bu6 !== null) {
        getListWorkBu(rsSearchUser.data.data.work_bu5, "6");
      }

      if (rsSearchUser.data.data.law_bu1 !== null) {
        getListLawBu(rsSearchUser.data.data.law_bu1, "2");
      }
      if (rsSearchUser.data.data.law_bu2 !== null) {
        getListLawBu(rsSearchUser.data.data.law_bu1, "2");
        getListLawBu(rsSearchUser.data.data.law_bu2, "3");
      }
      if (rsSearchUser.data.data.law_bu3 !== null) {
        getListLawBu(rsSearchUser.data.data.law_bu2, "3");
        getListLawBu(rsSearchUser.data.data.law_bu3, "4");
      }
      if (rsSearchUser.data.data.law_bu4 !== null) {
        getListLawBu(rsSearchUser.data.data.law_bu3, "4");
        getListLawBu(rsSearchUser.data.data.law_bu4, "5");
      }
      if (rsSearchUser.data.data.law_bu5 !== null) {
        getListLawBu(rsSearchUser.data.data.law_bu4, "5");
        getListLawBu(rsSearchUser.data.data.law_bu5, "6");
      }
      if (rsSearchUser.data.data.law_bu6 !== null) {
        getListLawBu(rsSearchUser.data.data.law_bu5, "6");
      }

      if (rsSearchUser.data.data.emp_address) {
        if (rsSearchUser.data.data.emp_address.province !== "") {
          getPostCode(rsSearchUser.data.data.emp_address.province, "province");
        }
      }
      if (rsSearchUser.data.data.emp_address) {
        if (rsSearchUser.data.data.emp_address.district !== "") {
          getPostCode(rsSearchUser.data.data.emp_address.district, "district");
        }
      }

      if (rsSearchUser.data.data.picture !== "") {
        if (rsSearchUser.data.data.picture !== null) {
          setEmpImgView(
            config.get("apiUrl") + "/api/file/" + rsSearchUser.data.data.picture
          );
        }
      }

      if (rsSearchUser.data.data.signature !== "") {
        if (rsSearchUser.data.data.signature !== null) {
          setEmpSignView(
            config.get("apiUrl") +
              "/api/file/" +
              rsSearchUser.data.data.signature
          );
        }
      }

      setSkillLang1(rsSearchUser.data.data.skill_lang_1);
      setSkillLang2(rsSearchUser.data.data.skill_lang_2);
      setSkillLang3(rsSearchUser.data.data.skill_lang_3);
      setSkillLang1LV(rsSearchUser.data.data.skill_lang_1_level);
      setSkillLang2LV(rsSearchUser.data.data.skill_lang_2_level);
      setSkillLang3LV(rsSearchUser.data.data.skill_lang_3_level);
      setFlagSkillcomputer1(rsSearchUser.data.data.skill_computer_1);
      setFlagSkillcomputer2(rsSearchUser.data.data.skill_computer_2);
      setFlagSkillcomputerOther(rsSearchUser.data.data.skill_computer_other);
      setFlagSkillDrive1(rsSearchUser.data.data.skill_drive_1);
      setFlagSkillDrive2(rsSearchUser.data.data.skill_drive_2);
      setFlagSkillDrive3(rsSearchUser.data.data.skill_drive_3);
      setFlagSkillDrive4(rsSearchUser.data.data.skill_drive_4);
      setFlagSkillDrive5(rsSearchUser.data.data.skill_drive_5);
      setFlagSkillToeic(rsSearchUser.data.data.skill_toeic);
      setFlagSkillTofel(rsSearchUser.data.data.skill_tofel);
      setFlagSkillIelts(rsSearchUser.data.data.skill_ielts);
      setFlagSkillCutep(rsSearchUser.data.data.skill_cutep);
      setFlagSkillTutep(rsSearchUser.data.data.skill_tutep);
      if (
        rsSearchUser.data.data.skill_toeic_file !== "null" &&
        rsSearchUser.data.data.skill_toeic_file !== "" &&
        rsSearchUser.data.data.skill_toeic_file !== null
      ) {
        setEmpToeic(rsSearchUser.data.data.skill_toeic_file);
      }
      if (
        rsSearchUser.data.data.skill_tofel_file !== "null" &&
        rsSearchUser.data.data.skill_tofel_file !== "" &&
        rsSearchUser.data.data.skill_tofel_file !== null
      ) {
        setEmpTofel(rsSearchUser.data.data.skill_tofel_file);
      }
      if (
        rsSearchUser.data.data.skill_ielts_file !== "null" &&
        rsSearchUser.data.data.skill_ielts_file !== "" &&
        rsSearchUser.data.data.skill_ielts_file !== null
      ) {
        setEmpIelts(rsSearchUser.data.data.skill_ielts_file);
      }
      if (
        rsSearchUser.data.data.skill_cutep_file !== "null" &&
        rsSearchUser.data.data.skill_cutep_file !== "" &&
        rsSearchUser.data.data.skill_cutep_file !== null
      ) {
        setEmpCutep(rsSearchUser.data.data.skill_cutep_file);
      }
      if (
        rsSearchUser.data.data.skill_tutep_file !== "null" &&
        rsSearchUser.data.data.skill_tutep_file !== "" &&
        rsSearchUser.data.data.skill_tutep_file !== null
      ) {
        setEmpTutep(rsSearchUser.data.data.skill_tutep_file);
      }
      setSkillCom1(rsSearchUser.data.data.skill_com_1);
      setSkillCom1LV(rsSearchUser.data.data.skill_com_1_level);
      setSkillCom2(rsSearchUser.data.data.skill_com_2);
      setSkillCom2LV(rsSearchUser.data.data.skill_com_2_level);
      setSkillComServiceLV(rsSearchUser.data.data.skill_com_service_level);
      setSkillComServerLV(rsSearchUser.data.data.skill_com_server_level);
      setSkillComGraphic(rsSearchUser.data.data.skill_com_graphic);
      setSkillComVedio(rsSearchUser.data.data.skill_com_vedio);
      setSkillComOffice(rsSearchUser.data.data.skill_com_office);
      setSkillComGraphicLV(rsSearchUser.data.data.skill_com_2_level);
      setSkillComVedioLV(rsSearchUser.data.data.skill_com_vedio_level);
      setSkillComOfficeLV(rsSearchUser.data.data.skill_com_office_level);
      setSkillSportLV(rsSearchUser.data.data.skill_sport_level);
      setSkillMusicLV(rsSearchUser.data.data.skill_music_level);
      setSkillHobbyLV(rsSearchUser.data.data.skill_hobby_level);

      setEmpCostId(rsSearchUser.data.data.cost_id);
      getCostCenterList(rsSearchUser.data.data);

      setStatusGoGovId(rsSearchUser.data.data.place_status);
      setStatusGoGovOrg(rsSearchUser.data.data.place_org);
      setStatusGoGovOther(rsSearchUser.data.data.place_other);
    } else {
      if (empSearch !== "") {
        handleShow();
        setTextMsg("ไม่พบข้อมูล");
      }
    }
    hideLoading();
  };

  const getCostCenterList = async (data) => {
    let orgIds = "";
    if (data.work_bu1) {
      orgIds = addOrgList(orgIds, data.work_bu1);
    }
    if (data.work_bu2) {
      orgIds = addOrgList(orgIds, data.work_bu2);
    }
    if (data.work_bu3) {
      orgIds = addOrgList(orgIds, data.work_bu3);
    }
    if (data.work_bu4) {
      orgIds = addOrgList(orgIds, data.work_bu4);
    }
    if (data.work_bu5) {
      orgIds = addOrgList(orgIds, data.work_bu5);
    }
    if (data.work_bu6) {
      orgIds = addOrgList(orgIds, data.work_bu6);
    }
    orgIds = "orgIds=" + orgIds;

    let rsCost = await axios.get(
      config.get("apiUrl") + "/api/master/cost-center?" + orgIds,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setCostCenterList(rsCost.data.data);
  };

  const getPostCode = async (data, t) => {
    if (t === "province") {
      let rs = await axios.get(
        config.get("apiUrl") + "/api/master/district/" + data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      rs.data.data.records.sort((a, b) =>
        a.district_tname > b.district_tname ? 1 : -1
      );
      setDistrict(rs.data.data.records);
    }
    if (t === "district") {
      let rs = await axios.get(
        config.get("apiUrl") + "/api/master/sub-district/" + data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      rs.data.data.records.sort((a, b) =>
        a.sub_district_tname > b.sub_district_tname ? 1 : -1
      );
      setSubDistrict(rs.data.data.records);
    }
  };

  const getListWorkBu = async (data, lv) => {
    let rsWorkbu = await axios.get(
      config.get("apiUrl") + "/api/master/org/1/" + data,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (lv === "2") {
      setWorkbu2(rsWorkbu.data.data.records);
    }
    if (lv === "3") {
      setWorkbu3(rsWorkbu.data.data.records);
    }
    if (lv === "4") {
      setWorkbu4(rsWorkbu.data.data.records);
    }
    if (lv === "5") {
      setWorkbu5(rsWorkbu.data.data.records);
    }
    if (lv === "6") {
      setWorkbu6(rsWorkbu.data.data.records);
    }
  };

  const getListLawBu = async (data, lv) => {
    let rsLawbu = await axios.get(
      config.get("apiUrl") + "/api/master/org/2/" + data,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (lv === "2") {
      setLawbu2(rsLawbu.data.data.records);
    }
    if (lv === "3") {
      setLawbu3(rsLawbu.data.data.records);
    }
    if (lv === "4") {
      setLawbu4(rsLawbu.data.data.records);
    }
    if (lv === "5") {
      setLawbu4(rsLawbu.data.data.records);
    }
    if (lv === "6") {
      setLawbu5(rsLawbu.data.data.records);
    }
  };

  const dataURItoBlob = (dataURI) => {
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  const [empImg, setEmpImg] = useState("");

  const uploadImg = async (data) => {
    setAvatarImgFile(data);
    let formData = new FormData();
    formData.append("file", data);
    let rsImg = await axios.post(config.get("apiUrl") + "/api/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    });
    setEmpImg(rsImg.data.data.file_id);
    viewImage(rsImg.data.data.file_id);
  };

  const [empPic1, setEmpPic1] = useState("");
  const uploadPic1 = async (data, empid, oldImg) => {
    if (empStatusId == "1") {
      $("#overlay").show();
      let params = new FormData();
      params.append("person_id", empid);
      params.append("isMask", false);
      try {
        let formData = new FormData();
        formData.append("img", dataURItoBlob(data));
        formData.append("person_id", empid);
        formData.append("isMask", false);
        let chkQuality = await axios.post(
          config.get("apiFaceReg") + "/main/face/detection",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,
            },
          }
        );
        if (chkQuality.data.error === false) {
          if (parseInt(chkQuality.data.data.quality) > 80) {
            try {
              await axios.post(
                config.get("apiFaceReg") + "/main/face/deleting",
                params,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,
                  },
                }
              );
            } catch (error) {}
            try {
              let chkFaceReg = await axios.post(
                config.get("apiFaceReg") + "/main/face/storing",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,
                  },
                }
              );
              let rsFace48QC = await Face48Api.checkQualityPhotoFace({
                file: data,
                keycloak,
              });
              if (rsFace48QC.data.code === 0) {
                setPhotoImgFile(data);
                let formDataImg = new FormData();
                formDataImg.append("file", dataURItoBlob(data));
                let rsImg = await axios.post(
                  config.get("apiUrl") + "/api/file",
                  formDataImg,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      Authorization: `bearer ${keycloak.token}`,
                    },
                  }
                );
                setEmpPic1(rsImg.data.data.file_id);
                $("#overlay").hide();
              } else {
                setPhotoImgFile(null);
                $("#overlay").hide();
                handleShow();
                setTextMsg(rsFace48QC.data.desc);
              }
              if (chkFaceReg.data.error === false) {
              }
            } catch (error) {
              let msg = error.response.data.message;
              if (msg === "No face detected or more than one face") {
                msg = "ตรวจไม่พบหน้าบนรูป";
              } else {
                msg = error.response.data.message;
              }
              $("#overlay").hide();
              handleShow();
              setTextMsg(msg);
            }
          } else {
            $("#overlay").hide();
            handleShow();
            setTextMsg("รูปของท่านไม่ผ่านเกณฑ์ กรุณาทำรายการใหม่อีกครั้ง");
          }
        }
      } catch (error) {
        let msg = error.response.data.message;
        if (msg === "No face detected or more than one face") {
          msg = "ตรวจไม่พบหน้าบนรูป";
        } else {
          msg = error.response.data.message;
        }
        $("#overlay").hide();
        handleShow();
        setTextMsg(msg);
      }
    } else {
      setTextMsg("ไม่สามารถอัพโหลดรูปได้");
      handleShow();
    }
  };
  const [empPic2, setEmpPic2] = useState("");
  const uploadPic2 = async (data, empid) => {
    if (empStatusId == "1") {
      let params = new FormData();
      params.append("person_id", empid);
      params.append("isMask", true);
      try {
        let formData = new FormData();
        formData.append("img", dataURItoBlob(data));
        formData.append("person_id", empid);
        formData.append("isMask", true);

        let chkQuality = await axios.post(
          config.get("apiFaceReg") + "/main/face/detection",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,
            },
          }
        );
        if (chkQuality.data.error === false) {
          if (parseInt(chkQuality.data.data.quality) > 80) {
            try {
              await axios.post(
                config.get("apiFaceReg") + "/main/face/deleting",
                params,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,
                  },
                }
              );
            } catch (error) {}
            try {
              let formData = new FormData();
              formData.append("img", dataURItoBlob(data));
              formData.append("person_id", empid);
              formData.append("isMask", true);
              let chkFaceReg = await axios.post(
                config.get("apiFaceReg") + "/main/face/storing",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,
                  },
                }
              );
              if (chkFaceReg.data.error === false) {
                let formDataImg = new FormData();
                formDataImg.append("file", dataURItoBlob(data));
                let rsImg = await axios.post(
                  config.get("apiUrl") + "/api/file",
                  formDataImg,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      Authorization: `bearer ${keycloak.token}`,
                    },
                  }
                );
                setEmpPic2(rsImg.data.data.file_id);
              }
            } catch (error) {
              let msg = error.response.data.message;
              if (msg === "No face detected or more than one face") {
                msg = "ตรวจไม่พบหน้าบนรูป";
              } else {
                msg = error.response.data.message;
              }
              handleShow();
              setTextMsg(msg);
            }
          } else {
            handleShow();
            setTextMsg("รูปของท่านไม่ผ่านเกณฑ์ กรุณาทำรายการใหม่อีกครั้ง");
          }
        }
      } catch (error) {
        let msg = error.response.data.message;
        if (msg === "No face detected or more than one face") {
          msg = "ตรวจไม่พบหน้าบนรูป";
        } else {
          msg = error.response.data.message;
        }
        handleShow();
        setTextMsg(msg);
      }
    } else {
      setTextMsg("ไม่สามารถอัพโหลดรูปได้");
      handleShow();
    }
  };
  const [empPic3, setEmpPic3] = useState("");
  const uploadPic3 = async (data) => {
    let formData = new FormData();
    formData.append("file", dataURItoBlob(data));
    let rsImg = await axios.post(config.get("apiUrl") + "/api/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    });
    setEmpPic3(rsImg.data.data.file_id);
  };
  const [empPic4, setEmpPic4] = useState("");
  const uploadPic4 = async (data) => {
    let formData = new FormData();
    formData.append("file", dataURItoBlob(data));
    let rsImg = await axios.post(config.get("apiUrl") + "/api/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    });
    setEmpPic4(rsImg.data.data.file_id);
  };
  const [empPic5, setEmpPic5] = useState("");
  const uploadPic5 = async (data) => {
    let formData = new FormData();
    formData.append("file", dataURItoBlob(data));
    let rsImg = await axios.post(config.get("apiUrl") + "/api/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    });
    setEmpPic5(rsImg.data.data.file_id);
  };
  const [empPic6, setEmpPic6] = useState("");
  const uploadPic6 = async (data) => {
    let formData = new FormData();
    formData.append("file", dataURItoBlob(data));
    let rsImg = await axios.post(config.get("apiUrl") + "/api/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    });
    setEmpPic6(rsImg.data.data.file_id);
  };

  const [empImgView, setEmpImgView] = useState("img/avatar.png");
  const viewImage = (data) => {
    setEmpImgView(config.get("apiUrl") + "/api/file/" + data);
  };
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    uploadImg(fileUploaded);
  };
  const onSubmit = (data) => {
    let msg = "กรุณากรอกข้อมูลให้ครบถ้วน";

    if (data.sex === "") {
      msg = "กรุณาเลือก เพศ";
      $(".home").click();
      $(".sex").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.status_id === "") {
      msg = "กรุณาเลือก สถานะการทำงาน";
      $(".home").click();
      $(".status_id").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.title_id === "") {
      msg = "กรุณาเลือก คำนำหน้าชื่อ";
      $(".home").click();
      $(".title_id").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.fname === "") {
      msg = "กรุณาระบุ ชื่อ";
      $(".home").click();
      $(".fname").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.lname === "") {
      msg = "กรุณาระบุ นามสกุล";
      $(".home").click();
      $(".lname").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.birthday === "") {
      msg = "กรุณาระบุ วันเดือนปีเกิด";
      $(".home").click();
      $(".birthday").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.id_card === "") {
      msg = "กรุณาระบุ เลขที่บัตรประชาชน";
      $(".home").click();
      $(".id_card").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.email === "") {
      msg = "กรุณาระบุ อีเมล";
      $(".home").click();
      $(".email").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.employee_id === "") {
      msg = "กรุณาระบุ Username";
      $(".home").click();
      $(".employee_id").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.role_id === "") {
      msg = "กรุณาเลือก สิทธิ์การเข้าใช้งานระบบ";
      $(".home").click();
      $(".role_id").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.emp_type_id === "") {
      msg = "กรุณาเลือก ประเภทบุคลากร";
      $(".profile").click();
      $(".emp_type_id").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.start_date === "") {
      msg = "กรุณาระบุ วันบรรจุ";
      $(".profile").click();
      $(".start_date").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.work_bu1 === "") {
      msg = "กรุณาเลือก สำนัก/กอง";
      $(".profile").click();
      $(".work_bu1").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.shift_id === "") {
      msg = "กรุณาเลือก ตารางเวลาการทำงาน";
      $(".profile").click();
      $(".shift_id").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (data.holiday_group_id === "") {
      msg = "กรุณาเลือก ประเภทวันหยุด";
      $(".profile").click();
      $(".holiday_group_id").focus();
      handleShow();
      setTextMsg(msg);
      setTimeout(() => {
        handleClose();
      }, 3000);
      return false;
    }

    if (hasSearchUser) {
      updateUser(data);
    } else {
      if (data.password === "") {
        msg = "กรุณาระบุ Password";
        $(".home").click();
        $(".password").focus();
        handleShow();
        setTextMsg(msg);
        setTimeout(() => {
          handleClose();
        }, 3000);
        return false;
      }

      createUser(data);
    }
  };

  const onClickSearch = (e) => {
    // console.log(singleSelections[0])
    if (singleSelections[0]) {
      // console.log('case 1')
      searchUser(singleSelections[0].employee_id, { jobId, position });
      setEmpSearch(singleSelections[0].employee_id);
    } else {
      // console.log('case 2', empSearch)
      searchUser(empSearch, { jobId, position });
    }
  };
  const videoConstraints = {
    width: { min: 480 },
    height: { min: 720 },
    aspectRatio: 0.6666666667,
  };
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const capture = React.useCallback(
    (empid, oldimg) => {
      const imageSrc = webcamRef.current.getScreenshot({
        width: 720,
        height: 1080,
      });
      uploadPic1(imageSrc, empid, oldimg);
      setImgSrc(imageSrc);
      camera1Close();
    },
    [webcamRef, setImgSrc]
  );
  const webcamRef2 = React.useRef(null);
  const [imgSrc2, setImgSrc2] = React.useState(null);
  const capture2 = React.useCallback(
    (empid) => {
      const imageSrc2 = webcamRef2.current.getScreenshot({
        width: 720,
        height: 1080,
      });
      uploadPic2(imageSrc2, empid);
      setImgSrc2(imageSrc2);
      camera2Close();
    },
    [webcamRef2, setImgSrc2]
  );
  const webcamRef3 = React.useRef(null);
  const [imgSrc3, setImgSrc3] = React.useState(null);
  const capture3 = React.useCallback(() => {
    const imageSrc3 = webcamRef3.current.getScreenshot();
    uploadPic3(imageSrc3);
    setImgSrc3(imageSrc3);
    camera3Close();
  }, [webcamRef3, setImgSrc3]);
  const webcamRef4 = React.useRef(null);
  const [imgSrc4, setImgSrc4] = React.useState(null);
  const capture4 = React.useCallback(() => {
    const imageSrc4 = webcamRef4.current.getScreenshot();
    uploadPic4(imageSrc4);
    setImgSrc4(imageSrc4);
    camera4Close();
  }, [webcamRef4, setImgSrc4]);
  const webcamRef5 = React.useRef(null);
  const [imgSrc5, setImgSrc5] = React.useState(null);
  const capture5 = React.useCallback(() => {
    const imageSrc5 = webcamRef5.current.getScreenshot();
    uploadPic5(imageSrc5);
    setImgSrc5(imageSrc5);
    camera5Close();
  }, [webcamRef5, setImgSrc5]);
  const webcamRef6 = React.useRef(null);
  const [imgSrc6, setImgSrc6] = React.useState(null);
  const capture6 = React.useCallback(() => {
    const imageSrc6 = webcamRef6.current.getScreenshot();
    uploadPic6(imageSrc6);
    setImgSrc6(imageSrc6);
    camera6Close();
  }, [webcamRef6, setImgSrc6]);
  const [empUsername, setEmpUsername] = useState("");
  const handleChangeInput = (e) => {
    const re = /^[a-zA-Z0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setEmpUsername(e.target.value);
    }
  };
  const [empPassword, setEmpPassword] = useState("");
  const handleChangeInt = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setEmpPassword(e.target.value);
    }
  };
  const hiddenImgInput = React.useRef(null);
  const hiddenImgInput2 = React.useRef(null);
  const hiddenImgInput3 = React.useRef(null);
  const hiddenImgInput4 = React.useRef(null);
  const hiddenImgInput5 = React.useRef(null);
  const hiddenImgInput6 = React.useRef(null);
  const transcriptInput = React.useRef(null);
  const toeicInput = React.useRef(null);
  const tofelInput = React.useRef(null);
  const ieltsInput = React.useRef(null);
  const cutepInput = React.useRef(null);
  const tutepInput = React.useRef(null);
  const handleUploadImg = (pic, event) => {
    if (pic === "pic1") {
      hiddenImgInput.current.click();
    }
    if (pic === "pic2") {
      hiddenImgInput2.current.click();
    }
    if (pic === "pic3") {
      hiddenImgInput3.current.click();
    }
    if (pic === "pic4") {
      hiddenImgInput4.current.click();
    }
    if (pic === "pic5") {
      hiddenImgInput5.current.click();
    }
    if (pic === "pic6") {
      hiddenImgInput6.current.click();
    }
    if (pic === "transcript") {
      transcriptInput.current.click();
    }
    if (pic === "toeic") {
      toeicInput.current.click();
    }
    if (pic === "tofel") {
      tofelInput.current.click();
    }
    if (pic === "ielts") {
      ieltsInput.current.click();
    }
    if (pic === "cutep") {
      cutepInput.current.click();
    }
    if (pic === "tutep") {
      tutepInput.current.click();
    }
  };
  const handleUploadProcess = (pic, event) => {
    const fileUploaded = event.target.files[0];
    uploadImgTemp(pic, fileUploaded);
  };
  const handleUploadProcessFaceReg = (pic, event) => {
    const fileUploaded = event.target.files[0];
    uploadImgTempFaceReg(pic, fileUploaded);
  };

  const uploadImgTempFaceReg = async (pic, data) => {
    if (pic === "pic1") {
      try {
        console.log("uploadImgTempFaceReg", data);
        let chkQuality = await axios.post(
          config.get("apiFaceReg") + "/main/face/detection",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,
            },
          }
        );
        if (chkQuality.data.error === false) {
          if (parseInt(chkQuality.data.data.quality) > 80) {
            // let rsFace48QC = await Face48Api.checkQualityPhotoFace({
            //   file: data,
            //   keycloak,
            // });
            // console.log("rsFace48QC", rsFace48QC);
            // if (rsFace48QC.data.code === 0) {
            //   setPhotoImgFile(data);

              // let rsImg = await axios.post(
              //   config.get("apiUrl") + "/api/file",
              //   formDataImg,
              //   {
              //     headers: {
              //       "Content-Type": "multipart/form-data",
              //       Authorization: `bearer ${keycloak.token}`,
              //     },
              //   }
              // );
            //   let rsImg = await Face48Api.uploadPhotoEmployee({
            //     peopleId: face48PeopleId,
            //     file: data,
            //     keycloak,
            //   });
            //   let rsImgAv = await Face48Api.uploadAvatarEmployee({
            //     peopleId: face48PeopleId,
            //     file: data,
            //     keycloak,
            //   });
            // }

            // setEmpPic1(rsImg.data.data.file_id);
            // setImgSrc(
            //   config.get("apiUrl") + "/api/file/" + rsImg.data.data.file_id
            // );
          } else {
            setPhotoImgFile(null);
            handleShow();
            setTextMsg("รูปของท่านไม่ผ่านเกณฑ์ กรุณาทำรายการใหม่อีกครั้ง");
          }
        }
      } catch (error) {
        console.log(error);
        handleShow();
        setTextMsg("กรุณาเลือกรูปใหม่อีกครั้ง");
      }

      //   try
      //   {
      //   let formData = new FormData();
      //     formData.append("img", data);
      //     formData.append("person_id", empUsername);
      //     formData.append("isMask", false);
      //   let chkQuality = await axios.post(config.get("apiFaceReg") + "/main/face/detection", formData, {headers: {"Content-Type": "multipart/form-data",Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,},});
      //   if(chkQuality.data.error === false)
      //   {
      //     if(parseInt(chkQuality.data.data.quality)>80)
      //     {
      //       let params = new FormData();
      //         params.append("person_id", empUsername);
      //         params.append("isMask", false);
      //       try {await axios.post(config.get("apiFaceReg") + "/main/face/deleting", params, {headers: {"Content-Type": "multipart/form-data",Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,},});} catch (error) {}
      //       try
      //       {
      //
      //         let chkFaceReg = await axios.post(config.get("apiFaceReg") + "/main/face/storing", formData, {headers: {"Content-Type": "multipart/form-data",Authorization: `Basic YWRtaW5fZGRjOmRkY18yMDIw`,},});
      //         if(chkFaceReg.data.error === false)
      //         {
      //           let formDataImg = new FormData();
      //               formDataImg.append("file", data);
      //           let rsImg = await axios.post(config.get("apiUrl") + "/api/file", formDataImg, {headers: {"Content-Type": "multipart/form-data",Authorization: `bearer ${keycloak.token}`,},});
      //           setEmpPic1(rsImg.data.data.file_id);
      //           setImgSrc(config.get("apiUrl") + "/api/file/" + rsImg.data.data.file_id)
      //         }
      //       }
      //       catch (error)
      //       {
      //         handleShow();
      //         setTextMsg("กรุณาเลือกรูปใหม่อีกครั้ง");
      //       }
      //     }
      //   }
      // }
      // catch (error)
      // {
      //   handleShow();
      //   setTextMsg("กรุณาเลือกรูปใหม่อีกครั้ง");
      // }
    }

    if (pic === "pic2") {
      try {
        let params = new FormData();
        params.append("person_id", empUsername);
        params.append("isMask", true);
        let formData = new FormData();
        formData.append("img", data);
        formData.append("person_id", empUsername);
        formData.append("isMask", true);
        let chkQuality = await axios.post(
          config.get("apiFaceReg") + "/main/face/detection",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `bearer ${keycloak.token}`,
            },
          }
        );
        if (chkQuality.data.error === false) {
          if (parseInt(chkQuality.data.data.quality) > 80) {
            try {
              await axios.post(
                config.get("apiFaceReg") + "/main/face/deleting",
                params,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `bearer ${keycloak.token}`,
                  },
                }
              );
            } catch (error) {}
            try {
              let chkFaceReg = await axios.post(
                config.get("apiFaceReg") + "/main/face/storing",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `bearer ${keycloak.token}`,
                  },
                }
              );
              if (chkFaceReg.data.error === false) {
                let formDataImg = new FormData();
                formDataImg.append("file", data);
                let rsImg = await axios.post(
                  config.get("apiUrl") + "/api/file",
                  formDataImg,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      Authorization: `bearer ${keycloak.token}`,
                    },
                  }
                );
                setEmpPic2(rsImg.data.data.file_id);
                setImgSrc2(
                  config.get("apiUrl") + "/api/file/" + rsImg.data.data.file_id
                );
              }
            } catch (error) {
              handleShow();
              setTextMsg("กรุณาเลือกรูปใหม่อีกครั้ง");
            }
          }
        }
      } catch (error) {
        handleShow();
        setTextMsg("กรุณาเลือกรูปใหม่อีกครั้ง");
      }
    }
  };

  const handleUploadFacePicture = (pic, event) => {
    // set close up at status_id
    if (empStatusId == "1") {
      const fileUploaded = event.target.files[0];
      uploadFacePicture(pic, fileUploaded);
    } else {
      setTextMsg("ไม่สามารถอัพโหลดรูปได้");
      handleShow();
    }

    // handleUploadProcessFaceReg(pic, event);
  };

  const uploadFacePicture = async (pic, data) => {
    if (data) {
      setIsLoading({ ...isLoading, [pic]: true });
      let formData = new FormData();
      formData.append("field", pic);
      formData.append("file", data);
      formData.append("employeeId", empUsername);

      try {
        let rsImg = await axios.post(
          config.get("apiUrl") + "/api/file/face-picture",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `bearer ${keycloak.token}`,
            },
          }
        );
        console.log("rsImg", rsImg.status);
        if (rsImg.status == 200) {
          let fileId = rsImg.data.data.file_id;
          let imgSrc = config.get("apiUrl") + "/api/file/" + fileId;
          if (pic === "pic1") {
            // let rsFace48QC = await Face48Api.checkQualityPhotoFace({
            //   file: data,
            //   keycloak,
            // });
            // console.log("rsFace48QC", rsFace48QC);
            // if (rsFace48QC.data.code === 0) {
            //   setPhotoImgFile(data);

              // let rsImg = await axios.post(
              //   config.get("apiUrl") + "/api/file",
              //   formDataImg,
              //   {
              //     headers: {
              //       "Content-Type": "multipart/form-data",
              //       Authorization: `bearer ${keycloak.token}`,
              //     },
              //   }
              // );
              // let rsImg = await Face48Api.uploadPhotoEmployee({
              //   peopleId: face48PeopleId,
              //   file: data,
              //   keycloak,
              // });
              // let rsImgAv = await Face48Api.uploadAvatarEmployee({
              //   peopleId: face48PeopleId,
              //   file: data,
              //   keycloak,
              // });
            // }
            setEmpPic1(fileId);
            setImgSrc(imgSrc);
          } else if (pic === "pic2") {
            setEmpPic2(fileId);
            setImgSrc2(imgSrc);
          } else if (pic === "pic3") {
            setEmpPic3(fileId);
            setImgSrc3(imgSrc);
          } else if (pic === "pic4") {
            setEmpPic4(fileId);
            setImgSrc4(imgSrc);
          } else if (pic === "pic5") {
            setEmpPic5(fileId);
            setImgSrc5(imgSrc);
          } else if (pic === "pic6") {
            setEmpPic6(fileId);
            setImgSrc6(imgSrc);
          }
        }
      } catch (error) {
        if (error.response) {
          setTextMsg(
            error.response.data.message_th || error.response.data.message
          );
        } else {
          setTextMsg("กรุณาเลือกรูปใหม่อีกครั้ง");
        }
        handleShow();
        setIsLoading({ ...isLoading, [pic]: false });
      }
      //   axios.post(config.get("apiUrl") + "/api/file/face-picture", formData, {
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //         Authorization: `bearer ${keycloak.token}`,
      //       },
      //     })
      //     .then((res) => {
      //       let fileId = res.data.data.file_id;
      //       let imgSrc = config.get("apiUrl") + "/api/file/" + fileId;
      //       if (pic === "pic1") {

      // let rsFace48QC = await Face48Api.checkQualityPhotoFace({
      //               file: data,
      //               keycloak,
      //             });
      //   console.log("rsFace48QC", rsFace48QC);
      //   if (rsFace48QC.data.code === 0) {
      //     setPhotoImgFile(data);

      //     // let rsImg = await axios.post(
      //     //   config.get("apiUrl") + "/api/file",
      //     //   formDataImg,
      //     //   {
      //     //     headers: {
      //     //       "Content-Type": "multipart/form-data",
      //     //       Authorization: `bearer ${keycloak.token}`,
      //     //     },
      //     //   }
      //     // );
      //     let rsImg = await Face48Api.uploadPhotoEmployee({
      //       peopleId: face48PeopleId,
      //       file: data,
      //       keycloak,
      //     });
      //     let rsImgAv = await Face48Api.uploadAvatarEmployee({
      //       peopleId: face48PeopleId,
      //       file: data,
      //       keycloak,
      //     });
      //   }

      //   setEmpPic1(fileId);
      //   setImgSrc(imgSrc);
      // } else if (pic === "pic2") {
      //   setEmpPic2(fileId);
      //   setImgSrc2(imgSrc);
      // } else if (pic === "pic3") {
      //   setEmpPic3(fileId);
      //   setImgSrc3(imgSrc);
      // } else if (pic === "pic4") {
      //   setEmpPic4(fileId);
      //   setImgSrc4(imgSrc);
      // } else if (pic === "pic5") {
      //   setEmpPic5(fileId);
      //   setImgSrc5(imgSrc);
      // } else if (pic === "pic6") {
      //   setEmpPic6(fileId);
      //   setImgSrc6(imgSrc);
      // }
      //     })
      //     .catch((error) => {
      //       if (error.response) {
      //         setTextMsg(
      //           error.response.data.message_th || error.response.data.message
      //         );
      //       } else {
      //         setTextMsg("กรุณาเลือกรูปใหม่อีกครั้ง");
      //       }
      //       handleShow();
      //     })
      //     .finally(() => {
      setIsLoading({ ...isLoading, [pic]: false });
      //     });
    }
  };
  // const capture = () => {
  //   const imageSrc = webcamRef.current.getScreenshot({ width: 720, height: 1080 });
  //   uploadFacePicture("pic1", dataURItoBlob(imageSrc));
  //   camera1Close();
  // }
  // const capture2 = () => {
  //   const imageSrc = webcamRef2.current.getScreenshot({ width: 720, height: 1080 });
  //   uploadFacePicture("pic2", dataURItoBlob(imageSrc));
  //   camera2Close();
  // }
  // const capture3 = () => {
  //   const imageSrc = webcamRef3.current.getScreenshot();
  //   uploadFacePicture("pic3", dataURItoBlob(imageSrc));
  //   camera3Close();
  // }
  // const capture4 = () => {
  //   const imageSrc = webcamRef4.current.getScreenshot();
  //   uploadFacePicture("pic4", dataURItoBlob(imageSrc));
  //   camera4Close();
  // }
  // const capture5 = () => {
  //   const imageSrc = webcamRef5.current.getScreenshot();
  //   uploadFacePicture("pic5", dataURItoBlob(imageSrc));
  //   camera5Close();
  // }
  // const capture6 = () => {
  //   const imageSrc = webcamRef6.current.getScreenshot();
  //   uploadFacePicture("pic6", dataURItoBlob(imageSrc));
  //   camera6Close();
  // }

  const uploadImgTemp = async (pic, data) => {
    let formData = new FormData();
    formData.append("file", data);
    let rsImg = await axios.post(config.get("apiUrl") + "/api/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    });
    if (pic === "pic1") {
      setEmpPic1(rsImg.data.data.file_id);
      setImgSrc(config.get("apiUrl") + "/api/file/" + rsImg.data.data.file_id);
    }

    if (pic === "pic2") {
      setEmpPic2(rsImg.data.data.file_id);
      setImgSrc2(config.get("apiUrl") + "/api/file/" + rsImg.data.data.file_id);
    }

    if (pic === "pic3") {
      setEmpPic3(rsImg.data.data.file_id);
      setImgSrc3(config.get("apiUrl") + "/api/file/" + rsImg.data.data.file_id);
    }

    if (pic === "pic4") {
      setEmpPic4(rsImg.data.data.file_id);
      setImgSrc4(config.get("apiUrl") + "/api/file/" + rsImg.data.data.file_id);
    }

    if (pic === "pic5") {
      setEmpPic5(rsImg.data.data.file_id);
      setImgSrc5(config.get("apiUrl") + "/api/file/" + rsImg.data.data.file_id);
    }

    if (pic === "pic6") {
      setEmpPic6(rsImg.data.data.file_id);
      setImgSrc6(config.get("apiUrl") + "/api/file/" + rsImg.data.data.file_id);
    }

    if (pic === "transcript") {
      setEmpTranscript(rsImg.data.data.file_id);
    }

    if (pic === "toeic") {
      setEmpToeic(rsImg.data.data.file_id);
    }
    if (pic === "tofel") {
      setEmpTofel(rsImg.data.data.file_id);
    }
    if (pic === "ielts") {
      setEmpIelts(rsImg.data.data.file_id);
    }
    if (pic === "cutep") {
      setEmpCutep(rsImg.data.data.file_id);
    }
    if (pic === "tutep") {
      setEmpTutep(rsImg.data.data.file_id);
    }
  };

  const [userAutoList, setUserAutoList] = useState([]);
  const [singleSelections, setSingleSelections] = useState([]);
  const [multiSelections, setMultiSelections] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const empChange = (data) => {
    handleSearch(data.target.value);
  };

  const handleSearch = async (query) => {
    setIsSearchLoading(true);
    let params = {
      page_size: 40,
      current_page: 1,
      criteria: {
        like_employee_id: query,
        like_fname: query,
        like_lname: query,
      },
    };
    setEmpSearch(query);
    let rs = await axios.post(
      config.get("apiUrl") + "/api/employee/search",
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (rs.data.status === "success") {
      setIsSearchLoading(false);
      setUserAutoList(rs.data.data.records);
    }
  };

  const filterBy = () => true;
  const [value, onChange] = useState(new Date());
  const [empSign, setEmpSign] = useState("");
  const [empSignView, setEmpSignView] = useState("img/noimage.png");
  const sigPadRef = useRef({});
  const [signImg, setSignImg] = useState(null);
  const [signShow, setSignShow] = useState(false);
  const signPadShow = () => setSignShow(true);
  const signPadClose = () => setSignShow(false);
  const clearSign = () => {
    sigPadRef.current.clear();
  };
  const trimSign = () => {
    setIsLoading({ ...isLoading, signature: true });
    let formData = new FormData();
    formData.append(
      "file",
      dataURItoBlob(sigPadRef.current.getTrimmedCanvas().toDataURL("image/png"))
    );
    formData.append("employeeId", empUsername);
    axios
      .post(config.get("apiUrl") + "/api/file/signature", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        setEmpSign(res.data.data.file_id);
        setEmpSignView(
          config.get("apiUrl") + "/api/file/" + res.data.data.file_id
        );
        signPadClose();
      })
      .catch((error) => {
        if (error.response) {
          setTextMsg(
            error.response.data.message_th || error.response.data.message
          );
        } else {
          setTextMsg("กรุณาลองอีกครั้ง");
        }
        handleShow();
      })
      .finally(() => {
        setIsLoading({ ...isLoading, signature: false });
      });
  };

  const delFacePicture = async (pic) => {
    setIsLoading({ ...isLoading, [pic + "Del"]: true });
    let formData = new FormData();
    formData.append("field", pic);
    formData.append("employeeId", empUsername);
    // await Face48Api.deletePictureEmployee({
    //   peopleId: face48PeopleId,
    //   keycloak,
    // });
    axios
      .post(config.get("apiUrl") + "/api/file/face-picture/delete", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        let fileId = "";
        let imgSrc = null;
        if (pic === "pic1") {
          setEmpPic1(fileId);
          setImgSrc(imgSrc);
          setPhotoImgFile(null);
          setAvatarImgFile(null);
        } else if (pic === "pic2") {
          setEmpPic2(fileId);
          setImgSrc2(imgSrc);
        } else if (pic === "pic3") {
          setEmpPic3(fileId);
          setImgSrc3(imgSrc);
        } else if (pic === "pic4") {
          setEmpPic4(fileId);
          setImgSrc4(imgSrc);
        } else if (pic === "pic5") {
          setEmpPic5(fileId);
          setImgSrc5(imgSrc);
        } else if (pic === "pic6") {
          setEmpPic6(fileId);
          setImgSrc6(imgSrc);
        }
      })
      .catch((error) => {
        if (error.response) {
          setTextMsg(
            error.response.data.message_th || error.response.data.message
          );
        } else {
          setTextMsg("กรุณาลองอีกครั้ง");
        }
        handleShow();
      })
      .finally(() => {
        setIsLoading({ ...isLoading, [pic + "Del"]: false });
      });
  };

  const delSignature = () => {
    setIsLoading({ ...isLoading, ["signatureDel"]: true });
    let formData = new FormData();
    formData.append("employeeId", empUsername);
    axios
      .post(config.get("apiUrl") + "/api/file/signature/delete", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${keycloak.token}`,
        },
      })
      .then((res) => {
        setEmpSign("");
        setEmpSignView("img/avatar.png");
      })
      .catch((error) => {
        if (error.response) {
          setTextMsg(
            error.response.data.message_th || error.response.data.message
          );
        } else {
          setTextMsg("กรุณาลองอีกครั้ง");
        }
        handleShow();
      })
      .finally(() => {
        setIsLoading({ ...isLoading, ["signatureDel"]: false });
      });
  };

  const backPage = () => {
    dispatch(setUserId(""));
    let path = `/admin-personnel-infomation-datatable`;
    history.push(path);
  };

  const disabledTabsPicture = () => {
    if (!hasSearchUser) {
      return "disabled";
    }
    return "";
  };

  const disabledTabsSign = () => {
    if (!hasSearchUser) {
      return "disabled";
    }
    return "";
  };

  const styleDisplayDeleteUser = () => {
    // 1669900006383 นายชาญวิทย์ อมรสุรินทวงศ์ it admin
    // 3120300121931 น.ส.จันทร์เพ็ญ เอกมอญ it admin
    switch (keycloak.tokenParsed.preferred_username) {
      case "1669900006383":
      case "3120300121931":
      case "nunim":
        return "";
      default:
        return "none";
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            {/* BreadCrumb */}
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />{" "}
                    หน้าหลัก
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">
                    ผู้ดูแลระบบ
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    ข้อมูลบุคลากร
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button
                  onClick={backPage}
                  style={{
                    display: !hasAnyRole(keycloak, ["admin", "admin-dep"])
                      ? "none"
                      : "",
                    color: "white",
                  }}
                  className="btn bg-color mb-2"
                >
                  <i className="fas fa-angle-double-left"></i>
                  &nbsp;&nbsp;ย้อนกลับ
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-md-12 page-header-wrapper">
                        <span className="left-border"></span>
                        <span className="page-header-text">ข้อมูลบุคลากร</span>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="card-body">
                      <div className="tabs-wrapper">
                        <ul
                          className="nav nav-tabs"
                          id="custom-content-below-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link active home"
                              id="custom-content-below-home-tab"
                              data-toggle="pill"
                              href="#custom-content-below-home"
                              role="tab"
                              aria-controls="custom-content-below-home"
                              aria-selected="true"
                              onClick={() => {
                                setBtnSave("");
                                setBtnDel("");
                              }}
                            >
                              ข้อมูลส่วนตัว
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link profile"
                              id="custom-content-below-profile-tab"
                              data-toggle="pill"
                              href="#custom-content-below-profile"
                              role="tab"
                              aria-controls="custom-content-below-profile"
                              aria-selected="false"
                              onClick={() => {
                                setBtnSave("");
                                setBtnDel("none");
                              }}
                            >
                              ข้อมูลการทำงาน
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link eoc"
                              id="custom-content-eoc-tab"
                              data-toggle="pill"
                              href="#custom-content-eoc"
                              role="tab"
                              aria-controls="custom-content-eoc"
                              aria-selected="false"
                              onClick={() => {
                                setBtnSave("");
                                setBtnDel("none");
                              }}
                            >
                              EOC
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="custom-content-below-messages-tab"
                              data-toggle="pill"
                              href="#custom-content-below-messages"
                              role="tab"
                              aria-controls="custom-content-below-messages"
                              aria-selected="false"
                              onClick={() => {
                                setBtnSave("");
                                setBtnDel("none");
                              }}
                            >
                              ข้อมูลเงินเดือน
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="custom-content-below-settings-tab"
                              data-toggle="pill"
                              href="#custom-content-below-settings"
                              role="tab"
                              aria-controls="custom-content-below-settings"
                              aria-selected="false"
                              onClick={() => {
                                setBtnSave("");
                                setBtnDel("none");
                              }}
                            >
                              ข้อมูลการศึกษา
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="custom-content-below-address-tab"
                              data-toggle="pill"
                              href="#custom-content-below-address"
                              role="tab"
                              aria-controls="custom-content-below-address"
                              aria-selected="false"
                              onClick={() => {
                                setBtnSave("");
                                setBtnDel("none");
                              }}
                            >
                              ข้อมูลที่อยู่
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="custom-content-below-ability-tab"
                              data-toggle="pill"
                              href="#custom-content-below-ability"
                              role="tab"
                              aria-controls="custom-content-below-ability"
                              aria-selected="false"
                              onClick={() => {
                                setBtnSave("");
                                setBtnDel("none");
                              }}
                            >
                              ความสามารถ
                            </a>
                          </li>
                          {!hasAnyRole(keycloak, ["user"]) && (
                            <li className="nav-item">
                              <a
                                className={`nav-link ${disabledTabsPicture()}`}
                                id="custom-content-below-image-tab"
                                data-toggle="pill"
                                href="#custom-content-below-image"
                                role="tab"
                                aria-controls="custom-content-below-image"
                                aria-selected="false"
                                onClick={() => {
                                  setBtnSave("none");
                                  setBtnDel("none");
                                }}
                              >
                                รูปภาพ
                              </a>
                            </li>
                          )}
                          <li className="nav-item">
                            <a
                              className={`nav-link ${disabledTabsSign()}`}
                              id="custom-content-below-signature-tab"
                              data-toggle="pill"
                              href="#custom-content-below-signature"
                              role="tab"
                              aria-controls="custom-content-below-signature"
                              aria-selected="false"
                              onClick={() => {
                                setBtnSave("none");
                                setBtnDel("none");
                              }}
                            >
                              ลายเซ็นต์
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="custom-content-expert-settings-tab"
                              data-toggle="pill"
                              href="#custom-content-expert-settings"
                              role="tab"
                              aria-controls="custom-content-expert-settings"
                              aria-selected="false"
                              onClick={() => {
                                setBtnSave("");
                                setBtnDel("none");
                              }}
                            >
                              ความเชี่ยวชาญ
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="custom-content-course-settings-tab"
                              data-toggle="pill"
                              href="#custom-content-course-settings"
                              role="tab"
                              aria-controls="custom-content-course-settings"
                              aria-selected="false"
                              onClick={() => {
                                setBtnSave("");
                                setBtnDel("none");
                              }}
                            >
                              การฝึกอบรม/หลักสูตร
                            </a>
                          </li>
                        </ul>

                        {/* TAB CONTENT */}
                        <div
                          id="custom-content-below-tabContent"
                          className="tab-content"
                        >
                          <div
                            className="tab-pane fade show pl-3 pr-3 active "
                            id="custom-content-below-home"
                            role="tabpanel"
                            aria-labelledby="custom-content-below-home-tab"
                          >
                            <div className="row mt-3">
                              <div className="col-12 col-md-10 pt-3">
                                {/*<div className="form-group row mt-3">*/}
                                <div className="form-group row mt-3">
                                  {/*<label htmlFor="inputEmail3" className="col-sm-2 col-form-label">รหัสพนักงาน</label>*/}

                                  <div className="col-12 col-sm-2 col-md-2 col-lg-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      รหัสพนักงาน
                                    </span>
                                  </div>
                                  <div className="col-8 col-sm-7 col-md-3 col-lg-3 pt-3">
                                    <AsyncTypeahead
                                      isLoading={isSearchLoading}
                                      filterBy={filterBy}
                                      id="basic-typeahead-single"
                                      labelKey="employee_id"
                                      onBlur={empChange}
                                      onChange={setSingleSelections}
                                      options={userAutoList}
                                      // disabled={!hasAnyRole(keycloak,['admin-dep','admin']) || hasSearchUser}
                                      disabled
                                      selected={singleSelections}
                                      minLength={3}
                                      onSearch={handleSearch}
                                      renderMenuItemChildren={(option) => (
                                        <div>
                                          {option.employee_id}{" "}
                                          <small>
                                            ({option.fname} {option.lname})
                                          </small>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  <div className="col-4 col-sm-2 col-md-2 col-lg-2 pt-3">
                                    {!hasSearchUser && false && (
                                      <Button
                                        type="button"
                                        variant="contained"
                                        className="btn-no-border btn-color-search"
                                        onClick={(e) => onClickSearch(e)}
                                      >
                                        <i className="fas fa-search pr-2"></i>
                                        ค้นหา
                                      </Button>
                                    )}
                                  </div>
                                  <div className="col-12 col-sm-2 col-md-1 col-lg-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      เพศ
                                    </span>
                                    <span className="text-danger"> *</span>
                                  </div>
                                  <div className="col-12 col-sm-4 col-md-3 col-lg-2 pt-3">
                                    <select
                                      className="form-control shadow-none sex"
                                      name="sex"
                                      ref={register}
                                      value={empSex}
                                      onChange={(e) =>
                                        setEmpSex(e.target.value)
                                      }
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {gender.map((item) => (
                                        <option
                                          key={item.gender_id}
                                          value={item.gender_id}
                                        >
                                          {item.gender_name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-12 col-sm-2 col-md-2 col-lg-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สถานะ
                                    </span>
                                    <span className="text-danger"> *</span>
                                  </div>
                                  <div className="col-12 col-sm-4 col-md-2 col-lg-2 pt-3">
                                    <select
                                      className="form-control shadow-none status_id"
                                      name="status_id"
                                      ref={register}
                                      value={empStatusId}
                                      onChange={(e) =>
                                        setEmpStatusId(e.target.value)
                                      }
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {wstatus.map((item) => (
                                        <option
                                          key={item.status_id}
                                          value={item.status_id}
                                        >
                                          {item.status_name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  {empStatusId == "2" && (
                                    <>
                                      <div className="col-12 col-md-6 col-lg-9 pt-3"></div>
                                      <div className="col-12 col-sm-2 col-md-2 col-lg-1 pt-3 mt-2">
                                        <span className="text-color-form align-middle">
                                          สาเหตุ
                                        </span>
                                        {/* <span className="text-danger"> *</span> */}
                                      </div>
                                      <div className="col-12 col-sm-4 col-md-2 col-lg-2 pt-3">
                                        <select
                                          className="form-control shadow-none root_cause"
                                          name="root_cause"
                                          ref={register}
                                          value={empRootCause}
                                          onChange={(e) =>
                                            setEmpRootCause(e.target.value)
                                          }
                                        >
                                          <option value="">กรุณาเลือก</option>
                                          <option value={1}>ลาออก</option>
                                          <option value={2}>เกษียณ</option>
                                          <option value={3}>เสียชีวิต</option>
                                          <option value={4}>โอนย้าย</option>
                                        </select>
                                      </div>
                                    </>
                                  )}
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      คำนำหน้า
                                    </span>
                                    <span className="text-danger"> *</span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <select
                                      className="form-control shadow-none title_id"
                                      name="title_id"
                                      ref={register}
                                      value={empTitle}
                                      onChange={(e) =>
                                        setEmptitle(e.target.value)
                                      }
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {title.map((item) => (
                                        <option
                                          key={item.title_id}
                                          value={item.title_id}
                                        >
                                          {item.title_tname}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ชื่อไทย
                                    </span>
                                    <span className="text-danger"> *</span>
                                  </div>
                                  <div className="col-12 col-md-3 pt-3">
                                    <input
                                      className="form-control shadow-none fname"
                                      type="text"
                                      name="fname"
                                      ref={register}
                                      defaultValue={employee.fname}
                                    />
                                  </div>
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สกุล
                                    </span>
                                    <span className="text-danger"> *</span>
                                  </div>
                                  <div className="col-12 col-md-4 pt-3">
                                    <input
                                      className="form-control shadow-none lname"
                                      type="text"
                                      name="lname"
                                      ref={register}
                                      defaultValue={employee.lname}
                                    />
                                  </div>
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      Perfix
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="etitle_id"
                                      ref={register}
                                      value={empTitle}
                                      onChange={(e) =>
                                        setEmptitle(e.target.value)
                                      }
                                    >
                                      <option value="">Please Choose</option>
                                      {title.map((item) => (
                                        <option
                                          key={item.title_id}
                                          value={item.title_id}
                                          defaultValue={
                                            item.title_id === employee.title_id
                                              ? true
                                              : false
                                          }
                                        >
                                          {item.title_ename}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      First Name
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-3 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="efname"
                                      ref={register}
                                      defaultValue={employee.efname}
                                    />
                                  </div>
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      Last Name
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-4 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="elname"
                                      ref={register}
                                      defaultValue={employee.elname}
                                    />
                                  </div>
                                  <div className="col-12 pt-5">
                                    <div className="loading-container d-none">
                                      <img
                                        className=""
                                        src="img/loading.gif"
                                        alt=""
                                      ></img>
                                      <label className="pt-2 text-success">
                                        &nbsp;&nbsp;กรุณารอดาวน์โหลดข้อมูลสักครู่
                                        ...
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* AVATAR PICTURE */}
                              <div className="col-12 col-md-2 pt-3 text-center ">
                                <div className="camera-box">
                                  <div className="section-camera">
                                    <Image
                                      src={empImgView}
                                      style={{
                                        height: "auto",
                                        borderRadius: "5px",
                                      }}
                                      className="img-fluid"
                                    />
                                    <input
                                      type="file"
                                      ref={hiddenFileInput}
                                      onClick={(e) => (e.target.value = null)}
                                      onChange={handleChange}
                                      style={{ display: "none" }}
                                    />
                                  </div>
                                  <Button
                                    type="button"
                                    variant="contained"
                                    size="large"
                                    className="btn btn-deep btn-block font-cschatthai font-13-force mt-2"
                                    onClick={handleClick}
                                  >
                                    <i className="fas fa-upload mr-2"></i>
                                    อัพโหลด
                                  </Button>
                                </div>
                              </div>

                              <div className="col-12 mt-3">
                                <hr style={{ margin: "6px -10px 10px" }} />
                              </div>

                              <div className="col-12">
                                <div className="row mt-3">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      เลขที่บัตรประชาชน
                                    </span>
                                    <span className="text-danger"> *</span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none id_card"
                                      type="text"
                                      name="id_card"
                                      maxLength="13"
                                      ref={register}
                                      defaultValue={employee.id_card}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-1">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      เลขที่หนังสือเดินทาง
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="passport"
                                      ref={register}
                                      defaultValue={employee.passport}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-1">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วัน/เดือน/ปี/เกิด
                                    </span>
                                    <span className="text-danger"> *</span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <div className="form-control-wrapper form-control-icon-right">
                                      <input
                                        type="text"
                                        defaultValue={birthDate}
                                        name="birthday"
                                        ref={register}
                                        data-date-format="dd/MM/yyyy"
                                        className="datepicker birthday form-control shadow-none"
                                      />

                                      <i className="far fa-calendar-alt"></i>
                                    </div>
                                  </div>
                                  <div className="col-3 col-md-1 pt-3 mt-2">
                                    <span
                                      className="text-color-form align-middle"
                                      style={{ display: "none" }}
                                    >
                                      อายุ
                                    </span>
                                  </div>
                                  <div className="col-6 col-md-1 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="age"
                                      ref={register}
                                      style={{ display: "none" }}
                                    />
                                  </div>
                                  <div className="col-3 col-md-1 pt-3 mt-2">
                                    <span
                                      className="text-color-form align-middle"
                                      style={{ display: "none" }}
                                    >
                                      ปี
                                    </span>
                                  </div>
                                </div>
                                <div className="row mt-1">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      เบอร์โทรศัพท์
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="telephone"
                                      ref={register}
                                      defaultValue={employee.telephone}
                                    />
                                  </div>
                                  <div className="col-3 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      มือถือ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="mobile"
                                      ref={register}
                                      defaultValue={employee.mobile}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-1">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      อีเมล์
                                    </span>
                                    <span className="text-danger"> *</span>
                                  </div>
                                  <div className="col-12 col-md-5 pt-3">
                                    <div className="form-control-wrapper form-control-icon-right">
                                      <input
                                        className="form-control shadow-none email"
                                        type="text"
                                        name="email"
                                        ref={register}
                                        defaultValue={employee.email}
                                      />
                                      <i className="fas fa-at"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 mt-3">
                                <hr style={{ margin: "6px -10px 10px" }} />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-2 pt-3 mt-2">
                                <span className="text-color-form align-middle">
                                  เลขที่บัตรบันทึกเวลา 1
                                </span>
                              </div>
                              <div className="col-12 col-md-2 pt-3">
                                <input
                                  className="form-control shadow-none"
                                  type="text"
                                  name="card_no"
                                  ref={register}
                                  defaultValue={employee.card_no}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-2 pt-3 mt-2">
                                <span className="text-color-form align-middle">
                                  Username
                                </span>
                                <span className="text-danger"> *</span>
                              </div>
                              <div className="col-12 col-md-2 pt-3">
                                <input
                                  className={
                                    hasAnyRole(keycloak, ["admin-dep", "admin"])
                                      ? "form-control shadow-none employee_id"
                                      : "form-control shadow-none employee_id rdonly"
                                  }
                                  type="text"
                                  name="employee_id"
                                  value={empUsername}
                                  ref={register}
                                  onChange={(e) => handleChangeInput(e)}
                                  readOnly={
                                    hasAnyRole(keycloak, ["admin-dep", "admin"])
                                      ? ""
                                      : "readonly"
                                  }
                                />
                              </div>
                              <div className="col-12 col-md-2 pt-2 mt-3">
                                <span className="text-color-form align-middle">
                                  สถานะผู้ใช้งาน
                                </span>
                              </div>
                              <div className="col-12 col-md-2 pt-1 mt-2">
                                <select
                                  className={
                                    hasAnyRole(keycloak, ["admin-dep", "admin"])
                                      ? "form-control shadow-none"
                                      : "form-control shadow-none rdonly"
                                  }
                                  name="user_active"
                                  ref={register}
                                  value={empActive}
                                  onChange={(e) => setEmpActive(e.target.value)}
                                  readOnly={
                                    hasAnyRole(keycloak, ["admin-dep", "admin"])
                                      ? ""
                                      : "readonly"
                                  }
                                >
                                  <option value="1">ACTIVE</option>
                                  <option
                                    value="0"
                                    defaultValue={
                                      employee.user_active === 0 ? true : false
                                    }
                                  >
                                    INACTIVE
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-2 pt-3 mt-2">
                                <span className="text-color-form align-middle">
                                  Password
                                </span>
                                <span className="text-danger"> *</span>
                              </div>
                              <div className="col-12 col-md-2 pt-3">
                                <input
                                  className="form-control shadow-none password"
                                  type="password"
                                  name="password"
                                  placeholder={
                                    editSalary === 0 ? "*******" : "********"
                                  }
                                  ref={register}
                                  value={empPassword}
                                  onChange={(e) =>
                                    //handleChangeInt(e)
                                    setEmpPassword(e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-12 col-md-2 pt-2 mt-3">
                                <span className="text-color-form align-middle">
                                  สิทธิ์การเข้าใช้งานระบบ
                                </span>
                                <span className="text-danger"> *</span>
                              </div>
                              <div className="col-12 col-md-2 pt-1 mt-2">
                                <select
                                  className={
                                    hasAnyRole(keycloak, ["admin-dep", "admin"])
                                      ? "form-control shadow-none role_id"
                                      : "form-control shadow-none role_id rdonly"
                                  }
                                  name="role_id"
                                  ref={register}
                                  value={empRole}
                                  onChange={(e) => {
                                    setEmpRole(e.target.value);
                                  }}
                                  readOnly={
                                    hasAnyRole(keycloak, ["admin-dep", "admin"])
                                      ? ""
                                      : "readonly"
                                  }
                                >
                                  <option value="">กรุณาเลือก</option>
                                  {role.map((item) => (
                                    <option
                                      key={item.role_id}
                                      value={item.role_id}
                                    >
                                      {item.role_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-12 col-md-2 pt-1 mt-3">
                                <input
                                  type="checkbox"
                                  name="center"
                                  ref={register}
                                  onClick={(e) =>
                                    flagCenter === 1
                                      ? setFlagCenter(0)
                                      : setFlagCenter(1)
                                  }
                                  defaultChecked={flagCenter === 1}
                                />
                                Center
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade pl-3 pr-3"
                            id="custom-content-below-profile"
                            role="tabpanel"
                            aria-labelledby="custom-content-below-profile-tab"
                          >
                            <div className="row">
                              <div className="col-12 col-md-12 pt-3">
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ประเภทบุคลากร
                                    </span>
                                    <span className="text-danger"> *</span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <select
                                      className="form-control shadow-none emp_type_id"
                                      name="emp_type_id"
                                      ref={register}
                                      value={empTypeId}
                                      onChange={(e) =>
                                        setEmpTypeId(e.target.value)
                                      }
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {empType.map((item) => (
                                        <option
                                          key={item.emp_type_id}
                                          value={item.emp_type_id}
                                        >
                                          {item.emp_type_name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สถานะไปช่วยราชการ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <select
                                      className="form-control shadow-none cost_id"
                                      name="statusGoGov_id"
                                      ref={register}
                                      value={statusGoGovId}
                                      onChange={(e) =>
                                        setStatusGoGovId(e.target.value)
                                      }
                                    >
                                      <option value=""></option>
                                      <option value={1}>ภายในกรม</option>
                                      <option value={2}>ภายนอกกรม</option>
                                    </select>
                                  </div>
                                  {statusGoGovId != "" && (
                                    <Fragment>
                                      <div className="col-12 col-md-1 pt-3 mt-2">
                                        <span className="text-color-form align-middle">
                                          {statusGoGovId == "1"
                                            ? `หน่วยงานที่ไปช่วยไปราชการ`
                                            : `กรุณาระบุ`}
                                        </span>
                                      </div>
                                      <div className="col-12 col-md-3 pt-3">
                                        {statusGoGovId == "1" ? (
                                          <select
                                            className="form-control shadow-none statusGoGov_org"
                                            name="statusGoGov_org"
                                            ref={register}
                                            value={statusGoGovOrg}
                                            onChange={(e) =>
                                              setStatusGoGovOrg(e.target.value)
                                            }
                                          >
                                            <option value="">กรุณาเลือก</option>
                                            {workbu.map((item) => (
                                              <option
                                                key={item.org_id}
                                                value={item.org_id}
                                              >
                                                {item.name}
                                              </option>
                                            ))}
                                          </select>
                                        ) : (
                                          statusGoGovId == "2" && (
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              name="statusGoGov_other"
                                              ref={register}
                                              defaultValue={statusGoGovOther}
                                            />
                                          )
                                        )}
                                      </div>
                                    </Fragment>
                                  )}
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      เลขที่ตำแหน่ง
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="position_number"
                                      defaultValue={employee.position_number}
                                      ref={register}
                                    />
                                  </div>
                                  {/*<div className="col-12 col-md-1 pt-3 mt-2">*/}
                                  {/*  <span className="text-color-form align-middle">*/}
                                  {/*    ตำแหน่ง*/}
                                  {/*  </span>*/}
                                  {/*</div>*/}
                                  {/*<div className="col-12 col-md-2 pt-3">*/}
                                  {/*  <input*/}
                                  {/*    className="form-control shadow-none"*/}
                                  {/*    type="text"*/}
                                  {/*    name="emp_position"*/}
                                  {/*    defaultValue={employee.emp_position}*/}
                                  {/*    ref={register}*/}
                                  {/*  />*/}
                                  {/*</div>*/}
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ตำแหน่ง
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <Select
                                      value={empPosition}
                                      options={position}
                                      onChange={(ValueType) =>
                                        setEmpPosition(ValueType)
                                      }
                                    />
                                  </div>
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สายงาน
                                    </span>
                                  </div>
                                  {/* <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="field_of_work"
                                      defaultValue={employee.field_of_work}
                                      ref={register}
                                    />
                                  </div> */}
                                  <div className="col-12 col-md-2 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="field_of_work"
                                      defaultValue={employee.field_of_work}
                                      ref={register}
                                    >
                                      <option value=""></option>
                                      <option value="1">สายงานหลัก</option>
                                      <option value="2">สายงานสนับสนุน</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ประเภทตำแหน่ง
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="position_type"
                                      defaultValue={employee.position_type}
                                      ref={register}
                                    />
                                  </div>
                                  {/*<div className="col-12 col-md-1 pt-3 mt-2">*/}
                                  {/*  <span className="text-color-form align-middle">*/}
                                  {/*    ระดับตำแหน่ง*/}
                                  {/*  </span>*/}
                                  {/*</div>*/}
                                  {/*<div className="col-12 col-md-2 pt-3">*/}
                                  {/*  <input*/}
                                  {/*    className="form-control shadow-none"*/}
                                  {/*    type="text"*/}
                                  {/*    name="job_id"*/}
                                  {/*    defaultValue={*/}
                                  {/*      employee.job_id*/}
                                  {/*    }*/}
                                  {/*    ref={register}*/}
                                  {/*  />*/}
                                  {/*</div>*/}

                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ระดับตำแหน่ง
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <Select
                                      value={empJob}
                                      options={jobId}
                                      onChange={(ValueType) =>
                                        setEmpJob(ValueType)
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-1">
                              <div className="col-12 col-md-2 pt-3 mt-2">
                                <span className="text-color-form align-middle">
                                  วันบรรจุ
                                </span>
                                <span className="text-danger"> *</span>
                              </div>
                              <div className="col-12 col-md-2 pt-3">
                                <div className="form-control-wrapper form-control-icon-right">
                                  <input
                                    type="text"
                                    defaultValue={startDate}
                                    name="start_date"
                                    ref={register}
                                    data-date-format="dd/MM/yyyy"
                                    className="datepicker start_date form-control shadow-none"
                                  />
                                  <i className="far fa-calendar-alt"></i>
                                </div>
                              </div>
                              <div className="col-12 col-md-1 pt-3 mt-2">
                                <span className="text-color-form align-middle">
                                  อายุงาน
                                </span>
                              </div>
                              <div className="col-12 col-md-2 pt-3 mt-2 text-left">
                                <span className="text-color-form align-middle text-left">
                                  {allWorkDate}
                                </span>
                              </div>
                              <div className="col-12 col-md-1 pt-3 mt-2">
                                <span className="text-color-form align-middle">
                                  วันเกษียณ
                                </span>
                              </div>
                              <div className="col-12 col-md-2 pt-3">
                                <div className="form-control-wrapper form-control-icon-right">
                                  <input
                                    type="text"
                                    defaultValue={resignDate}
                                    name="resign_date"
                                    ref={register}
                                    data-date-format="dd/MM/yyyy"
                                    className="datepicker resign_date form-control shadow-none"
                                  />
                                  <i className="far fa-calendar-alt"></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mt-3">
                              <hr style={{ margin: "6px -10px 10px" }} />
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="row">
                                  <div className="col-12 col-md-12 mt-2">
                                    <span
                                      className="text-color-form align-middle"
                                      style={{ fontWeight: "bolder" }}
                                    >
                                      หน่วยงานตามโครงสร้างการมอบหมายงาน
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-4 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สำนัก/กอง
                                    </span>
                                    <span className="text-danger"> *</span>
                                  </div>
                                  <div className="col-12 col-md-6 pt-3">
                                    <select
                                      className="form-control shadow-none work_bu1"
                                      name="work_bu1"
                                      ref={register}
                                      value={empWorkBu1}
                                      onChange={(e) => onChangeHandler("1", e)}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {workbu.map((item) => (
                                        <option
                                          key={item.org_id}
                                          value={item.org_id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div className="col-12 col-md-4 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ต่ำกว่าสำนัก/กอง 1 ระดับ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-6 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="work_bu2"
                                      ref={register}
                                      onChange={(e) => onChangeHandler("2", e)}
                                      value={empWorkBu2}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {workbu2.map((item) => (
                                        <option
                                          key={item.org_id}
                                          value={item.org_id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div
                                    className="col-12 col-md-6 pt-3"
                                    style={{ display: "none" }}
                                  >
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="workbu_2_text"
                                      ref={register}
                                      defaultValue={employee.workbu_2_text}
                                    />
                                  </div>
                                  <div className="col-12 col-md-4 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ต่ำกว่าสำนัก/กอง 2 ระดับ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-6 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="work_bu3"
                                      ref={register}
                                      onChange={(e) => onChangeHandler("3", e)}
                                      value={empWorkBu3}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {workbu3.map((item) => (
                                        <option
                                          key={item.org_id}
                                          value={item.org_id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div
                                    className="col-12 col-md-6 pt-3"
                                    style={{ display: "none" }}
                                  >
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="workbu_3_text"
                                      ref={register}
                                      defaultValue={employee.workbu_3_text}
                                    />
                                  </div>
                                  <div className="col-12 col-md-4 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ต่ำกว่าสำนัก/กอง 3 ระดับ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-6 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="work_bu4"
                                      ref={register}
                                      onChange={(e) => onChangeHandler("4", e)}
                                      value={empWorkBu4}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {workbu4.map((item) => (
                                        <option
                                          key={item.org_id}
                                          value={item.org_id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div
                                    className="col-12 col-md-6 pt-3"
                                    style={{ display: "none" }}
                                  >
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="workbu_4_text"
                                      ref={register}
                                      defaultValue={employee.workbu_4_text}
                                    />
                                  </div>
                                  <div className="col-12 col-md-4 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ต่ำกว่าสำนัก/กอง 4 ระดับ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-6 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="work_bu5"
                                      ref={register}
                                      onChange={(e) => onChangeHandler("5", e)}
                                      value={empWorkBu5}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {workbu5.map((item) => (
                                        <option
                                          key={item.org_id}
                                          value={item.org_id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div
                                    className="col-12 col-md-6 pt-3"
                                    style={{ display: "none" }}
                                  >
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="workbu_5_text"
                                      ref={register}
                                      defaultValue={employee.workbu_5_text}
                                    />
                                  </div>
                                  <div className="col-12 col-md-4 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ต่ำกว่าสำนัก/กอง 5 ระดับ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-6 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="work_bu6"
                                      ref={register}
                                      onChange={(e) => onChangeHandler("6", e)}
                                      value={empWorkBu6}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {workbu6.map((item) => (
                                        <option
                                          key={item.org_id}
                                          value={item.org_id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div
                                    className="col-12 col-md-6 pt-3"
                                    style={{ display: "none" }}
                                  >
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="workbu_6_text"
                                      ref={register}
                                      defaultValue={employee.workbu_6_text}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="row">
                                  <div className="col-12 col-md-12 mt-2">
                                    <span
                                      className="text-color-form align-middle"
                                      style={{ fontWeight: "bolder" }}
                                    >
                                      หน่วยงานตามโครงสร้างกฎหมาย
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-4 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สำนัก/กอง
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-6 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="law_bu1"
                                      ref={register}
                                      value={empLawBu1}
                                      onChange={(e) =>
                                        onChangeHandlerLaw("1", e)
                                      }
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {lawbu.map((item) => (
                                        <option
                                          key={item.org_id}
                                          value={item.org_id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-12 col-md-4 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ต่ำกว่าสำนัก/กอง 1 ระดับ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-6 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="law_bu2"
                                      ref={register}
                                      onChange={(e) =>
                                        onChangeHandlerLaw("2", e)
                                      }
                                      value={empLawBu2}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {lawbu2.map((item) => (
                                        <option
                                          key={item.org_id}
                                          value={item.org_id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div
                                    className="col-12 col-md-6 pt-3"
                                    style={{ display: "none" }}
                                  >
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="lawbu_2_text"
                                      ref={register}
                                      defaultValue={employee.lawbu_2_text}
                                    />
                                  </div>
                                  <div className="col-12 col-md-4 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ต่ำกว่าสำนัก/กอง 2 ระดับ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-6 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="law_bu3"
                                      ref={register}
                                      onChange={(e) =>
                                        onChangeHandlerLaw("3", e)
                                      }
                                      value={empLawBu3}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {lawbu3.map((item) => (
                                        <option
                                          key={item.org_id}
                                          value={item.org_id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div
                                    className="col-12 col-md-6 pt-3"
                                    style={{ display: "none" }}
                                  >
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="lawbu_3_text"
                                      ref={register}
                                      defaultValue={employee.lawbu_3_text}
                                    />
                                  </div>
                                  <div className="col-12 col-md-4 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ต่ำกว่าสำนัก/กอง 3 ระดับ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-6 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="law_bu4"
                                      ref={register}
                                      onChange={(e) =>
                                        onChangeHandlerLaw("4", e)
                                      }
                                      value={empLawBu4}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {lawbu4.map((item) => (
                                        <option
                                          key={item.org_id}
                                          value={item.org_id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div
                                    className="col-12 col-md-6 pt-3"
                                    style={{ display: "none" }}
                                  >
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="lawbu_4_text"
                                      ref={register}
                                      defaultValue={employee.lawbu_4_text}
                                    />
                                  </div>
                                  <div className="col-12 col-md-4 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ต่ำกว่าสำนัก/กอง 4 ระดับ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-6 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="law_bu5"
                                      ref={register}
                                      onChange={(e) =>
                                        onChangeHandlerLaw("5", e)
                                      }
                                      value={empLawBu5}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {lawbu5.map((item) => (
                                        <option
                                          key={item.org_id}
                                          value={item.org_id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div
                                    className="col-12 col-md-6 pt-3"
                                    style={{ display: "none" }}
                                  >
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="lawbu_5_text"
                                      ref={register}
                                      defaultValue={employee.lawbu_5_text}
                                    />
                                  </div>
                                  <div className="col-12 col-md-4 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ต่ำกว่าสำนัก/กอง 5 ระดับ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-6 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="law_bu6"
                                      ref={register}
                                      onChange={(e) =>
                                        onChangeHandlerLaw("6", e)
                                      }
                                      value={empLawBu6}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {lawbu6.map((item) => (
                                        <option
                                          key={item.org_id}
                                          value={item.org_id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div
                                    className="col-12 col-md-6 pt-3"
                                    style={{ display: "none" }}
                                  >
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="lawbu_6_text"
                                      ref={register}
                                      defaultValue={employee.lawbu_6_text}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mt-3">
                              <hr style={{ margin: "6px -10px 10px" }} />
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-2 pt-3 mt-2">
                                <span className="text-color-form align-middle">
                                  รหัสศูนย์ต้นทุน
                                </span>
                              </div>
                              <div className="col-12 col-md-3 pt-3">
                                <select
                                  className="form-control shadow-none cost_id"
                                  name="cost_id"
                                  ref={register}
                                  value={empCostId}
                                  onChange={(e) => setEmpCostId(e.target.value)}
                                >
                                  <option value="">กรุณาเลือก</option>
                                  {costCenterList.map((item) => (
                                    <option
                                      key={item.costId}
                                      value={item.costId}
                                    >
                                      {item.costNumber}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-12 mt-3">
                              <hr style={{ margin: "6px -10px 10px" }} />
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-2 pt-3 mt-2">
                                <span className="text-color-form align-middle">
                                  ตารางเวลาการทำงาน
                                </span>
                                <span className="text-danger"> *</span>
                              </div>
                              <div className="col-12 col-md-2 pt-3">
                                <select
                                  className="form-control shadow-none shift_id"
                                  name="shift_id"
                                  ref={register}
                                  value={empShipId}
                                  onChange={(e) => setEmpShipId(e.target.value)}
                                >
                                  <option value="">กรุณาเลือก</option>
                                  {shift.map((item) => (
                                    <option
                                      key={item.shift_id}
                                      value={item.shift_id}
                                    >
                                      {item.shift_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-2 pt-3 mt-2">
                                <span className="text-color-form align-middle">
                                  ประเภทวันหยุด
                                </span>
                                <span className="text-danger"> *</span>
                              </div>
                              <div className="col-12 col-md-2 pt-3">
                                <select
                                  className="form-control shadow-none holiday_group_id"
                                  name="holiday_group_id"
                                  ref={register}
                                  value={empHolidayGroupId}
                                  onChange={(e) =>
                                    setEmpHolidayGroupId(e.target.value)
                                  }
                                >
                                  <option value="">กรุณาเลือก</option>
                                  {holidayGroup.map((item) => (
                                    <option
                                      key={item.holiday_group_id}
                                      value={item.holiday_group_id}
                                    >
                                      {item.holiday_group_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <PersonelEOCTab
                            register={register}
                            employee={employee}
                            control={control}
                            onChange={(v) => setEmployeeMission(v)}
                          />
                          <div
                            className="tab-pane fade pl-3 pr-3"
                            id="custom-content-below-messages"
                            role="tabpanel"
                            aria-labelledby="custom-content-below-messages-tab"
                          >
                            <div className="row">
                              <div className="col-12 col-md-12 pt-3">
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      เงินเดือนปัจจุบัน
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="salary"
                                      defaultValue={
                                        employeeSaraly
                                          ? employeeSaraly.salary
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      บาท
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วันที่มีผล
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <div className="form-control-wrapper form-control-icon-right">
                                      <input
                                        type="text"
                                        defaultValue={currentSaralyDate}
                                        name="ceffective_date"
                                        ref={register}
                                        data-date-format="dd/MM/yyyy"
                                        className="datepicker ceffective_date form-control shadow-none"
                                      />
                                      <i className="far fa-calendar-alt"></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      เงินเดือนก่อนหน้า
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="prev_salary"
                                      defaultValue={
                                        employeeSaralyPrev
                                          ? employeeSaralyPrev.salary
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      บาท
                                    </span>
                                  </div>
                                  <div className="d-none col-12 col-md-3 pt-3 mt-2"></div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วันที่มีผล
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <div className="form-control-wrapper form-control-icon-right">
                                      <input
                                        type="text"
                                        defaultValue={prevSaralyDate}
                                        name="peffective_date"
                                        ref={register}
                                        data-date-format="dd/MM/yyyy"
                                        className="datepicker peffective_date form-control shadow-none"
                                      />
                                      <i className="far fa-calendar-alt"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade pl-3 pr-3"
                            id="custom-content-below-settings"
                            role="tabpanel"
                            aria-labelledby="custom-content-below-settings-tab"
                          >
                            <div className="row">
                              <div className="col-12 col-md-12 pt-3">
                                <div className="row">
                                  <div className="col-12 col-md-12 pt-3 text-right">
                                    <Button
                                      type="button"
                                      className="btn-no-border btn-color-search btn btn-contained"
                                      onClick={(e) => setBox2(2)}
                                    >
                                      +
                                    </Button>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ระดับการศึกษา
                                    </span>
                                  </div>

                                  <div className="col-12 col-md-2 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="education_level"
                                      ref={register}
                                      onChange={(e) =>
                                        setEmpEduLv(e.target.value)
                                      }
                                      value={empEduLv}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {edu.map((item) => (
                                        <option
                                          key={item.education_level_id}
                                          value={item.education_level_id}
                                        >
                                          {item.education_level_tname}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วุฒิการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="educational_backgroup"
                                      defaultValue={
                                        employeeEducation
                                          ? employeeEducation.educational_backgroup
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วุฒิย่อ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="abbreviations"
                                      maxLength="30"
                                      defaultValue={
                                        employeeEducation
                                          ? employeeEducation.abbreviations
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สาขาวิชาเอก
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="major"
                                      defaultValue={
                                        employeeEducation
                                          ? employeeEducation.major
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สถาบันการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="institution"
                                      defaultValue={
                                        employeeEducation
                                          ? employeeEducation.institution
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วันที่สำเร็จการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <div className="form-control-wrapper form-control-icon-right">
                                      <div className="form-control-wrapper form-control-icon-right">
                                        <input
                                          type="text"
                                          defaultValue={eduDate}
                                          name="graduation_date"
                                          ref={register}
                                          data-date-format="dd/MM/yyyy"
                                          className="datepicker graduation_date form-control shadow-none"
                                        />
                                        <i className="far fa-calendar-alt"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ปีที่สำเร็จการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="graduation_year"
                                      defaultValue={
                                        employeeEducation
                                          ? employeeEducation.graduation_year
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="row"
                              style={{
                                display: useBox2 == 2 ? "block" : "none",
                              }}
                            >
                              <div className="col-12 col-md-12 pt-3">
                                <div className="row">
                                  <div className="col-12 col-md-12 pt-3 text-right">
                                    <Button
                                      type="button"
                                      className="btn-no-border btn-color-search btn btn-contained"
                                      onClick={(e) => setBox2(1)}
                                    >
                                      -
                                    </Button>{" "}
                                    <Button
                                      type="button"
                                      className="btn-no-border btn-color-search btn btn-contained"
                                      onClick={(e) => setBox3(2)}
                                    >
                                      +
                                    </Button>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ระดับการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="education_level2"
                                      ref={register}
                                      onChange={(e) =>
                                        setEmpEduLv2(e.target.value)
                                      }
                                      value={empEduLv2}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {edu.map((item) => (
                                        <option
                                          key={item.education_level_id}
                                          value={item.education_level_id}
                                        >
                                          {item.education_level_tname}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วุฒิการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="educational_backgroup2"
                                      defaultValue={
                                        employeeEducation2
                                          ? employeeEducation2.educational_backgroup
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วุฒิย่อ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="abbreviations2"
                                      maxLength="30"
                                      defaultValue={
                                        employeeEducation2
                                          ? employeeEducation2.abbreviations
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สาขาวิชาเอก
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="major2"
                                      defaultValue={
                                        employeeEducation2
                                          ? employeeEducation2.major
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สถาบันการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="institution2"
                                      defaultValue={
                                        employeeEducation2
                                          ? employeeEducation2.institution
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วันที่สำเร็จการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <div className="form-control-wrapper form-control-icon-right">
                                      <div className="form-control-wrapper form-control-icon-right">
                                        <input
                                          type="text"
                                          defaultValue={eduDate2}
                                          name="graduation_date2"
                                          ref={register}
                                          data-date-format="dd/MM/yyyy"
                                          className="datepicker graduation_date2 form-control shadow-none"
                                        />{" "}
                                        <i className="far fa-calendar-alt"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ปีที่สำเร็จการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="graduation_year2"
                                      defaultValue={
                                        employeeEducation2
                                          ? employeeEducation2.graduation_year
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="row"
                              style={{
                                display: useBox3 == 2 ? "block" : "none",
                              }}
                            >
                              <div className="col-12 col-md-12 pt-3">
                                <div className="row">
                                  <div className="col-12 col-md-12 pt-3 text-right">
                                    <Button
                                      type="button"
                                      className="btn-no-border btn-color-search btn btn-contained"
                                      onClick={(e) => setBox3(1)}
                                    >
                                      -
                                    </Button>{" "}
                                    <Button
                                      type="button"
                                      className="btn-no-border btn-color-search btn btn-contained"
                                      onClick={(e) => setBox4(2)}
                                    >
                                      +
                                    </Button>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ระดับการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="education_level3"
                                      ref={register}
                                      onChange={(e) =>
                                        setEmpEduLv3(e.target.value)
                                      }
                                      value={empEduLv3}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {edu.map((item) => (
                                        <option
                                          key={item.education_level_id}
                                          value={item.education_level_id}
                                        >
                                          {item.education_level_tname}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วุฒิการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="educational_backgroup3"
                                      defaultValue={
                                        employeeEducation3
                                          ? employeeEducation3.educational_backgroup
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วุฒิย่อ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="abbreviations3"
                                      maxLength="30"
                                      defaultValue={
                                        employeeEducation3
                                          ? employeeEducation3.abbreviations
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สาขาวิชาเอก
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="major3"
                                      defaultValue={
                                        employeeEducation3
                                          ? employeeEducation3.major
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สถาบันการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="institution3"
                                      defaultValue={
                                        employeeEducation3
                                          ? employeeEducation3.institution
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วันที่สำเร็จการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <div className="form-control-wrapper form-control-icon-right">
                                      <div className="form-control-wrapper form-control-icon-right">
                                        <input
                                          type="text"
                                          defaultValue={eduDate3}
                                          name="graduation_date3"
                                          ref={register}
                                          data-date-format="dd/MM/yyyy"
                                          className="datepicker graduation_date3 form-control shadow-none"
                                        />{" "}
                                        <i className="far fa-calendar-alt"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ปีที่สำเร็จการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="graduation_year3"
                                      defaultValue={
                                        employeeEducation3
                                          ? employeeEducation3.graduation_year
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="row"
                              style={{
                                display: useBox4 == 2 ? "block" : "none",
                              }}
                            >
                              <div className="col-12 col-md-12 pt-3">
                                <div className="row">
                                  <div className="col-12 col-md-12 pt-3 text-right">
                                    <Button
                                      type="button"
                                      className="btn-no-border btn-color-search btn btn-contained"
                                      onClick={(e) => setBox4(1)}
                                    >
                                      -
                                    </Button>{" "}
                                    <Button
                                      type="button"
                                      className="btn-no-border btn-color-search btn btn-contained"
                                      onClick={(e) => setBox5(2)}
                                    >
                                      +
                                    </Button>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ระดับการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="education_level4"
                                      ref={register}
                                      onChange={(e) =>
                                        setEmpEduLv4(e.target.value)
                                      }
                                      value={empEduLv4}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {edu.map((item) => (
                                        <option
                                          key={item.education_level_id}
                                          value={item.education_level_id}
                                        >
                                          {item.education_level_tname}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วุฒิการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="educational_backgroup4"
                                      defaultValue={
                                        employeeEducation4
                                          ? employeeEducation4.educational_backgroup
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วุฒิย่อ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="abbreviations4"
                                      maxLength="30"
                                      defaultValue={
                                        employeeEducation4
                                          ? employeeEducation4.abbreviations
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สาขาวิชาเอก
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="major4"
                                      defaultValue={
                                        employeeEducation4
                                          ? employeeEducation4.major
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สถาบันการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="institution4"
                                      defaultValue={
                                        employeeEducation4
                                          ? employeeEducation4.institution
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วันที่สำเร็จการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <div className="form-control-wrapper form-control-icon-right">
                                      <div className="form-control-wrapper form-control-icon-right">
                                        <input
                                          type="text"
                                          defaultValue={eduDate4}
                                          name="graduation_date4"
                                          ref={register}
                                          data-date-format="dd/MM/yyyy"
                                          className="datepicker graduation_date4 form-control shadow-none"
                                        />{" "}
                                        <i className="far fa-calendar-alt"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ปีที่สำเร็จการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="graduation_year4"
                                      defaultValue={
                                        employeeEducation4
                                          ? employeeEducation4.graduation_year
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="row"
                              style={{
                                display: useBox5 == 2 ? "block" : "none",
                              }}
                            >
                              <div className="col-12 col-md-12 pt-3">
                                <div className="row">
                                  <div className="col-12 col-md-12 pt-3 text-right">
                                    <Button
                                      type="button"
                                      className="btn-no-border btn-color-search btn btn-contained"
                                      onClick={(e) => setBox5(1)}
                                    >
                                      -
                                    </Button>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ระดับการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="education_level5"
                                      ref={register}
                                      onChange={(e) =>
                                        setEmpEduLv4(e.target.value)
                                      }
                                      value={empEduLv4}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {edu.map((item) => (
                                        <option
                                          key={item.education_level_id}
                                          value={item.education_level_id}
                                        >
                                          {item.education_level_tname}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วุฒิการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="educational_backgroup5"
                                      defaultValue={
                                        employeeEducation5
                                          ? employeeEducation5.educational_backgroup
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วุฒิย่อ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="abbreviations5"
                                      maxLength="30"
                                      defaultValue={
                                        employeeEducation5
                                          ? employeeEducation5.abbreviations
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สาขาวิชาเอก
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="major5"
                                      defaultValue={
                                        employeeEducation5
                                          ? employeeEducation5.major
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      สถาบันการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="institution5"
                                      defaultValue={
                                        employeeEducation5
                                          ? employeeEducation5.institution
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      วันที่สำเร็จการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <div className="form-control-wrapper form-control-icon-right">
                                      <div className="form-control-wrapper form-control-icon-right">
                                        <input
                                          type="text"
                                          defaultValue={setEduDate5}
                                          name="graduation_date5"
                                          ref={register}
                                          data-date-format="dd/MM/yyyy"
                                          className="datepicker graduation_date5 form-control shadow-none"
                                        />{" "}
                                        <i className="far fa-calendar-alt"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ปีที่สำเร็จการศึกษา
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="graduation_year5"
                                      defaultValue={
                                        employeeEducation5
                                          ? employeeEducation5.graduation_year
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade  pl-3 pr-3"
                            id="custom-content-below-address"
                            role="tabpanel"
                            aria-labelledby="custom-content-below-address-tab"
                          >
                            <div className="row">
                              <div className="col-12 col-md-12 pt-3">
                                <div className="row">
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      เลขที่
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="address_no"
                                      defaultValue={
                                        employeeAddress
                                          ? employeeAddress.address_no
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      หมู่บ้าน
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="village"
                                      defaultValue={
                                        employeeAddress
                                          ? employeeAddress.village
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      หมู่ที่
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="moo"
                                      defaultValue={
                                        employeeAddress
                                          ? employeeAddress.moo
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ถนน
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="road"
                                      defaultValue={
                                        employeeAddress
                                          ? employeeAddress.road
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      จังหวัด
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="province"
                                      ref={register}
                                      onChange={(e) =>
                                        onChangePostCode("province", e)
                                      }
                                      value={empProvince}
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {province.map((item) => (
                                        <option
                                          key={item.province_id}
                                          value={item.province_id}
                                        >
                                          {item.province_tname}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      อำเภอ
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="district"
                                      ref={register}
                                      value={empDistrict}
                                      onChange={(e) =>
                                        onChangePostCode("district", e)
                                      }
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {district.map((item) => (
                                        <option
                                          key={item.district_id}
                                          value={item.district_id}
                                        >
                                          {item.district_tname}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      ตำบล
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <select
                                      className="form-control shadow-none"
                                      name="sub_district"
                                      ref={register}
                                      value={empSubDistrict}
                                      onChange={(e) =>
                                        setEmpSubDistrict(e.target.value)
                                      }
                                    >
                                      <option value="">กรุณาเลือก</option>
                                      {subDistrict.map((item) => (
                                        <option
                                          key={item.sub_district_id}
                                          value={item.sub_district_id}
                                        >
                                          {item.sub_district_tname}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-12 col-md-1 pt-3 mt-2">
                                    <span className="text-color-form align-middle">
                                      รหัสไปรษณีย์
                                    </span>
                                  </div>
                                  <div className="col-12 col-md-2 pt-3">
                                    <input
                                      className="form-control shadow-none"
                                      type="text"
                                      name="postcode"
                                      defaultValue={
                                        employeeAddress
                                          ? employeeAddress.postcode
                                          : ""
                                      }
                                      ref={register}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade  pl-3 pr-3"
                            id="custom-content-below-image"
                            role="tabpanel"
                            aria-labelledby="custom-content-below-image-tab"
                          >
                            <div className="row mt-3">
                              <div className="col-12 col-md-12 pt-3">
                                <fieldset className="border p-2">
                                  <legend className="w-auto text-header">
                                    รูปสำหรับระบบบันทึกเวลา
                                  </legend>
                                  <div className="row">
                                    <div className="col-6 col-sm-5 col-md-4 pt-3 text-center ">
                                      <span className="text-color-form align-middle">
                                        หน้าตรง (ไม่สวมหน้ากากอนามัย)
                                      </span>
                                      <br />
                                      {!imgSrc && (
                                        <>
                                          <div className="d-flex justify-content-center mt-2">
                                            <div className="camera-box">
                                              <Image
                                                src={imgPreview}
                                                style={{ width: "200px" }}
                                              />
                                            </div>
                                          </div>
                                          <div className="d-block mt-3">
                                            <Button
                                              type="button"
                                              variant="contained"
                                              size="large"
                                              className="btn btn-deep btn-sm font-cschatthai"
                                              style={{
                                                width: "auto",
                                                marginRight: 10,
                                              }}
                                              onClick={camera1Show}
                                              disabled={isLoading.pic1}
                                            >
                                              <i className="fas fa-camera mr-2"></i>
                                              ถ่ายรูป
                                            </Button>
                                            <input
                                              type="file"
                                              ref={hiddenImgInput}
                                              onClick={(e) =>
                                                (e.target.value = null)
                                              }
                                              onChange={(e) =>
                                                handleUploadFacePicture(
                                                  "pic1",
                                                  e
                                                )
                                              }
                                              style={{ display: "none" }}
                                            />
                                            <Button
                                              type="button"
                                              variant="contained"
                                              size="large"
                                              className="btn btn-secondary btn-sm font-cschatthai"
                                              onClick={(e) =>
                                                handleUploadImg("pic1", e)
                                              }
                                              style={{ width: "auto" }}
                                              disabled={isLoading.pic1}
                                            >
                                              <i className="fas fa-upload mr-2"></i>
                                              อัพโหลด
                                              {isLoading.pic1 && (
                                                <div
                                                  className="spinner-border spinner-border-sm ml-2"
                                                  role="status"
                                                >
                                                  <span className="sr-only">
                                                    Loading...
                                                  </span>
                                                </div>
                                              )}
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                      {imgSrc && (
                                        <Image
                                          src={imgSrc}
                                          style={{
                                            width: "200px",
                                            paddingBottom: 10,
                                          }}
                                        />
                                      )}
                                      <br />
                                      {imgSrc && (
                                        <Button
                                          type="button"
                                          variant="contained"
                                          className="btn-no-border btn-color-delete"
                                          onClick={(e) =>
                                            delFacePicture("pic1")
                                          }
                                          disabled={isLoading.pic1Del}
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                          {isLoading.pic1Del && (
                                            <div
                                              className="spinner-border spinner-border-sm ml-2"
                                              role="status"
                                            >
                                              <span className="sr-only">
                                                Loading...
                                              </span>
                                            </div>
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                    <div className="col-6 col-sm-5 col-md-4 pt-3 text-center ">
                                      <span className="text-color-form align-middle">
                                        หน้าตรง (สวมหน้ากากอนามัย)
                                      </span>
                                      <br />
                                      {!imgSrc2 && (
                                        <>
                                          <div className="d-flex justify-content-center mt-2">
                                            <div className="camera-box">
                                              <Image
                                                src={maskPreview}
                                                style={{ width: "200px" }}
                                              />
                                            </div>
                                          </div>
                                          <div className="d-block mt-3">
                                            <Button
                                              type="button"
                                              variant="contained"
                                              size="large"
                                              className="btn btn-deep btn-sm font-cschatthai"
                                              style={{
                                                width: "auto",
                                                marginRight: 10,
                                              }}
                                              onClick={camera2Show}
                                              disabled={isLoading.pic2}
                                            >
                                              <i className="fas fa-camera mr-2"></i>
                                              ถ่ายรูป
                                            </Button>
                                            <input
                                              type="file"
                                              ref={hiddenImgInput2}
                                              onClick={(e) =>
                                                (e.target.value = null)
                                              }
                                              onChange={(e) =>
                                                handleUploadFacePicture(
                                                  "pic2",
                                                  e
                                                )
                                              }
                                              style={{ display: "none" }}
                                            />
                                            <Button
                                              type="button"
                                              variant="contained"
                                              size="large"
                                              className="btn btn-secondary btn-sm font-cschatthai"
                                              onClick={(e) =>
                                                handleUploadImg("pic2", e)
                                              }
                                              style={{ width: "auto" }}
                                              disabled={isLoading.pic2}
                                            >
                                              <i className="fas fa-upload mr-2"></i>
                                              อัพโหลด
                                              {isLoading.pic2 && (
                                                <div
                                                  className="spinner-border spinner-border-sm ml-2"
                                                  role="status"
                                                >
                                                  <span className="sr-only">
                                                    Loading...
                                                  </span>
                                                </div>
                                              )}
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                      {imgSrc2 && (
                                        <Image
                                          src={imgSrc2}
                                          style={{
                                            width: "200px",
                                            paddingBottom: 10,
                                          }}
                                        />
                                      )}
                                      <br />
                                      {imgSrc2 && (
                                        <Button
                                          type="button"
                                          variant="contained"
                                          className="btn-no-border btn-color-delete"
                                          onClick={(e) =>
                                            delFacePicture("pic2")
                                          }
                                          disabled={isLoading.pic2Del}
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                          {isLoading.pic2Del && (
                                            <div
                                              className="spinner-border spinner-border-sm ml-2"
                                              role="status"
                                            >
                                              <span className="sr-only">
                                                Loading...
                                              </span>
                                            </div>
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                    <div
                                      className="col-12 col-md-2 pt-3 text-center "
                                      style={{ display: "none" }}
                                    >
                                      {!imgSrc3 && (
                                        <>
                                          <div className="d-flex justify-content-center mt-2">
                                            <div className="camera-box">
                                              <Image
                                                src={imgPreview}
                                                style={{ width: "200px" }}
                                              />
                                            </div>
                                          </div>
                                          <div className="d-block mt-3">
                                            <Button
                                              type="button"
                                              variant="contained"
                                              size="large"
                                              className="btn btn-deep btn-sm font-cschatthai"
                                              style={{
                                                width: "auto",
                                                marginRight: 10,
                                              }}
                                              onClick={camera3Show}
                                              disabled={isLoading.pic3}
                                            >
                                              <i className="fas fa-camera mr-2"></i>
                                              ถ่ายรูป
                                            </Button>
                                            <input
                                              type="file"
                                              ref={hiddenImgInput3}
                                              onClick={(e) =>
                                                (e.target.value = null)
                                              }
                                              onChange={(e) =>
                                                handleUploadFacePicture(
                                                  "pic3",
                                                  e
                                                )
                                              }
                                              style={{ display: "none" }}
                                            />
                                            <Button
                                              type="button"
                                              variant="contained"
                                              size="large"
                                              className="btn btn-secondary btn-sm font-cschatthai"
                                              onClick={(e) =>
                                                handleUploadImg("pic3", e)
                                              }
                                              style={{ width: "auto" }}
                                              disabled={isLoading.pic3}
                                            >
                                              <i className="fas fa-upload mr-2"></i>
                                              อัพโหลด
                                              {isLoading.pic3 && (
                                                <div
                                                  className="spinner-border spinner-border-sm ml-2"
                                                  role="status"
                                                >
                                                  <span className="sr-only">
                                                    Loading...
                                                  </span>
                                                </div>
                                              )}
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                      {imgSrc3 && (
                                        <Image
                                          src={imgSrc3}
                                          style={{
                                            width: "200px",
                                            paddingBottom: 10,
                                          }}
                                        />
                                      )}
                                      <br />
                                      {imgSrc3 && (
                                        <Button
                                          type="button"
                                          variant="contained"
                                          className="btn-no-border btn-color-delete"
                                          onClick={(e) =>
                                            delFacePicture("pic3")
                                          }
                                          disabled={isLoading.pic3Del}
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                          {isLoading.pic3Del && (
                                            <div
                                              className="spinner-border spinner-border-sm ml-2"
                                              role="status"
                                            >
                                              <span className="sr-only">
                                                Loading...
                                              </span>
                                            </div>
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                    <div
                                      className="col-12 col-md-2 pt-3 text-center "
                                      style={{ display: "none" }}
                                    >
                                      {!imgSrc4 && (
                                        <>
                                          <Image
                                            src={imgPreview}
                                            style={{ width: "200px" }}
                                          />
                                          <Button
                                            type="button"
                                            variant="contained"
                                            size="large"
                                            className="btn-no-border btn-color-submit-small"
                                            style={{
                                              width: "90px",
                                              marginRight: 20,
                                            }}
                                            onClick={camera4Show}
                                            disabled={isLoading.pic4}
                                          >
                                            <i className="fas fa-camera-retro"></i>
                                          </Button>
                                          <input
                                            type="file"
                                            ref={hiddenImgInput4}
                                            onClick={(e) =>
                                              (e.target.value = null)
                                            }
                                            onChange={(e) =>
                                              handleUploadFacePicture("pic4", e)
                                            }
                                            style={{ display: "none" }}
                                          />
                                          <Button
                                            type="button"
                                            variant="contained"
                                            size="large"
                                            className="btn-no-border btn-color-search"
                                            onClick={(e) =>
                                              handleUploadImg("pic4", e)
                                            }
                                            style={{ width: "auto" }}
                                            disabled={isLoading.pic4}
                                          >
                                            <i className="fas fa-paperclip"></i>
                                            {isLoading.pic4 && (
                                              <div
                                                className="spinner-border spinner-border-sm ml-2"
                                                role="status"
                                              >
                                                <span className="sr-only">
                                                  Loading...
                                                </span>
                                              </div>
                                            )}
                                          </Button>
                                        </>
                                      )}
                                      {imgSrc4 && (
                                        <Image
                                          src={imgSrc4}
                                          style={{
                                            width: "200px",
                                            paddingBottom: 10,
                                          }}
                                        />
                                      )}
                                      <br />
                                      {imgSrc4 && (
                                        <Button
                                          type="button"
                                          variant="contained"
                                          className="btn-no-border btn-color-delete"
                                          onClick={(e) =>
                                            delFacePicture("pic4")
                                          }
                                          disabled={isLoading.pic4Del}
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                          {isLoading.pic4Del && (
                                            <div
                                              className="spinner-border spinner-border-sm ml-2"
                                              role="status"
                                            >
                                              <span className="sr-only">
                                                Loading...
                                              </span>
                                            </div>
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-12 pt-3">
                                <fieldset className="border p-2">
                                  <legend className="w-auto text-header">
                                    รูปชุดปกติขาว
                                  </legend>
                                  <div className="row">
                                    <div className="col-6 col-sm-5 col-md-4 pt-3 text-center ">
                                      {!imgSrc5 && (
                                        <>
                                          <div className="d-flex justify-content-center mt-2">
                                            <div className="camera-box">
                                              <Image
                                                src={imgPreview}
                                                style={{ width: "200px" }}
                                              />
                                            </div>
                                          </div>
                                          <div className="d-block mt-3">
                                            <Button
                                              type="button"
                                              variant="contained"
                                              size="large"
                                              className="btn btn-deep btn-sm font-cschatthai"
                                              style={{
                                                width: "auto",
                                                marginRight: 10,
                                              }}
                                              onClick={camera5Show}
                                              disabled={isLoading.pic5}
                                            >
                                              <i className="fas fa-camera mr-2"></i>
                                              ถ่ายรูป
                                            </Button>
                                            <input
                                              type="file"
                                              ref={hiddenImgInput5}
                                              onClick={(e) =>
                                                (e.target.value = null)
                                              }
                                              onChange={(e) =>
                                                handleUploadFacePicture(
                                                  "pic5",
                                                  e
                                                )
                                              }
                                              style={{ display: "none" }}
                                            />
                                            <Button
                                              type="button"
                                              variant="contained"
                                              size="large"
                                              className="btn btn-secondary btn-sm font-cschatthai"
                                              onClick={(e) =>
                                                handleUploadImg("pic5", e)
                                              }
                                              style={{ width: "auto" }}
                                              disabled={isLoading.pic5}
                                            >
                                              <i className="fas fa-upload mr-2"></i>
                                              อัพโหลด
                                              {isLoading.pic5 && (
                                                <div
                                                  className="spinner-border spinner-border-sm ml-2"
                                                  role="status"
                                                >
                                                  <span className="sr-only">
                                                    Loading...
                                                  </span>
                                                </div>
                                              )}
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                      {imgSrc5 && (
                                        <Image
                                          src={imgSrc5}
                                          style={{
                                            width: "200px",
                                            paddingBottom: 10,
                                          }}
                                        />
                                      )}
                                      <br />
                                      {imgSrc5 && (
                                        <Button
                                          type="button"
                                          variant="contained"
                                          className="btn-no-border btn-color-delete"
                                          onClick={(e) =>
                                            delFacePicture("pic5")
                                          }
                                          disabled={isLoading.pic5Del}
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                          {isLoading.pic5Del && (
                                            <div
                                              className="spinner-border spinner-border-sm ml-2"
                                              role="status"
                                            >
                                              <span className="sr-only">
                                                Loading...
                                              </span>
                                            </div>
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-12 pt-3">
                                <fieldset className="border p-2">
                                  <legend className="w-auto text-header">
                                    รูปติดบัตร
                                  </legend>
                                  <div className="row">
                                    <div className="col-6 col-sm-5 col-md-4 pt-3 text-center ">
                                      {!imgSrc6 && (
                                        <>
                                          <div className="d-flex justify-content-center mt-2">
                                            <div className="camera-box">
                                              <Image
                                                src={imgPreview}
                                                style={{ width: "200px" }}
                                              />
                                            </div>
                                          </div>
                                          <div className="d-block mt-3">
                                            <Button
                                              type="button"
                                              variant="contained"
                                              size="large"
                                              className="btn btn-deep btn-sm font-cschatthai"
                                              style={{
                                                width: "auto",
                                                marginRight: 10,
                                              }}
                                              onClick={camera6Show}
                                              disabled={isLoading.pic6}
                                            >
                                              <i className="fas fa-camera mr-2"></i>
                                              ถ่ายรูป
                                            </Button>
                                            <input
                                              type="file"
                                              ref={hiddenImgInput6}
                                              onClick={(e) =>
                                                (e.target.value = null)
                                              }
                                              onChange={(e) =>
                                                handleUploadFacePicture(
                                                  "pic6",
                                                  e
                                                )
                                              }
                                              style={{ display: "none" }}
                                            />
                                            <Button
                                              type="button"
                                              variant="contained"
                                              size="large"
                                              className="btn btn-secondary btn-sm font-cschatthai"
                                              onClick={(e) =>
                                                handleUploadImg("pic6", e)
                                              }
                                              style={{ width: "auto" }}
                                              disabled={isLoading.pic6}
                                            >
                                              <i className="fas fa-upload mr-2"></i>
                                              อัพโหลด
                                              {isLoading.pic6 && (
                                                <div
                                                  className="spinner-border spinner-border-sm ml-2"
                                                  role="status"
                                                >
                                                  <span className="sr-only">
                                                    Loading...
                                                  </span>
                                                </div>
                                              )}
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                      {imgSrc6 && (
                                        <Image
                                          src={imgSrc6}
                                          style={{
                                            width: "200px",
                                            paddingBottom: 10,
                                          }}
                                        />
                                      )}
                                      <br />
                                      {imgSrc6 && (
                                        <Button
                                          type="button"
                                          variant="contained"
                                          className="btn-no-border btn-color-delete"
                                          onClick={(e) =>
                                            delFacePicture("pic6")
                                          }
                                          disabled={isLoading.pic6Del}
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                          {isLoading.pic6Del && (
                                            <div
                                              className="spinner-border spinner-border-sm ml-2"
                                              role="status"
                                            >
                                              <span className="sr-only">
                                                Loading...
                                              </span>
                                            </div>
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade  pl-3 pr-3"
                            id="custom-content-below-signature"
                            role="tabpanel"
                            aria-labelledby="custom-content-below-signature-tab"
                          >
                            <div className="row mt-3">
                              <div className="col-12 col-md-12 pt-3">
                                <fieldset className="border p-2">
                                  <legend className="w-auto text-header">
                                    ลายเซ็นต์
                                  </legend>
                                  <div className="row">
                                    <div className="col-6 col-sm-5 col-md-4 pt-3 text-center ">
                                      {!empSign && (
                                        <>
                                          <div className="d-flex justify-content-center mt-2">
                                            <div className="camera-box">
                                              <Image
                                                src={signPreview}
                                                style={{ width: "200px" }}
                                              />
                                            </div>
                                          </div>
                                          <div className="d-block mt-3">
                                            <Button
                                              type="button"
                                              variant="contained"
                                              size="large"
                                              className="btn btn-deep btn-sm font-cschatthai"
                                              style={{
                                                width: "auto",
                                                marginRight: 10,
                                              }}
                                              onClick={signPadShow}
                                              disabled={isLoading.signature}
                                            >
                                              <i className="fas fa-signature mr-2"></i>
                                              ลายเซ็นต์
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                      {empSign && (
                                        <Image
                                          src={empSignView}
                                          style={{
                                            width: "200px",
                                            paddingBottom: 10,
                                          }}
                                        />
                                      )}
                                      <br />
                                      <br />
                                      {empSign && (
                                        <Button
                                          type="button"
                                          variant="contained"
                                          className="btn-no-border btn-color-delete"
                                          onClick={(e) => delSignature()}
                                          disabled={isLoading.signatureDel}
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                          {isLoading.signatureDel && (
                                            <div
                                              className="spinner-border spinner-border-sm ml-2"
                                              role="status"
                                            >
                                              <span className="sr-only">
                                                Loading...
                                              </span>
                                            </div>
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade  pl-3 pr-3"
                            id="custom-content-below-ability"
                            role="tabpanel"
                            aria-labelledby="custom-content-below-ability-tab"
                          >
                            <div className="row">
                              <div className="col-12 col-md-12 pt-3">
                                <fieldset className="border p-2">
                                  <legend className="w-auto text-header">
                                    ความสามารถทางภาษา
                                  </legend>
                                  <div className="row">
                                    <div className="col-12 col-md-1 pt-3 mt-2">
                                      <span className="text-color-form align-left">
                                        ภาษาที่ 1 :
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-2 pt-3">
                                      <select
                                        className="form-control shadow-none"
                                        name="skill_lang_1"
                                        ref={register}
                                        value={skillLang1}
                                        onChange={(e) =>
                                          setSkillLang1(e.target.value)
                                        }
                                      >
                                        <option value="">ระบุ</option>
                                        <option value="ภาษาอังกฤษ">
                                          ภาษาอังกฤษ
                                        </option>
                                        <option value="ภาษาจีน">ภาษาจีน</option>
                                        <option value="ภาษาญี่ปุ่น">
                                          ภาษาญี่ปุ่น
                                        </option>
                                        <option value="ภาษาเกาหลี">
                                          ภาษาเกาหลี
                                        </option>
                                        <option value="ภาษาฝรั่งเศส">
                                          ภาษาฝรั่งเศส
                                        </option>
                                        <option value="ภาษาเยอรมัน">
                                          ภาษาเยอรมัน
                                        </option>
                                        <option value="ภาษาโปรตุเกส">
                                          ภาษาโปรตุเกส
                                        </option>
                                        <option value="ภาษามลายู">
                                          ภาษามลายู
                                        </option>
                                      </select>
                                    </div>
                                    <div className="col-12 col-md-2 pt-3">
                                      <select
                                        className="form-control shadow-none"
                                        name="skill_lang_1_level"
                                        ref={register}
                                        value={skillLang1LV}
                                        onChange={(e) =>
                                          setSkillLang1LV(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          กรุณาเลือกระดับ
                                        </option>
                                        <option value="พอใช้">พอใช้</option>
                                        <option value="ดี">ดี</option>
                                        <option value="ดีมาก">ดีมาก</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-md-1 pt-3 mt-2">
                                      <span className="text-color-form align-left">
                                        ภาษาที่ 2 :
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-2 pt-3">
                                      <select
                                        className="form-control shadow-none"
                                        name="skill_lang_2"
                                        ref={register}
                                        value={skillLang2}
                                        onChange={(e) =>
                                          setSkillLang2(e.target.value)
                                        }
                                      >
                                        <option value="">ระบุ</option>
                                        <option value="ภาษาอังกฤษ">
                                          ภาษาอังกฤษ
                                        </option>
                                        <option value="ภาษาจีน">ภาษาจีน</option>
                                        <option value="ภาษาญี่ปุ่น">
                                          ภาษาญี่ปุ่น
                                        </option>
                                        <option value="ภาษาเกาหลี">
                                          ภาษาเกาหลี
                                        </option>
                                        <option value="ภาษาฝรั่งเศส">
                                          ภาษาฝรั่งเศส
                                        </option>
                                        <option value="ภาษาเยอรมัน">
                                          ภาษาเยอรมัน
                                        </option>
                                        <option value="ภาษาโปรตุเกส">
                                          ภาษาโปรตุเกส
                                        </option>
                                        <option value="ภาษามลายู">
                                          ภาษามลายู
                                        </option>
                                      </select>
                                    </div>
                                    <div className="col-12 col-md-2 pt-3">
                                      <select
                                        className="form-control shadow-none"
                                        name="skill_lang_2_level"
                                        ref={register}
                                        value={skillLang2LV}
                                        onChange={(e) =>
                                          setSkillLang2LV(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          กรุณาเลือกระดับ
                                        </option>
                                        <option value="พอใช้">พอใช้</option>
                                        <option value="ดี">ดี</option>
                                        <option value="ดีมาก">ดีมาก</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-md-1 pt-3 mt-2">
                                      <span className="text-color-form align-left">
                                        ภาษาที่ 3 :
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-2 pt-3">
                                      <select
                                        className="form-control shadow-none"
                                        name="skill_lang_3"
                                        ref={register}
                                        value={skillLang3}
                                        onChange={(e) =>
                                          setSkillLang3(e.target.value)
                                        }
                                      >
                                        <option value="">ระบุ</option>
                                        <option value="ภาษาอังกฤษ">
                                          ภาษาอังกฤษ
                                        </option>
                                        <option value="ภาษาจีน">ภาษาจีน</option>
                                        <option value="ภาษาญี่ปุ่น">
                                          ภาษาญี่ปุ่น
                                        </option>
                                        <option value="ภาษาเกาหลี">
                                          ภาษาเกาหลี
                                        </option>
                                        <option value="ภาษาฝรั่งเศส">
                                          ภาษาฝรั่งเศส
                                        </option>
                                        <option value="ภาษาเยอรมัน">
                                          ภาษาเยอรมัน
                                        </option>
                                        <option value="ภาษาโปรตุเกส">
                                          ภาษาโปรตุเกส
                                        </option>
                                        <option value="ภาษามลายู">
                                          ภาษามลายู
                                        </option>
                                      </select>
                                    </div>
                                    <div className="col-12 col-md-2 pt-3">
                                      <select
                                        className="form-control shadow-none"
                                        name="skill_lang_3_level"
                                        ref={register}
                                        value={skillLang3LV}
                                        onChange={(e) =>
                                          setSkillLang3LV(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          กรุณาเลือกระดับ
                                        </option>
                                        <option value="พอใช้">พอใช้</option>
                                        <option value="ดี">ดี</option>
                                        <option value="ดีมาก">ดีมาก</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-md-12 pt-3 mt-3">
                                      <span className="text-header align-left">
                                        ผลการสอบวัดความรู้
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-1 pt-3 mt-3">
                                      <input
                                        type="checkbox"
                                        name="skill_toeic"
                                        ref={register}
                                        onClick={(e) =>
                                          flagSkillToeic === 1
                                            ? setFlagSkillToeic(0)
                                            : setFlagSkillToeic(1)
                                        }
                                        defaultChecked={flagSkillToeic === 1}
                                      />
                                      <span className="text-color-form pl-2">
                                        {" "}
                                        TOEIC
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-1 pt-3 mt-3">
                                      คะแนน
                                    </div>
                                    <div className="col-12 col-md-1 pt-4">
                                      <input
                                        className="form-control shadow-none"
                                        type="text"
                                        name="skill_toeic_point"
                                        ref={register}
                                        defaultValue={
                                          employee.skill_toeic_point
                                        }
                                      />
                                    </div>
                                    <div className="col-12 col-sm-1 col-md-2 pt-4">
                                      <label>
                                        <input
                                          type="file"
                                          ref={toeicInput}
                                          onClick={(e) =>
                                            (e.target.value = null)
                                          }
                                          onChange={(e) =>
                                            handleUploadProcess("toeic", e)
                                          }
                                          style={{ display: "none" }}
                                        />
                                        <Button
                                          variant="contained"
                                          onClick={(e) =>
                                            handleUploadImg("toeic", e)
                                          }
                                          className="btn-no-border btn-color-search"
                                        >
                                          <i className="fas fa-paperclip pr-2"></i>{" "}
                                          แนบไฟล์
                                        </Button>
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-2 pt-3 mt-3">
                                      {empToeic !== "" && (
                                        <a
                                          href={
                                            config.get("apiUrl") +
                                            "/api/file/" +
                                            empToeic
                                          }
                                          target="_blank"
                                        >
                                          ดูไฟล์แนบ
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-md-1 pt-3 mt-3">
                                      <input
                                        type="checkbox"
                                        name="skill_tofel"
                                        ref={register}
                                        onClick={(e) =>
                                          flagSkillTofel === 1
                                            ? setFlagSkillTofel(0)
                                            : setFlagSkillTofel(1)
                                        }
                                        defaultChecked={flagSkillTofel === 1}
                                      />
                                      <span className="text-color-form pl-2">
                                        {" "}
                                        TOFEL
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-1 pt-3 mt-3">
                                      คะแนน
                                    </div>
                                    <div className="col-12 col-md-1 pt-4">
                                      <input
                                        className="form-control shadow-none"
                                        type="text"
                                        name="skill_tofel_point"
                                        ref={register}
                                        defaultValue={
                                          employee.skill_tofel_point
                                        }
                                      />
                                    </div>
                                    <div className="col-12 col-sm-1 col-md-2 pt-4">
                                      <label>
                                        <input
                                          type="file"
                                          ref={tofelInput}
                                          onClick={(e) =>
                                            (e.target.value = null)
                                          }
                                          onChange={(e) =>
                                            handleUploadProcess("tofel", e)
                                          }
                                          style={{ display: "none" }}
                                        />
                                        <Button
                                          variant="contained"
                                          onClick={(e) =>
                                            handleUploadImg("tofel", e)
                                          }
                                          className="btn-no-border btn-color-search"
                                        >
                                          <i className="fas fa-paperclip pr-2"></i>{" "}
                                          แนบไฟล์
                                        </Button>
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-2 pt-3 mt-3">
                                      {empTofel !== "" && (
                                        <a
                                          href={
                                            config.get("apiUrl") +
                                            "/api/file/" +
                                            empTofel
                                          }
                                          target="_blank"
                                        >
                                          ดูไฟล์แนบ
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-md-1 pt-3 mt-3">
                                      <input
                                        type="checkbox"
                                        name="skill_ielts"
                                        ref={register}
                                        onClick={(e) =>
                                          flagSkillIelts === 1
                                            ? setFlagSkillIelts(0)
                                            : setFlagSkillIelts(1)
                                        }
                                        defaultChecked={flagSkillIelts === 1}
                                      />
                                      <span className="text-color-form pl-2">
                                        {" "}
                                        IELTS
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-1 pt-3 mt-3">
                                      คะแนน
                                    </div>
                                    <div className="col-12 col-md-1 pt-4">
                                      <input
                                        className="form-control shadow-none"
                                        type="text"
                                        name="skill_ielts_point"
                                        ref={register}
                                        defaultValue={
                                          employee.skill_ielts_point
                                        }
                                      />
                                    </div>
                                    <div className="col-12 col-sm-1 col-md-2 pt-4">
                                      <label>
                                        <input
                                          type="file"
                                          ref={ieltsInput}
                                          onClick={(e) =>
                                            (e.target.value = null)
                                          }
                                          onChange={(e) =>
                                            handleUploadProcess("ielts", e)
                                          }
                                          style={{ display: "none" }}
                                        />
                                        <Button
                                          variant="contained"
                                          onClick={(e) =>
                                            handleUploadImg("ielts", e)
                                          }
                                          className="btn-no-border btn-color-search"
                                        >
                                          <i className="fas fa-paperclip pr-2"></i>{" "}
                                          แนบไฟล์
                                        </Button>
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-2 pt-3 mt-3">
                                      {empIelts !== "" && (
                                        <a
                                          href={
                                            config.get("apiUrl") +
                                            "/api/file/" +
                                            empIelts
                                          }
                                          target="_blank"
                                        >
                                          ดูไฟล์แนบ
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-md-1 pt-3 mt-3">
                                      <input
                                        type="checkbox"
                                        name="skill_cutep"
                                        ref={register}
                                        onClick={(e) =>
                                          flagSkillCutep === 1
                                            ? setFlagSkillCutep(0)
                                            : setFlagSkillCutep(1)
                                        }
                                        defaultChecked={flagSkillCutep === 1}
                                      />
                                      <span className="text-color-form pl-2">
                                        {" "}
                                        CU-TEP
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-1 pt-3 mt-3">
                                      คะแนน
                                    </div>
                                    <div className="col-12 col-md-1 pt-4">
                                      <input
                                        className="form-control shadow-none"
                                        type="text"
                                        name="skill_cutep_point"
                                        ref={register}
                                        defaultValue={
                                          employee.skill_cutep_point
                                        }
                                      />
                                    </div>
                                    <div className="col-12 col-sm-1 col-md-2 pt-4">
                                      <label>
                                        <input
                                          type="file"
                                          ref={cutepInput}
                                          onChange={(e) =>
                                            handleUploadProcess("cutep", e)
                                          }
                                          style={{ display: "none" }}
                                        />
                                        <Button
                                          variant="contained"
                                          onClick={(e) =>
                                            handleUploadImg("cutep", e)
                                          }
                                          className="btn-no-border btn-color-search"
                                        >
                                          <i className="fas fa-paperclip pr-2"></i>{" "}
                                          แนบไฟล์
                                        </Button>
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-2 pt-3 mt-3">
                                      {empCutep !== "" && (
                                        <a
                                          href={
                                            config.get("apiUrl") +
                                            "/api/file/" +
                                            empCutep
                                          }
                                          target="_blank"
                                        >
                                          ดูไฟล์แนบ
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-md-1 pt-3 mt-3">
                                      <input
                                        type="checkbox"
                                        name="skill_tutep"
                                        ref={register}
                                        onClick={(e) =>
                                          flagSkillTutep === 1
                                            ? setFlagSkillTutep(0)
                                            : setFlagSkillTutep(1)
                                        }
                                        defaultChecked={flagSkillTutep === 1}
                                      />
                                      <span className="text-color-form pl-2">
                                        {" "}
                                        TU-GET
                                      </span>
                                    </div>
                                    <div className="col-12 col-md-1 pt-3 mt-3">
                                      คะแนน
                                    </div>
                                    <div className="col-12 col-md-1 pt-4">
                                      <input
                                        className="form-control shadow-none"
                                        type="text"
                                        name="skill_tutep_point"
                                        ref={register}
                                        defaultValue={
                                          employee.skill_tutep_point
                                        }
                                      />
                                    </div>
                                    <div className="col-12 col-sm-1 col-md-2 pt-4">
                                      <label>
                                        <input
                                          type="file"
                                          ref={tutepInput}
                                          onClick={(e) =>
                                            (e.target.value = null)
                                          }
                                          onChange={(e) =>
                                            handleUploadProcess("tutep", e)
                                          }
                                          style={{ display: "none" }}
                                        />
                                        <Button
                                          variant="contained"
                                          onClick={(e) =>
                                            handleUploadImg("tutep", e)
                                          }
                                          className="btn-no-border btn-color-search"
                                        >
                                          <i className="fas fa-paperclip pr-2"></i>{" "}
                                          แนบไฟล์
                                        </Button>
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-2 pt-3 mt-3">
                                      {empTutep !== "" && (
                                        <a
                                          href={
                                            config.get("apiUrl") +
                                            "/api/file/" +
                                            empTutep
                                          }
                                          target="_blank"
                                        >
                                          ดูไฟล์แนบ
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </fieldset>

                                <div className="row">
                                  <div className="col-12 col-md-12 pt-3">
                                    <fieldset className="border p-2">
                                      <legend className="w-auto text-header">
                                        ความสามารถทางด้านคอมพิวเตอร์
                                      </legend>
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3 mt-2">
                                          <span className="text-color-form align-left">
                                            ด้านการพัฒนาโปรแกรม :
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_com_1"
                                            ref={register}
                                            value={skillCom1}
                                            onChange={(e) =>
                                              setSkillCom1(e.target.value)
                                            }
                                          >
                                            <option value="">ระบุ</option>
                                            <option value="C">C</option>
                                            <option value="C++">C++</option>
                                            <option value="C#">C#</option>
                                            <option value="COBOL">COBOL</option>
                                            <option value="Pascal">
                                              Pascal
                                            </option>
                                            <option value="BASIC">BASIC</option>
                                            <option value="FORTRAN">
                                              FORTRAN
                                            </option>
                                            <option value="Java">Java</option>
                                            <option value="JavaScript">
                                              JavaScript
                                            </option>
                                            <option value="Perl">Perl</option>
                                            <option value="PHP">PHP</option>
                                            <option value="Python">
                                              Python
                                            </option>
                                            <option value="Prolog">
                                              Prolog
                                            </option>
                                            <option value="Objective-C">
                                              Objective-C
                                            </option>
                                            <option value="Ruby">Ruby</option>
                                          </select>
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_com_1_level"
                                            ref={register}
                                            value={skillCom1LV}
                                            onChange={(e) =>
                                              setSkillCom1LV(e.target.value)
                                            }
                                          >
                                            <option value="">
                                              กรุณาเลือกระดับ
                                            </option>
                                            <option value="พอใช้">พอใช้</option>
                                            <option value="ดี">ดี</option>
                                            <option value="ดีมาก">ดีมาก</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3 mt-2">
                                          <span className="text-color-form align-left">
                                            ด้านการพัฒนาโปรแกรม :
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_com_2"
                                            ref={register}
                                            value={skillCom2}
                                            onChange={(e) =>
                                              setSkillCom2(e.target.value)
                                            }
                                          >
                                            <option value="">ระบุ</option>
                                            <option value="C">C</option>
                                            <option value="C++">C++</option>
                                            <option value="C#">C#</option>
                                            <option value="COBOL">COBOL</option>
                                            <option value="Pascal">
                                              Pascal
                                            </option>
                                            <option value="BASIC">BASIC</option>
                                            <option value="FORTRAN">
                                              FORTRAN
                                            </option>
                                            <option value="Java">Java</option>
                                            <option value="JavaScript">
                                              JavaScript
                                            </option>
                                            <option value="Perl">Perl</option>
                                            <option value="PHP">PHP</option>
                                            <option value="Python">
                                              Python
                                            </option>
                                            <option value="Prolog">
                                              Prolog
                                            </option>
                                            <option value="Objective-C">
                                              Objective-C
                                            </option>
                                            <option value="Ruby">Ruby</option>
                                          </select>
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_com_2_level"
                                            ref={register}
                                            value={skillCom2LV}
                                            onChange={(e) =>
                                              setSkillCom2LV(e.target.value)
                                            }
                                          >
                                            <option value="">
                                              กรุณาเลือกระดับ
                                            </option>
                                            <option value="พอใช้">พอใช้</option>
                                            <option value="ดี">ดี</option>
                                            <option value="ดีมาก">ดีมาก</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3 mt-2">
                                          <span className="text-color-form align-left">
                                            ด้านการซ่อมบำรุงและประกอบคอมพิวเตอร์
                                            :
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-4 pt-3">
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="skill_com_service"
                                            ref={register}
                                            defaultValue={
                                              employee.skill_com_service
                                            }
                                          />
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_com_service_level"
                                            ref={register}
                                            value={skillComServiceLV}
                                            onChange={(e) =>
                                              setSkillComServiceLV(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">
                                              กรุณาเลือกระดับ
                                            </option>
                                            <option value="พอใช้">พอใช้</option>
                                            <option value="ดี">ดี</option>
                                            <option value="ดีมาก">ดีมาก</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3 mt-2">
                                          <span className="text-color-form align-left">
                                            ด้านคอมพิวเตอร์แม่ข่าย (Server)
                                            และเครือข่าย :
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-4 pt-3">
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="skill_com_server"
                                            ref={register}
                                            defaultValue={
                                              employee.skill_com_server
                                            }
                                          />
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_com_server_level"
                                            ref={register}
                                            value={skillComServerLV}
                                            onChange={(e) =>
                                              setSkillComServerLV(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">
                                              กรุณาเลือกระดับ
                                            </option>
                                            <option value="พอใช้">พอใช้</option>
                                            <option value="ดี">ดี</option>
                                            <option value="ดีมาก">ดีมาก</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3 mt-2">
                                          <span className="text-color-form align-left">
                                            ด้านคอมพิวเตอร์กราฟฟิกและการออกแบบ :
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_com_graphic"
                                            ref={register}
                                            value={skillComGraphic}
                                            onChange={(e) =>
                                              setSkillComGraphic(e.target.value)
                                            }
                                          >
                                            <option value="">ระบุ</option>
                                            <option value="PHOTOSHOP">
                                              PHOTOSHOP
                                            </option>
                                          </select>
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_com_graphic_level"
                                            ref={register}
                                            value={skillComGraphicLV}
                                            onChange={(e) =>
                                              setSkillComGraphicLV(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">
                                              กรุณาเลือกระดับ
                                            </option>
                                            <option value="พอใช้">พอใช้</option>
                                            <option value="ดี">ดี</option>
                                            <option value="ดีมาก">ดีมาก</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3 mt-2">
                                          <span className="text-color-form align-left">
                                            ด้านการถ่ายทำและตัดต่อวิดีโอ :
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_com_vedio"
                                            ref={register}
                                            value={skillComVedio}
                                            onChange={(e) =>
                                              setSkillComVedio(e.target.value)
                                            }
                                          >
                                            <option value="">ระบุ</option>
                                            <option value="Vedio Studio">
                                              Vedio Studio
                                            </option>
                                          </select>
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_com_vedio_level"
                                            ref={register}
                                            value={skillComVedioLV}
                                            onChange={(e) =>
                                              setSkillComVedioLV(e.target.value)
                                            }
                                          >
                                            <option value="">
                                              กรุณาเลือกระดับ
                                            </option>
                                            <option value="พอใช้">พอใช้</option>
                                            <option value="ดี">ดี</option>
                                            <option value="ดีมาก">ดีมาก</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3 mt-2">
                                          <span className="text-color-form align-left">
                                            ด้านโปรแกรมสำนักงาน :
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_com_office"
                                            ref={register}
                                            value={skillComOffice}
                                            onChange={(e) =>
                                              setSkillComOffice(e.target.value)
                                            }
                                          >
                                            <option value="">ระบุ</option>
                                            <option value="WORD">WORD</option>
                                            <option value="EXCEL">EXCEL</option>
                                            <option value="POWER POINT">
                                              POWER POINT
                                            </option>
                                          </select>
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_com_office_level"
                                            ref={register}
                                            value={skillComOfficeLV}
                                            onChange={(e) =>
                                              setSkillComOfficeLV(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">
                                              กรุณาเลือกระดับ
                                            </option>
                                            <option value="พอใช้">พอใช้</option>
                                            <option value="ดี">ดี</option>
                                            <option value="ดีมาก">ดีมาก</option>
                                          </select>
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-md-12 pt-3">
                                    <fieldset className="border p-2">
                                      <legend className="w-auto text-header">
                                        ความสามารถทางด้านด้านพิมพ์ดีด
                                      </legend>
                                      <div className="row">
                                        <div className="col-12 col-md-1 pt-2">
                                          ไทย
                                        </div>
                                        <div className="col-12 col-md-1">
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="skill_typing_thai"
                                            ref={register}
                                            defaultValue={
                                              employee.skill_typing_thai
                                            }
                                          />
                                        </div>
                                        <div className="col-12 col-md-10 pt-2">
                                          คำ/นาที
                                        </div>
                                      </div>
                                      <div className="row mt-2">
                                        <div className="col-12 col-md-1 pt-2">
                                          อังกฤษ
                                        </div>
                                        <div className="col-12 col-md-1">
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="skill_typing_eng"
                                            ref={register}
                                            defaultValue={
                                              employee.skill_typing_eng
                                            }
                                          />
                                        </div>
                                        <div className="col-12 col-md-1 pt-2">
                                          คำ/นาที
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-md-12 pt-3">
                                    <fieldset className="border p-2">
                                      <legend className="w-auto text-header">
                                        ความสามารถในการขับขี่
                                      </legend>
                                      <div className="row">
                                        <div className="col-12 col-md-12 pt-3">
                                          <input
                                            type="checkbox"
                                            name="skill_drive_1"
                                            ref={register}
                                            onClick={(e) =>
                                              flagSkillDrive1 === 1
                                                ? setFlagSkillDrive1(0)
                                                : setFlagSkillDrive1(1)
                                            }
                                            defaultChecked={
                                              flagSkillDrive1 === 1
                                            }
                                          />
                                          <span className="text-color-form pl-2">
                                            {" "}
                                            รถยนต์
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-12 pt-3">
                                          <input
                                            type="checkbox"
                                            name="skill_drive_3"
                                            ref={register}
                                            onClick={(e) =>
                                              flagSkillDrive3 === 1
                                                ? setFlagSkillDrive3(0)
                                                : setFlagSkillDrive3(1)
                                            }
                                            defaultChecked={
                                              flagSkillDrive3 === 1
                                            }
                                          />
                                          <span className="text-color-form pl-2">
                                            {" "}
                                            รถบรรทุก
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-12 pt-3">
                                          <input
                                            type="checkbox"
                                            name="skill_drive_4"
                                            ref={register}
                                            onClick={(e) =>
                                              flagSkillDrive4 === 1
                                                ? setFlagSkillDrive4(0)
                                                : setFlagSkillDrive4(1)
                                            }
                                            defaultChecked={
                                              flagSkillDrive4 === 1
                                            }
                                          />
                                          <span className="text-color-form pl-2">
                                            {" "}
                                            รถกระบะ
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-12 pt-3">
                                          <input
                                            type="checkbox"
                                            name="skill_drive_5"
                                            ref={register}
                                            onClick={(e) =>
                                              flagSkillDrive5 === 1
                                                ? setFlagSkillDrive5(0)
                                                : setFlagSkillDrive5(1)
                                            }
                                            defaultChecked={
                                              flagSkillDrive5 === 1
                                            }
                                          />
                                          <span className="text-color-form pl-2">
                                            {" "}
                                            รถฟอร์คลิฟท์
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-12 pt-3">
                                          <input
                                            type="checkbox"
                                            name="skill_drive_2"
                                            ref={register}
                                            onClick={(e) =>
                                              flagSkillDrive2 === 1
                                                ? setFlagSkillDrive2(0)
                                                : setFlagSkillDrive2(1)
                                            }
                                            defaultChecked={
                                              flagSkillDrive2 === 1
                                            }
                                          />
                                          <span className="text-color-form pl-2">
                                            {" "}
                                            รถจักรยานยนต์
                                          </span>
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-md-12 pt-3">
                                    <fieldset className="border p-2">
                                      <legend className="w-auto text-header">
                                        ความสามารถทางด้านดนตรี กีฬา และงานอดิเรก
                                      </legend>
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3">
                                          <span className="text-color-form align-left">
                                            ด้านกีฬา{" "}
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-4 pt-3">
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="skill_sport"
                                            ref={register}
                                            defaultValue={employee.skill_sport}
                                          />
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_sport_level"
                                            ref={register}
                                            value={skillSportLV}
                                            onChange={(e) =>
                                              setSkillSportLV(e.target.value)
                                            }
                                          >
                                            <option value="">
                                              กรุณาเลือกระดับ
                                            </option>
                                            <option value="พอใช้">พอใช้</option>
                                            <option value="ดี">ดี</option>
                                            <option value="ดีมาก">ดีมาก</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3">
                                          <span className="text-color-form align-left">
                                            ด้านดนตรี{" "}
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-4 pt-3">
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="skill_music"
                                            ref={register}
                                            defaultValue={employee.skill_music}
                                          />
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_music_level"
                                            ref={register}
                                            value={skillMusicLV}
                                            onChange={(e) =>
                                              setSkillMusicLV(e.target.value)
                                            }
                                          >
                                            <option value="">
                                              กรุณาเลือกระดับ
                                            </option>
                                            <option value="พอใช้">พอใช้</option>
                                            <option value="ดี">ดี</option>
                                            <option value="ดีมาก">ดีมาก</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3">
                                          <span className="text-color-form align-left">
                                            งานอดิเรก{" "}
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-4 pt-3">
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="skill_hobby"
                                            ref={register}
                                            defaultValue={employee.skill_hobby}
                                          />
                                        </div>
                                        <div className="col-12 col-md-2 pt-3">
                                          <select
                                            className="form-control shadow-none"
                                            name="skill_hobby_level"
                                            ref={register}
                                            value={skillHobbyLV}
                                            onChange={(e) =>
                                              setSkillHobbyLV(e.target.value)
                                            }
                                          >
                                            <option value="">
                                              กรุณาเลือกระดับ
                                            </option>
                                            <option value="พอใช้">พอใช้</option>
                                            <option value="ดี">ดี</option>
                                            <option value="ดีมาก">ดีมาก</option>
                                          </select>
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-md-12 pt-3">
                                    <fieldset className="border p-2">
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3">
                                          <span className="text-color-form align-left">
                                            ความสามารถพิเศษอื่นๆ{" "}
                                          </span>
                                        </div>
                                        <div className="col-12 col-md-4 pt-3">
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="skill_other_2"
                                            ref={register}
                                            defaultValue={
                                              employee.skill_other_2
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3">
                                          <span className="text-color-form align-left"></span>
                                        </div>
                                        <div className="col-12 col-md-4 pt-3">
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="skill_other_3"
                                            ref={register}
                                            defaultValue={
                                              employee.skill_other_3
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3">
                                          <span className="text-color-form align-left"></span>
                                        </div>
                                        <div className="col-12 col-md-4 pt-3">
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="skill_other_4"
                                            ref={register}
                                            defaultValue={
                                              employee.skill_other_4
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-md-2 pt-3">
                                          <span className="text-color-form align-left"></span>
                                        </div>
                                        <div className="col-12 col-md-4 pt-3">
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            name="skill_other_5"
                                            ref={register}
                                            defaultValue={
                                              employee.skill_other_5
                                            }
                                          />
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <PersonelExpertTab
                            register={register}
                            employee={employee}
                          />
                          <PersonelCourseTab
                            register={register}
                            employee={employee}
                            control={control}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12"></div>
                      </div>
                    </div>
                    <div className="card-footer">
                      {/*<div className="col-12 mt-3">*/}
                      {/*  <hr style={{ margin: "6px -10px 10px" }} />*/}
                      {/*</div>*/}
                      <div className="d-flex justify-content-between row mt-1">
                        <div className="col-12 col-md-2 mt-2">
                          <Button
                            type="submit"
                            variant="contained"
                            className="btn btn-deep btn-lg btn-block font-cschatthai"
                            style={{ display: btnSave }}
                          >
                            <i className="fas fa-save pr-2"></i>บันทึก
                          </Button>
                        </div>
                        {/*<div className="col-12 col-md-8 mt-2"></div>*/}
                        <div
                          className="col-12 col-md-2 mt-2"
                          style={{ display: styleDisplayDeleteUser() }}
                        >
                          <Button
                            type="button"
                            variant="contained"
                            className="btn btn-warning btn-lg btn-block font-cschatthai"
                            onClick={handleDelShow}
                            style={{ display: btnDel }}
                          >
                            <i className="fas fa-trash-alt pr-2"></i>ลบรายการ
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <Modal
        show={showMsg}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">
          <span style={{ fontSize: 26 }}>{textMsg}</span>
        </Modal.Body>
      </Modal>

      <Modal show={showDelMsg} onHide={handleDelClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center text-danger">
          คุณต้องการลบข้อมูลใช่หรือไม่
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="contained"
            className="btn-no-border btn-color-submit-small mr-2"
            onClick={deleteUser}
          >
            ใช่
          </Button>

          <Button
            type="button"
            variant="contained"
            className="btn-no-border btn-color-delete-small"
            onClick={handleDelClose}
          >
            ไม่ใช่
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCamera1} onHide={camera1Close} size="xl">
        <Modal.Body className="text-color-form text-center">
          <Image
            src={"./img/frame_person.png"}
            style={{
              position: "absolute",
              width: 360,
              marginTop: "50px",
              opacity: 0.7,
              zIndex: "1",
            }}
          />
          {!imgSrc && (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={360}
              height={640}
              videoConstraints={videoConstraints}
              screenshotQuality={1}
            />
          )}
          <br />
          {!imgSrc && (
            <Button
              type="button"
              variant="contained"
              size="large"
              className="btn-no-border btn-color-submit-small"
              onClick={capture}
            >
              <i className="fas fa-camera-retro"></i>
            </Button>
          )}
          <br />
        </Modal.Body>
      </Modal>
      <Modal show={showCamera2} onHide={camera2Close} size="xl">
        <Modal.Body className="text-color-form text-center">
          <Image
            src={"./img/frame_person.png"}
            style={{
              position: "absolute",
              width: 360,
              marginTop: "50px",
              opacity: 0.7,
              zIndex: "1",
            }}
          />
          {!imgSrc2 && (
            <Webcam
              audio={false}
              ref={webcamRef2}
              screenshotFormat="image/jpeg"
              width={360}
              height={640}
              videoConstraints={videoConstraints}
              screenshotQuality={1}
            />
          )}
          <br />
          {!imgSrc2 && (
            <Button
              type="button"
              variant="contained"
              size="large"
              className="btn-no-border btn-color-submit-small"
              onClick={capture2}
            >
              <i className="fas fa-camera-retro"></i>
            </Button>
          )}
          <br />
        </Modal.Body>
      </Modal>
      <Modal show={showCamera3} onHide={camera3Close} size="xl">
        <Modal.Body className="text-color-form text-center">
          {!imgSrc3 && (
            <Webcam
              audio={false}
              ref={webcamRef3}
              screenshotFormat="image/jpeg"
              width={360}
              height={640}
              videoConstraints={videoConstraints}
              screenshotQuality={1}
            />
          )}
          <br />
          {!imgSrc3 && (
            <Button
              type="button"
              variant="contained"
              size="large"
              className="btn-no-border btn-color-submit-small"
              onClick={capture3}
            >
              <i className="fas fa-camera-retro"></i>
            </Button>
          )}
          <br />
        </Modal.Body>
      </Modal>
      <Modal show={showCamera4} onHide={camera4Close} size="xl">
        <Modal.Body className="text-color-form text-center">
          {!imgSrc4 && (
            <Webcam
              audio={false}
              ref={webcamRef4}
              screenshotFormat="image/jpeg"
              width={360}
              height={640}
              videoConstraints={videoConstraints}
              screenshotQuality={1}
            />
          )}
          <br />
          {!imgSrc4 && (
            <Button
              type="button"
              variant="contained"
              size="large"
              className="btn-no-border btn-color-submit-small"
              onClick={capture4}
            >
              <i className="fas fa-camera-retro"></i>
            </Button>
          )}
          <br />
        </Modal.Body>
      </Modal>
      <Modal show={showCamera5} onHide={camera5Close} size="xl">
        <Modal.Body className="text-color-form text-center">
          {!imgSrc5 && (
            <Webcam
              audio={false}
              ref={webcamRef5}
              screenshotFormat="image/jpeg"
              width={360}
              height={640}
              videoConstraints={videoConstraints}
              screenshotQuality={1}
            />
          )}
          <br />
          {!imgSrc5 && (
            <Button
              type="button"
              variant="contained"
              size="large"
              className="btn-no-border btn-color-submit-small"
              onClick={capture5}
            >
              <i className="fas fa-camera-retro"></i>
            </Button>
          )}
          <br />
        </Modal.Body>
      </Modal>
      <Modal show={showCamera6} onHide={camera6Close} size="xl">
        <Modal.Body className="text-color-form text-center">
          {!imgSrc6 && (
            <Webcam
              audio={false}
              ref={webcamRef6}
              screenshotFormat="image/jpeg"
              width={360}
              height={640}
              videoConstraints={videoConstraints}
              screenshotQuality={1}
            />
          )}
          <br />
          {!imgSrc6 && (
            <Button
              type="button"
              variant="contained"
              size="large"
              className="btn-no-border btn-color-submit-small"
              onClick={capture6}
            >
              <i className="fas fa-camera-retro"></i>
            </Button>
          )}
          <br />
        </Modal.Body>
      </Modal>
      <Modal show={signShow} onHide={signPadClose} size="" dialogClassName="">
        <Modal.Body className="text-color-form text-center">
          <div className="row">
            <div className="col-12">
              <SignatureCanvas
                penColor="black"
                ref={sigPadRef}
                canvasProps={{
                  width: 333,
                  height: 100,
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  className: "sigPad sigOutline",
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center p-3">
              <span className="text-color-form align-middle">ลายเซ็นต์</span>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Button
                type="button"
                variant="contained"
                className="btn-no-border btn-color-submit2 btn-block"
                onClick={() => trimSign()}
                disabled={isLoading.signature}
              >
                <i className="far fa-save pr-2"></i> บันทึก
                {isLoading.signature && (
                  <div
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </Button>
            </div>
            <div className="col-6">
              <Button
                type="button"
                variant="contained"
                className="btn-no-border btn-color-delete btn-block"
                onClick={() => clearSign()}
                disabled={isLoading.signature}
              >
                <i className="fas fa-eraser pr-2"></i> ลบ
                {isLoading.signatureDelete && (
                  <div
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="loading-container overlay" id="overlay">
        <div className="loading-wrap"></div>
        <div className="branding">
          <img src="img/logo-small.png" />
          <div className="spinner"></div>
        </div>
      </div>
    </div>
  );
};

export default PersonnelInfo;
