import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import config from "react-global-configuration";
import { FormControlLabel, List } from "@material-ui/core";
import { Controller } from "react-hook-form";

// import $ from "jquery";
// import { useSelector } from "react-redux";
// import { userId } from "../../../app/Admin/PersonnelInfoSlice";

const PersonelExpertTab = ({ register, employee }) => {
  const [keycloak] = useKeycloak();

  // const store_UserId = useSelector(userId);

  const [dataCompetency, setCompetency] = useState([]);
  const [dataExpert, setExpert] = useState([]);
  const [dataExpertWorkTemp, setExpertWorkTemp] = useState([]);
  const [dataExpertWork, setExpertWork] = useState([]);
  const [masExpert, setMasExpert] = useState([]);
  const [masCompetency, setMasCompetency] = useState([]);
  const [empCourse, setEmpCourse] = useState([]);

  const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   console.log("ket keycloak");
  // }, [keycloak.token]);

  useEffect(() => {
    if (employee.emp_expert && employee.emp_expert.length > 0) {
      setExpert(employee.emp_expert);

      if (employee.emp_expert.length < 5) {
        const emp_expert_length = employee.emp_expert.length;
        const maxIndex = Math.max(0, 5 - emp_expert_length);

        for (let i = 0; i < maxIndex; i++) {
          addNewRow();
        }
      }
    } else {
      setExpert([
        {
          expert_id: "",
          expert_time: "",
          expert_train: "",
          expert_work: "",
          expert_level: "",
        },
        {
          expert_id: "",
          expert_time: "",
          expert_train: "",
          expert_work: "",
          expert_level: "",
        },
        {
          expert_id: "",
          expert_time: "",
          expert_train: "",
          expert_work: "",
          expert_level: "",
        },
        {
          expert_id: "",
          expert_time: "",
          expert_train: "",
          expert_work: "",
          expert_level: "",
        },
        {
          expert_id: "",
          expert_time: "",
          expert_train: "",
          expert_work: "",
          expert_level: "",
        },
      ]);
    }
    if (employee.emp_competency) {
      setMasCompetency([]);
      setCompetency(employee.emp_competency);
    }

    fetchData();
  }, [employee]);

  const fetchData = async () => {
    const resExpert = await axios.get(
      config.get("apiUrl") + "/api/master/expert",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setMasExpert(resExpert.data.data.records);

    const resCompetency = await axios.get(
      config.get("apiUrl") + "/api/master/competency",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setMasCompetency(resCompetency.data.data.records);

    if (employee.employee_id) {
      const resEmpCourse = await axios.get(
        config.get("apiUrl") + "/api/emp-course/get/" + employee.employee_id,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      setEmpCourse(resEmpCourse.data.data.records);
    }
  };

  function addNewRow() {
    setExpert((prev) => [
      ...prev,
      {
        expert_id: "",
        expert_time: "",
        expert_train: "",
        expert_work: "",
        expert_level: "",
      },
    ]);
  }

  const showModalClose = () => {
    // if (employeeMission) {
    //   let select = employeeMission.map((mission) =>
    //     mission.mission_id.toString()
    //   );
    //   setSelectedItems(select);
    // }

    setShowModal(false);
  };

  function addNewRowExpertWork() {
    setExpertWorkTemp((prev) => [
      ...prev,
      {
        expert_work: "",
      },
    ]);
  }

  const handleEditExpertWork = (id) => {
    setShowModal(true);

    if (id === "") {
      addNewRowExpertWork();
    }
  };

  return (
    <div
      className="tab-pane fade pl-3 pr-3"
      id="custom-content-expert-settings"
      role="tabpanel"
      aria-labelledby="custom-content-expert-settings-tab"
    >
      <div className="row">
        <div className="col-12 col-md-12 pt-3">
          <div className="row">
            <div className="col-12 col-md-12 pt-3">
              <fieldset className="border p-2">
                <legend className="w-auto text-header">
                  ประสบการณ์ทำงานและความเชี่ยวชาญ
                </legend>
                <div className="row">
                  <div className="col-12 col-md-12 pt-3">
                    <Alert
                      variant="secondary"
                      className="d-none d-lg-block"
                      style={{
                        backgroundColor: "#e2e3e5",
                        borderColor: "#d6d8db",
                        color: "#383d41",
                      }}
                    >
                      <p>ระดับสมรรถนะและความเชี่ยวชาญ ประกอบด้วย</p>
                      <p>
                        1.) ระดับเริ่มต้น (Novice) หมายถึง ผู้เริ่มปฏิบัติงาน
                        สามารถปฏิบัติงานพื้นฐานได้
                        ยังมีประสบการณ์น้อยต้องอาศัยการชี้นำจากผู้ที่มีประสบการณ์
                      </p>
                      <p>
                        2.) ระดับผู้ก้าวหน้าหรือเรียนรู้ (Advance Beginner)
                        หมายถึง ผู้มีประสบการณ์การปฏิบัติงาน
                        สามรถมองประเด็นปัญหาจากสถานการณ์ที่เกิดขึ้น
                        แต่ยังไม่สามารถจัดลับดำความสำคัญของประเด็นปัญหาได้
                      </p>
                      <p>
                        3.) ระดับผู้มีความสามารถหรือผู้ปฏิบัติ (Competent)
                        หมายถึง ผู้มีประสบการณ์การปฏิบัติงานโดยไม่มีการโยกย้าย
                        มีการพัฒนามากขึ้น สามารถวางแผน จัดลำดับความสำคัญได้
                      </p>
                      <p>
                        4.) ระดับผู้ชำนาญ (Proficient) หมายถึง
                        ผู้มีประสบการณ์การปฏิบัติงานในตำแหน่งเดิม/งานเดิม
                        สามารถมองสถานการณ์ได้กว้าง และรอบด้าน เข้าใจปัญหาต่างๆ
                        ได้ดีขึ้น สามารถเป็นผู้นำในการอภิปรายปัญหา
                      </p>
                      <p>
                        5.) ระดับผู้เชี่ยวชาญ (Expert) หมายถึง
                        ที่มีประสบการณ์การปฏิบัติงานในตำแหน่งเดิม/งานเดิม
                        เป็นผู้มีความสามารถในการปฏิบัติสูง
                        สามารถเชื่อมโยงความคิด และคาดการณ์ได้
                        สามารถบริหารจัดการสอน รวมทั้งเป็นแบบอย่างที่ดี
                      </p>
                    </Alert>

                    {/* <div className="text-right">
                      <Button
                        type="button"
                        className="btn-no-border btn-color-search btn btn-contained"
                        onClick={addNewRow}
                      >
                        + เพิ่ม
                      </Button>
                    </div> */}

                    <table
                      id="data-table"
                      className="table table-striped table-bordered display w-100 margin-bottom-30"
                    >
                      <thead style={{ backgroundColor: "#f3f3f4" }}>
                        <tr>
                          <th className="text-center">ลำดับ</th>
                          <th className="text-center">ด้านความเชี่ยวชาญ</th>
                          <th className="text-center">
                            ประสบการณ์ปฏิบัติงานในด้านความเชี่ยวชาญ (ปี)
                          </th>
                          <th className="text-center">การพัฒนา/ฝึกอบรม</th>
                          <th className="text-center">
                            ผลงานวิจัย/วิชาการ/ผลงานเด่น
                          </th>
                          <th className="text-center">ระดับความเชี่ยวชาญ</th>
                        </tr>
                      </thead>

                      <tbody>
                        {dataExpert.map((val, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              <select
                                className="form-control shadow-none"
                                ref={register}
                                name={`empExpert.${i}.expert_id`}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setExpert((perv) => {
                                    const newExpert = [...perv];

                                    newExpert[i] = {
                                      ...newExpert[i],
                                      expert_id: newValue,
                                    };
                                    return newExpert;
                                  });
                                }}
                                defaultValue={val.expert_id}
                                value={val.expert_id}
                              >
                                <option value="">กรุณาเลือก</option>
                                {masExpert.map((v, i) => {
                                  return (
                                    <option key={i} value={v.value}>
                                      {v.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </td>
                            <td>
                              <input
                                className="form-control shadow-none"
                                type="number"
                                name={`empExpert.${i}.expert_time`}
                                ref={register}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setExpert((perv) => {
                                    const newExpert = [...perv];

                                    newExpert[i] = {
                                      ...newExpert[i],
                                      expert_time: newValue,
                                    };
                                    return newExpert;
                                  });
                                }}
                                defaultValue={val.expert_time}
                              />
                            </td>
                            <td>
                              {/* <input
                                className="form-control shadow-none"
                                type="text"
                                name={`empExpert.${i}.expert_train`}
                                ref={register}
                                placeholder="ชื่อหลักสูตรที่เกี่ยวข้อง"
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setExpert((perv) => {
                                    const newExpert = [...perv];

                                    newExpert[i] = {
                                      ...newExpert[i],
                                      expert_train: newValue,
                                    };
                                    return newExpert;
                                  });
                                }}
                                defaultValue={val.expert_train}
                              /> */}
                              <select
                                className="form-control shadow-none"
                                ref={register}
                                name={`empExpert.${i}.expert_train`}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setExpert((perv) => {
                                    const newExpert = [...perv];

                                    newExpert[i] = {
                                      ...newExpert[i],
                                      expert_train: newValue,
                                    };
                                    return newExpert;
                                  });
                                }}
                                defaultValue={val.expert_train}
                                value={val.expert_train}
                              >
                                <option value="">กรุณาเลือก</option>
                                {empCourse.map((v, i) => {
                                  return (
                                    <option key={i} value={v.course}>
                                      {v.course}
                                    </option>
                                  );
                                })}
                              </select>
                            </td>
                            <td align="center">
                              {/* <input
                                className="form-control shadow-none"
                                type="text"
                                name={`empExpert.${i}.expert_work`}
                                ref={register}
                                placeholder="ชื่อผลงานวิจัยที่เกี่ยวข้อง"
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setExpert((perv) => {
                                    const newExpert = [...perv];

                                    newExpert[i] = {
                                      ...newExpert[i],
                                      expert_work: newValue,
                                    };
                                    return newExpert;
                                  });
                                }}
                                defaultValue={val.expert_work}
                              /> */}
                              <Button
                                type="button"
                                variant="contained"
                                size="large"
                                className="btn-no-border btn-color-search"
                                onClick={() =>
                                  handleEditExpertWork(val.expert_id)
                                }
                              >
                                เพิ่ม/แก้ไข
                              </Button>
                            </td>
                            <td>
                              <select
                                className="form-control shadow-none"
                                ref={register}
                                name={`empExpert.${i}.expert_level`}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setExpert((perv) => {
                                    const newExpert = [...perv];

                                    newExpert[i] = {
                                      ...newExpert[i],
                                      expert_level: newValue,
                                    };
                                    return newExpert;
                                  });
                                }}
                                defaultValue={val.expert_level}
                                value={val.expert_level}
                              >
                                <option value="1">
                                  ระดับเริ่มต้น (Novice)
                                </option>
                                <option value="2">
                                  ระดับผู้ก้าวหน้าหรือเรียนรู้ (Advance
                                  Beginner)
                                </option>
                                <option value="3">
                                  ระดับผู้มีความสามารถหรือผู้ปฏิบัติ (Competent)
                                </option>
                                <option value="4">
                                  ระดับผู้ชำนาญ (Proficient)
                                </option>
                                <option value="5">
                                  ระดับผู้เชี่ยวชาญ (Expert)
                                </option>
                              </select>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 pt-3">
              <fieldset className="border p-2">
                <legend className="w-auto text-header">ระดับสมรรถนะ</legend>
                <div className="row">
                  <div className="col-12 col-md-12 pt-3">
                    <table
                      id="data-table"
                      className="table table-striped table-bordered display w-100 margin-bottom-30"
                    >
                      <thead style={{ backgroundColor: "#f3f3f4" }}>
                        <tr>
                          <th className="text-center">องค์ประกอบ</th>
                          <th className="text-center">ระดับ</th>
                        </tr>
                      </thead>

                      <tbody>
                        {masCompetency.map((val, i) => {
                          let competency = dataCompetency.find(
                            (v) => v.competency_id === val.id
                          );

                          return (
                            <tr key={i}>
                              <td>
                                {val.name_th} {val.name_en}
                                <input
                                  hidden
                                  type="text"
                                  name={`empCompetency.${i}.competency_id`}
                                  ref={register}
                                  value={val.id}
                                />
                              </td>
                              <td>
                                <select
                                  className="form-control shadow-none"
                                  ref={register}
                                  name={`empCompetency.${i}.competency_level`}
                                  defaultValue={competency?.competency_level}
                                >
                                  <option value="0">
                                    กรุณาเลือก
                                  </option>
                                  <option value="1">
                                    1) ทำได้สูงกว่ามาตรฐานที่กำหนดมาก
                                  </option>
                                  <option value="2">
                                    2) ทำได้สูงกว่ามาตรฐานที่กำหนด
                                  </option>
                                  <option value="3">3) ทำได้ตามมาตรฐาน</option>
                                  <option value="4">
                                    4) ทำได้ตามมาตรฐานที่กำหนด บางส่วน
                                  </option>
                                  <option value="5">
                                    5) ยังไม่สามารถทำได้ตามมาตรฐานที่กำหนด
                                  </option>
                                </select>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={showModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>เพิ่ม/แก้ไข : ผลงานวิจัย/วิชาการ/ผลงานเด่น</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body className="text-color-form text-center">
            <Container fluid>
              <div className="form-group row text-left">
                <div className="col-12 pt-2">
                  <List subheader={<li />}>
                    {dataExpertWorkTemp?.map((val, i) => {
                      // const labelId = `checkbox-list-label-${val.value}`;
                      return (
                        <div className="row" key={i}>
                          <div className="col-1 pt-3">{i + 1}</div>
                          {/* <FormControlLabel
                            control={
                              <Controller
                                name="mission"
                                render={({ field }) => {
                                  return (
                                    <Checkbox
                                      checked={selectedItems.includes(
                                        val.value
                                      )}
                                      onChange={() =>
                                        handleMissionSelect(val.value)
                                      }
                                      {...field}
                                    />
                                  );
                                }}
                                control={control}
                              />
                            }
                            label={val.label}
                            key={val.value}
                          /> */}
                          <div className="col-11 pt-2">
                            <input
                              className="form-control shadow-none"
                              type="text"
                              name={`empExpert.${i}.expert_train`}
                              ref={register}
                              placeholder="กรุณาระบุที่เกี่ยวข้อง"
                              // onChange={(e) => {
                              //   const newValue = e.target.value;
                              //   setExpert((perv) => {
                              //     const newExpert = [...perv];

                              //     newExpert[i] = {
                              //       ...newExpert[i],
                              //       expert_train: newValue,
                              //     };
                              //     return newExpert;
                              //   });
                              // }}
                              defaultValue={val.expert_work}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </List>
                </div>
                <div className="col-12 pt-2">
                  <div className="text-right">
                    <Button
                      type="button"
                      className="btn-no-border btn-color-search btn btn-contained"
                      onClick={addNewRowExpertWork}
                    >
                      + เพิ่ม
                    </Button>
                  </div>
                </div>
              </div>
            </Container>
            <div className="col-12 pt-1">
              <Button
                type="button"
                variant="contained"
                id="btn-submit"
                className="btn-no-border btn-color-submit-small"
                // onClick={onSubmitMission}
              >
                <i className="far fa-save pr-2" />
                บันทึก
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </div>
  );
};

export default PersonelExpertTab;
