/* eslint-disable */
import React, { useState, useEffect } from "react";
import config from "react-global-configuration";
import { useForm } from "react-hook-form";
import { Container, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import { useKeycloak } from "@react-keycloak/web";
import { WatDatePicker } from "thaidatepicker-react";
import { Button, TextField } from "@material-ui/core";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import th from "date-fns/locale/th";
registerLocale("th", th);
import isRole from "../util/isRole";
import DateInput from "../util/DateInput";
import { useSelector, useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";

import "datatables.mark.js/dist/datatables.mark.es6.min.js";
import "datatables.net-bs4/js/dataTables.bootstrap4.min";
import "datatables.net-responsive/js/dataTables.responsive.min";
import { userId, setUserId } from "../../app/Admin/PersonnelInfoSlice";

import OrgDataDetail from "./OrgDataDetail";

import $ from "jquery";
import { hasAnyRole } from "../util/AuthUtil";

const OrgDataTable = () => {
  let history = useHistory();
  const [keycloak] = useKeycloak();

  const [dataTable, setDataTable] = useState(null);
  const [workbu, setWorkbu] = useState([]);
  const [organize, setOrganize] = useState([]);
  const [parents,setParents] = useState([]);
  const [parent, setParent] = useState([]);
  const [works,setWorks] = useState([]);
  const [work, setWork] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [selectOrg, setSelectOrg] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [onCheangeOrganize, setOnCheangeOrganize] = useState("");
  const [onChangeParent, setOnChangeParent] = useState("");
  const [onChangeWork, setOnChangeWork] = useState("");
  const [onParentId, setOnParentId] = useState("");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const dispatch = useDispatch();

  const handleClose = () => {
    setShowModal(false);
  };

  const initOrhChart = (data) => {
    setSelectOrg({});
    setSelectOrg(data);
    setShowModal(true);
  };

  const initOrhView = (data) => {
    console.log("initOrhDetail", data);

    history.push(
      `/develop-org-data-table-detail?data=${data.orgId}&state=${true}`
    );
    // setSelectOrg({});
    // setSelectOrg(data);
    // setShowModal(true);
  };

  const initOrhDetail = (data,row) => {
    console.log("initOrhDetail", data);
    console.log("initOrhDetailRow", row);
    history.push(
      `/develop-org-data-table-detail?data=${data.orgId}&state=${false}`
    );
    // setSelectOrg({});
    // setSelectOrg(data);
    // setShowModal(true);
  };

  useEffect(() => {
    console.log("onCheangeOrganize", onCheangeOrganize);
    console.log("onChangeParent", onChangeParent);
    console.log("onChangeWork", onChangeWork);
    const chengeData = async () => {
      if (onCheangeOrganize !== "") {
        try {
          let prResult = await axios.get(
            config.get("apiUrl") + "/api/combobox/parent/" + onCheangeOrganize,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `bearer ${keycloak.token}`,
              },
            }
          );
          setParent(prResult.data);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      }else{
        setParent(parents);
      }

      if (onChangeParent !== "") {
        try {
          let workResult = await axios.get(
            config.get("apiUrl") + "/api/combobox/parent/" + onChangeParent,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `bearer ${keycloak.token}`,
              },
            }
          );
          setWork(workResult.data);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      }else{
        setWork(works);
      }
    };

    chengeData();
  }, [onCheangeOrganize, onChangeParent, onChangeWork]);

  useEffect(() => {
    fetchData();
  }, [keycloak.token]);

  const loadDataTableOrgChart = async () => {
    $("#data-table-add-special-organization").DataTable({
      // order: [1, 'asc'],
      searching: false,
      ajax: {
        contentType: "application/json",
        url:
          config.get("apiUrl") +
          "/api/develop/org-chart/datatable/" +
          selectOrg.orgId,
        type: "POST",
        data: function (d) {
          return JSON.stringify(d);
        },
        headers: {
          Authorization: `bearer ${keycloak.token}`,
        },
      },
      processing: true,
      serverSide: true,
      columnDefs: [{ width: "100px" }],
      columns: [
        {
          data: "orgId",
          className: "text-center",
          width: "20%",
        },
        {
          data: "orgName",
          className: "text-left",
          width: "20%",
        },
        {
          data: "depth",
          className: "text-center",
          width: "20%",
        },
      ],
      rowCallback: function (row, data) {},
      createdRow: function (row, data, dataIndex) {
        if (String(data["depth"]) == "0") {
          console.log($(row));
          $(row).css("background-color", "#e84c93");
        }
      },
      lengthChange: true,
      ordering: false,
      info: true,
      responsive: true,
      dom: '<"row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>><"datatable-wrap"t><"row"<"col-sm-12 col-md-5"i><"col-sm-12 col-md-7"p>>',
      language: {
        search: "_INPUT_",
        searchPlaceholder: "ค้นหา...",
        lengthMenu: "_MENU_ รายการ/หน้า",
        sLengthMenu: "_MENU_ รายการ/หน้า",
        sProcessing: "กำลังดำเนินการ...",
        sZeroRecords: "ไม่พบข้อมูล",
        sInfo: "รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว",
        sInfoEmpty: "แสดง 0 ถึง 0 จาก 0 แถว",
        sInfoFiltered: "",
        sSearch: "",
        sUrl: "",
        oPaginate: {
          sFirst: "หน้าแรก",
          sPrevious: "ก่อนหน้า",
          sNext: "ถัดไป",
          sLast: "หน้าสุดท้าย",
        },
      },
    });
  };

  const fetchSearchData = async () => {

    let orgId = [];
    if (onCheangeOrganize != "") {
      orgId.push(onCheangeOrganize);
    }
    if (onChangeParent != "") {
      orgId.push(onChangeParent);
    }
    if (onChangeWork != "") {
      orgId.push(onChangeWork);
    }

    console.log("fetchSearchData", orgId);
    
    $("#data-table").DataTable().clear();
    $("#data-table").DataTable().destroy();

    initDataTable(orgId,orgId);
    
  
  };

  const fetchData = async () => {
    let rsWorkbu = await axios.get(
      config.get("apiUrl") + "/api/master/org/1/1001",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    rsWorkbu.data.data.records.sort((a, b) => (a.name > b.name ? 1 : -1));
    setWorkbu(rsWorkbu.data.data.records);

    let org = await axios.get(
      config.get("apiUrl") + "/api/combobox/organize/1",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    console.log("org", org);
    setOrganize(org.data);
    // setParent(org.data);
    let parnet = await axios.get(
      config.get("apiUrl") + "/api/combobox/organize/2",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setParents(parnet.data);
    setParent(parnet.data);

    let works = await axios.get(
      config.get("apiUrl") + "/api/combobox/organize/3",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setWorks(works.data);
    setWork(works.data);

    if (keycloak.tokenParsed) {
      let rsUser = await axios.get(
        config.get("apiUrl") + "/api/employee/user-login/work-bu1",
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      if (rsUser.data.status === "success") {
        let empOrgId = rsUser.data.data || "";
        setOrgId(empOrgId);

        initDataTable(empOrgId, []);
      }
    }
  };

  const initDataTable = (empOrgId = null, orgId) => {
    $.fn.dataTable.ext.errMode = "none";

    // if(parentId != 0){
    //   console.log("reload data ");
    //   dataTable.reload();
    // }

    empOrgId = empOrgId ? empOrgId : orgId;
    const apiUrl = config.get("apiUrl") + "/api/develop/org/datatable/v2/?parentId=" + orgId +"&orgId=" + orgId;
    console.log("initDataTable",orgId);
    // setDataTable(
      $("#data-table").DataTable({
        order: [1, "asc"],
        ajax: {
          contentType: "application/json",
          url: apiUrl,
          type: "POST",
          data: function (d) {
            return JSON.stringify(d);
          },
          headers: {
            Authorization: `bearer ${keycloak.token}`,
          },
        },
        processing: true,
        serverSide: true,
        columns: [
          {
            width: "120px",
            orderable: true,
            data: "orgId",
            className: "text-center",
            responsivePriority: 1,
          },
          {
            width: "120px",
            orderable: true,
            data: "level",
            className: "text-center",
            render: function (data, type, row) {
              let titleLevel = "";
              switch (data) {
                case 0:
                  titleLevel = "กรม";
                  break;
                case 1:
                  titleLevel = "สำนัก/กอง";
                  break;
                case 2:
                  titleLevel = "กลุ่ม";
                  break;
                case 3:
                  titleLevel = "งาน";
                  break;
                case 4:
                  titleLevel = "หน่วยงานย่อย";
                  break;
              }
              return titleLevel;
            },
          },
          {
            width: "800px",
            orderable: true,
            data: "orgName",
            className: "text-left",
          },
          {
            width: "150px",
            orderable: true,
            data: "nickname",
            className: "text-center",
            render: function (data, type, row) {
              return data == "" || data == null ? "" : "สธ " + data;
            },
          },
          {
            width: "300px",
            orderable: true,
            data: "updateDate",
            className: "text-center",
          },
          {
            width: "100px",
            orderable: false,
            data: "",
            className: "text-center",
            render: function (data, type, row) {
              return `<button class="btn bg-color text-light btn-show" type="button""><i class="far fa-eye"></i></button>`;
            },
          },
          {
            width: "100px",
            orderable: false,
            data: "",
            className: "text-center",
            render: function (data, type, row) {
              return `<button class="btn bg-color text-light btn-detail" type="button""><i class="fas fa-edit"></i></button>`;
            },
          },
        ],
        rowCallback: function (row, data) {
          $("td", row)
            .find(".btn-show")
            .on("click", function (e) {
              initOrhView(data);
            });
          $("td", row)
            .find(".btn-detail")
            .on("click", function (e) {
              initOrhDetail(data,row);
            });
        },
        lengthChange: true,
        ordering: true,
        info: true,
        autoWidth: false,
        responsive: true,
        language: {
          search: "_INPUT_",
          searchPlaceholder: "ค้นหา...",
          lengthMenu: "_MENU_ รายการ/หน้า",
          sLengthMenu: "_MENU_ รายการ/หน้า",
          sProcessing: "กำลังดำเนินการ...",
          sZeroRecords: "ไม่พบข้อมูล",
          sInfo: "รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว",
          sInfoEmpty: "แสดง 0 ถึง 0 จาก 0 แถว",
          sInfoFiltered: "",
          sSearch: "",
          sUrl: "",
          oPaginate: {
            sFirst: "หน้าแรก",
            sPrevious: "ก่อนหน้า",
            sNext: "ถัดไป",
            sLast: "หน้าสุดท้าย",
          },
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
        },
      })

    // );
    

    // Datable Component CSS.
    document
      .querySelector("div.dataTables_length select")
      .classList.add("datatable-length");
    document
      .querySelector("div.dataTables_filter input")
      .classList.add("datatable-search");

    
  };

  // const searchDataTable = () => {
  //     if (dataTable) {
  //         dataTable.ajax.url(config.get('apiUrl') + '/api/v2/employee/datatable').load();
  //     }
  // };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">
                    ผู้ดูแลระบบ
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    ข้อมูลแผนผังหน่วยงาน
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="section-wrapper text-normal">
                  <span className="text-header-x2 ddc-color">ค้นหาข้อมูล</span>
                  <br />
                  <div className="row mt-3">
                    <div className="col-12 col-md-2 pt-2">
                      <span className="text-color-form align-middle">
                        สำนักกอง
                      </span>
                    </div>
                    <div className="col-12 col-md-3 pt-1">
                      <select
                        className="form-control shadow-none"
                        id="organizeLevel"
                        onChange={(e) => {
                          setOnCheangeOrganize(e.target.value);
                        }}
                      >
                        <option value="">--- กรุณาเลือก ---</option>
                        {organize.map((item) => {
                          return (
                            <option key={item.orgId} value={item.orgId}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-md-2 pt-2">
                      <span className="text-color-form align-middle">
                        กลุ่ม
                      </span>
                    </div>
                    <div className="col-12 col-md-3 pt-1">
                      <select
                        className="form-control shadow-none"
                        id="parentLevel"
                        onChange={(e) => {
                          setOnChangeParent(e.target.value);
                        }}
                      >
                        <option value="">--- กรุณาเลือก ---</option>
                        {parent.map((item) => {
                          return (
                            <option key={item.orgId} value={item.orgId}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-md-2 pt-2">
                      <span className="text-color-form align-middle">งาน</span>
                    </div>
                    <div className="col-12 col-md-3 pt-1">
                      <select
                        className="form-control shadow-none"
                        id="workLevel"
                        onChange={(e) => {
                          setOnChangeWork(e.target.value);
                        }}
                      >
                        <option value="">--- กรุณาเลือก ---</option>
                        {work.map((item) => {
                          return (
                            <option key={item.orgId} value={item.orgId}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div>
                    <Button
                      type="button"
                      variant="contained"
                      size="large"
                      className="btn-no-border btn-color-searchv2"
                      onClick={fetchSearchData}
                    >
                      <i
                        className="fas fa-search"
                        style={{ paddingRight: "5px" }}
                      ></i>
                      ค้นหา
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="section-wrapper text-normal">
                  <span className="text-header-x2 ddc-color">
                    ข้อมูลแผนผังหน่วยงาน
                  </span>
                  <br />
                  <br />
                  <br />
                  <table
                    id="data-table"
                    className="table table-striped table-bordered display w-100 margin-bottom-30"
                  >
                    <thead>
                      <tr>
                        <th className="text-center">รหัสหน่วยงาน</th>
                        <th className="text-center">ประเภท</th>
                        <th className="text-center">ชื่อหน่วยงาน</th>
                        <th className="text-center">เลข สธ.</th>
                        <th className="text-center">อัพเดท</th>
                        <th className="text-center">ดำเนินการ</th>
                        <th className="text-center">แก้ไข</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        onEntered={() => {
          loadDataTableOrgChart();
        }}
      >
        <Modal.Header closeButton className="bg-color">
          <Modal.Title className="nav-text text-shadow-header">
            <i className="fas fa-sitemap mr-2"></i>แผนผังหน่วยงาน
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <div className="col-md-12">
                  <div className="card mt-2">
                    <div className="table-header-masexpert card-header text-dark">
                      ลำดับหน่วยงาน
                    </div>
                    <div className="card-body">
                      <table
                        id="data-table-add-special-organization"
                        className="table table-striped table-bordered display w-100 margin-bottom-30"
                      >
                        <thead>
                          <tr>
                            <th className="text-center">รหัสหน่วยงาน</th>
                            <th className="text-center">ชื่อหน่วยงาน</th>
                            <th className="text-center">ลำดับ</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="btn-color-delete-small"
            onClick={handleClose}
          >
            <i className="fas fa-ban pr-2"></i>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OrgDataTable;