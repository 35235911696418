/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { registerLocale } from "react-datepicker";
import th from "date-fns/locale/th";
import { Controller } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  List,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import config from "react-global-configuration";
import DatePicker from "react-datepicker";

registerLocale("th", th);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 400,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
}));

const PersonelEOCTab = ({ register, employee, control, onChange }) => {
  const [keycloak] = useKeycloak();

  const [hazard, setHazard] = useState([]);
  const [employeeMission, setEmployeeMission] = useState([]);
  const [mission, setMission] = useState([]);
  const [misssionTemp, setMisssionTemp] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [situationId, setSituationId] = useState("1");
  const [hazardId, setHazardId] = useState("");

  const [q, setQ] = useState("");

  const [date1, setDate1] = useState(new Date());
  const [date2, setDate2] = useState(new Date());
  const [date3, setDate3] = useState(new Date());
  const [date4, setDate4] = useState(new Date());
  const [date5, setDate5] = useState(new Date());

  useEffect(() => {
    fetchData();
    dateChange();
  }, [keycloak.token]);

  useEffect(() => {
    if (employee.emp_mission) {
      setEmployeeMission(employee.emp_mission);
      setSelectedItems(
        employee.emp_mission
          ? employee.emp_mission.map((val) => {
              return {
                mission_id: val.mission_id.toString(),
                hazard_id: val.hazard_id,
              };
            })
          : []
      );

      onChangeSituation(employee.situation_status);
      onChangeHazard(employee.hazard_id);
    }
  }, [employee]);

  const fetchData = async () => {
    const resMission = await axios.get(
      config.get("apiUrl") + "/api/master/mission",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setMisssionTemp(resMission.data.data);
  };

  const dateChange = async () => {
    $(".datepicker").datepicker({
      todayBtn: false,
      autoclose: true,
      format: "dd/mm/yyyy",
      language: "th",
      thaiyear: true,
    });

    $(".time").datepicker({
      todayBtn: false,
      autoclose: true,
      format: "hh:mm",
      language: "th",
      thaiyear: true,
    });
  };

  const showModalClose = () => {
    if (employeeMission) {
      let select = employeeMission.map((mission) => {
        return {
          mission_id: mission.mission_id.toString(),
          hazard_id: mission.hazard_id,
        };
      });
      setSelectedItems(select);
    }

    setShowModal(false);
  };

  const onSubmitMission = (data) => {
    // console.log(data);
    // console.log(selectedItems);
    let items = selectedItems.map((val) => {
      return { mission_id: val.mission_id, hazard_id: val.hazard_id, duty: 0 };
    });

    setEmployeeMission(items);
    onChange(items);
    setShowModal(false);
  };

  const searchMission = async () => {
    let search = misssionTemp?.records.filter((item) => {
      return item.label.toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
    });

    setMission((prevItems) => ({ ...prevItems, records: search }));
  };

  const onChangeSituation = async (v) => {
    setSituationId(v);
    setHazardId("");
    let rs = await axios.get(
      config.get("apiUrl") + "/api/hazard-mission/get/status/" + v,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setHazard(rs.data.data);
  };

  const onChangeHazard = async (v) => {
    setHazardId(v);
    const resMission = await axios.get(
      config.get("apiUrl") + "/api/hazard-mission/get-detail/hazard/" + v,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setMission(resMission.data.data);
  };

  const handleMissionSelect = (value) => {
    // const isPresent = selectedItems.indexOf(value);
    const isPresent = selectedItems.some(
      (item) => item.mission_id === value.mission_id
    );
    if (isPresent) {
      const remaining = selectedItems.filter(
        (item) => item.mission_id !== value.mission_id
      );
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems) => [...prevItems, value]);
    }
  };

  const classes = useStyles();

  return (
    <div
      className="tab-pane fade pl-3 pr-3"
      id="custom-content-eoc"
      role="tabpanel"
      aria-labelledby="custom-content-eoc-tab"
    >
      <div className="row">
        <div className="col-12 col-md-12 pt-3">
          <div className="row">
            <div className="col-12 col-md-2 pt-3 mt-2">
              <span className="text-color-form align-middle">สถานการณ์</span>
              <span className="text-danger"> *</span>
            </div>
            <div className="col-12 col-md-3 pt-3">
              <select
                className="form-control shadow-none cost_id"
                name="situation_id"
                ref={register}
                value={situationId}
                onChange={(e) => onChangeSituation(e.target.value)}
              >
                <option value={1}>ปกติ</option>
                <option value={2}>ฉุกเฉิน (EOC)</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-2 pt-3 mt-2">
              <span className="text-color-form align-middle">
                โรค/ภัยสุขภาพ
              </span>
              <span className="text-danger"> *</span>
            </div>
            <div className="col-12 col-md-3 pt-3">
              <select
                className="form-control shadow-none cost_id"
                name="hazard_id"
                ref={register}
                value={hazardId}
                onChange={(e) => onChangeHazard(e.target.value)}
              >
                <option value="">กรุณาเลือก</option>
                {hazard.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-2 pt-3 mt-2">
              <span className="text-color-form align-middle">กลุ่มภารกิจ</span>
              <span className="text-danger"> *</span>
            </div>
            <div className="col-12 col-md-3 pt-3">
              <Button
                type="button"
                variant="contained"
                className="btn bg-color btn-lg btn-block font-cschatthai"
                style={{ color: "white" }}
                onClick={() => setShowModal(true)}
                disabled={hazardId == "" || String(situationId) === "1"}
              >
                เลือกกลุ่มภารกิจ
              </Button>
            </div>
          </div>
          <div className="col-12 mt-3">
            <hr style={{ margin: "6px -10px 10px" }} />
          </div>
          {/* <div className="row">
            <div className="col-12 col-md-12 pt-3 mt-2">
              <ul>
                {employeeMission.map((val, i) => {
                  let mission = misssionTemp?.records.find(
                    (v) => v.value == val.mission_id
                  );

                  return <li key={i}>{mission.label}</li>;
                })}
              </ul>
            </div>
          </div> */}
          {employeeMission.length > 0 && (
            <div className="row">
              {hazard.map((item, i) => {
                const empMissionList = employeeMission.filter(
                  (m) => m.hazard_id === item.id
                );

                if (empMissionList.length) {
                  return (
                    <Fragment key={i}>
                      <div className="col-12 col-md-12 mt-2">
                        <span
                          className="text-color-form align-middle"
                          style={{ fontWeight: "bolder" }}
                        >
                          {item.name}
                        </span>
                      </div>
                      <div className="col-12 col-md-12 mt-2">
                        <ul>
                          {empMissionList.map((val, i) => {
                            let mission = misssionTemp?.records.find(
                              (v) => v.value == val.mission_id
                            );

                            return <li key={i}>{mission?.label}</li>;
                          })}
                        </ul>
                      </div>
                    </Fragment>
                  );
                }
              })}
            </div>
          )}
          <div className="col-12 mt-3">
            <hr style={{ margin: "6px -10px 10px" }} />
          </div>
          <div className="row">
            <div className="col-12 col-md-1 pt-3">
              <span className="text-color-form align-middle">
                ลงเวลาเข้างาน
              </span>
            </div>
            <div className="col-12 col-md-1 pt-3">
              <div className="form-control-wrapper form-control-icon-right">
                <input
                  type="text"
                  ref={register}
                  data-date-format="dd/MM/yyyy"
                  className="datepicker form-control shadow-none"
                />

                <i className="far fa-calendar-alt"></i>
              </div>
            </div>
            <div className="col-12 col-md-1 pt-3">
              <span className="text-color-form align-middle">เวลาเข้า</span>
            </div>
            <div className="col-12 col-md-1 pt-3">
              <DatePicker
                className="form-control shadow-none"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="H:mm"
                selected={date1}
                onChange={(date) => setDate1(date)}
              />
            </div>
            <div className="col-12 col-md-1 pt-3">
              <span className="text-color-form align-middle">เวลาพัก</span>
            </div>
            <div className="col-12 col-md-1 pt-3">
              <DatePicker
                className="form-control shadow-none"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="H:mm"
                selected={date2}
                onChange={(date) => setDate2(date)}
              />
            </div>
            <div className="col-12 col-md-1 pt-3">
              <span className="text-color-form align-middle">เวลาเข้าพัก</span>
            </div>
            <div className="col-12 col-md-1 pt-3">
              <DatePicker
                className="form-control shadow-none"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="H:mm"
                selected={date3}
                onChange={(date) => setDate3(date)}
              />
            </div>
            <div className="col-12 col-md-1 pt-3">
              <span className="text-color-form align-middle">เวลาออก</span>
            </div>
            <div className="col-12 col-md-1 pt-3">
              <DatePicker
                className="form-control shadow-none"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="H:mm"
                selected={date4}
                onChange={(date) => setDate4(date)}
              />
            </div>
            <div className="col-12 col-md-1 pt-3">
              <span className="text-color-form align-middle">ชั่วโมงทำงาน</span>
            </div>
            <div className="col-12 col-md-1 pt-3">
              <DatePicker
                className="form-control shadow-none"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="H:mm"
                selected={date5}
                onChange={(date) => setDate5(date)}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={showModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>กลุ่มภารกิจ</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body className="text-color-form text-center">
            <Container fluid>
              <Row>
                <Col>
                  <h6>ทั้งหมด {mission.total_records} กลุ่มภารกิจ</h6>
                </Col>
                <Col>
                  <InputGroup>
                    <Form.Control
                      placeholder="ค้นหา..."
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                    <Button
                      type="button"
                      variant="contained"
                      size="large"
                      className="btn-no-border btn-color-search"
                      onClick={searchMission}
                    >
                      <i className="fas fa-search pr-2"></i>
                      ค้นหา
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <div className="form-group row text-left">
                <div className="col-12 pt-2">
                  <List className={classes.root} subheader={<li />}>
                    {mission?.map((val, i) => {
                      // const labelId = `checkbox-list-label-${val.value}`;
                      return (
                        <li key={i}>
                          <FormControlLabel
                            control={
                              <Controller
                                name="mission"
                                render={({ field }) => {
                                  return (
                                    <Checkbox
                                      checked={selectedItems.some(
                                        (item) => item.mission_id === val.missionId
                                      )}
                                      onChange={() =>
                                        handleMissionSelect({
                                          mission_id: val.missionId,
                                          hazard_id: val.hazardMissionHeaderId,
                                        })
                                      }
                                      {...field}
                                    />
                                  );
                                }}
                                control={control}
                              />
                            }
                            label={val.name_th}
                            key={val.missionId}
                          />
                        </li>
                      );
                    })}
                  </List>
                </div>
              </div>
            </Container>
            <div className="col-12 pt-1">
              <Button
                type="button"
                variant="contained"
                id="btn-submit"
                className="btn-no-border btn-color-submit-small"
                onClick={onSubmitMission}
              >
                <i className="far fa-save pr-2" />
                บันทึก
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </div>
  );
};

export default PersonelEOCTab;
