/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import Switch from "react-switch";
import {
  Button,
  Checkbox,
  FormControlLabel,
  List,
  makeStyles,
} from "@material-ui/core";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import { registerLocale } from "react-datepicker";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import config from "react-global-configuration";
import moment from "moment";
import th from "date-fns/locale/th";
registerLocale("th", th);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 400,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
}));

const HazardMissionForm = () => {
  const [keycloak] = useKeycloak();
  const { register, handleSubmit, setValue, control } = useForm();
  const status = useWatch({ control, name: "active" });
  // const [user, setUser] = useState([]);
  var calendarThai = new Date(
    new Date().getFullYear() + 543,
    new Date().getMonth(),
    new Date().getDate()
  );

  const history = useHistory();
  const id = history.location.state?.id;

  const [startDate, setStartDate] = useState(calendarThai);
  const [endDate, setEndDate] = useState(calendarThai);

  const [listBudget, setListBudget] = useState([]);
  const [mission, setMission] = useState([]);
  const [hazard, setHazard] = useState([]);
  const [hazardId, setHazardId] = useState("");
  const [project, setProject] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [textMsg, setTextMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const handleClose = () => setShowMsg(false);
  const handleShow = () => setShowMsg(true);

  const fetchData = async () => {
    const resHazard = await axios.get(
      config.get("apiUrl") + "/api/master/hazard",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setHazard(resHazard.data.data.records);

    const resMission = await axios.get(
      config.get("apiUrl") + "/api/master/mission",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setMission(resMission.data.data.records);

    const resProject = await axios.get(
      config.get("apiUrl") + "/api/hazard-mission/project/list",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setProject(resProject.data.data);

    if (id) {
      let rsData = await axios.get(
        config.get("apiUrl") + "/api/hazard-mission/detail/" + id,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );

      setHazardId(rsData.data.hazard_id);
      setValue("id", id);
      setValue("name", rsData.data.name);
      setValue(
        "start_date",
        rsData.data.start_date
          ? moment(rsData.data.start_date)
              .add(+543, "year")
              .format("DD/MM/YYYY")
          : null
      );
      setValue(
        "end_date",
        rsData.data.end_date
          ? moment(rsData.data.end_date).add(+543, "year").format("DD/MM/YYYY")
          : null
      );
      setValue("active", rsData.data.active == "1" ? true : false);

      rsData.data?.detail &&
        rsData.data?.detail.map((val) => {
          handleMissionSelect({
            mission_id: val.mission_id.toString(),
            id: parseInt(val.id),
          });
        });

      rsData.data?.project &&
        rsData.data?.project.map((val) => {
          setListBudget((prev) => [
            ...prev,
            {
              id: val.id,
              mainProjectId: val.mainProjectId,
              budgetAll: val.budgetAll,
            },
          ]);
        });
    }
  };

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview("init");
    dateChange();
    // leaveSection();
    fetchData();
  }, [keycloak.token]);

  const dateChange = async () => {
    $(".start_date")
      .datepicker({
        todayBtn: false,
        autoclose: true,
        format: "dd/mm/yyyy",
        language: "th",
        thaiyear: true,
      })
      .datepicker("setDate", new Date())
      .on("changeDate", function (selected) {
        var minDate = new Date(selected.date.valueOf());
        minDate = new Date(
          minDate.getFullYear(),
          minDate.getMonth(),
          minDate.getDate()
        );
        var eDate = new Date($(".end_date").datepicker("getDate"));
        eDate = new Date(
          eDate.getFullYear(),
          eDate.getMonth(),
          eDate.getDate()
        );
        $(".end_date").datepicker("setStartDate", minDate);
        $(".end_date").datepicker("setDate", eDate < minDate ? minDate : eDate);
        setStartDate($(".start_date").val());
        // leaveSection();
      });
    $(".end_date")
      .datepicker({
        todayBtn: false,
        autoclose: true,
        format: "dd/mm/yyyy",
        language: "th",
        thaiyear: true,
      })
      .datepicker("setStartDate", new Date())
      .datepicker("setDate", new Date())
      .on("changeDate", function (selected) {
        setEndDate($(".end_date").val());
        // leaveSection();
      });
  };

  const onSubmit = async (data) => {
    let params = {
      id: parseInt(data.id),
      name: data.name,
      hazard_id: hazardId,
      start_date: moment(data.start_date, "DD/MM/YYYY")
        .add(-543, "year")
        .format("YYYY-MM-DD"),
      end_date: moment(data.end_date, "DD/MM/YYYY")
        .add(-543, "year")
        .format("YYYY-MM-DD"),
      active: data.active ? "1" : "0",
      detail: selectedItems,
      project: listBudget,
    };

    try {
      let rsSave = await axios.post(
        config.get("apiUrl") + "/api/hazard-mission/save",
        params,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      if (rsSave.data.status === "success") {
        handleShow();
        setTextMsg("บันทึกข้อมูลเรียบร้อย");
        history.push("/admin-main-hazard-mission");
      } else {
        handleShow();
        setTextMsg("ไม่สามารถข้อมูลได้");
      }
    } catch {
      handleShow();
      setTextMsg("บันทึกไม่สำเร็จ");
    }
  };

  const hiddenImgInput = React.useRef(null);
  const handleUploadImg = (pic, event) => {
    if (pic === "pic1") {
      hiddenImgInput.current.click();
    }
  };
  const handleUploadProcess = (pic, event) => {
    const fileUploaded = event.target.files[0];
    uploadImgTemp(pic, fileUploaded);
  };
  const [fileUpload, setFileUpload] = useState("");
  const uploadImgTemp = async (pic, data) => {
    let formData = new FormData();
    formData.append("file", data);
    let rsImg = await axios.post(config.get("apiUrl") + "/api/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    });
    if (pic === "pic1") {
      setFileUpload(rsImg.data.data.file_id);
    }
  };

  const handleAddBudget = () => {
    setListBudget((prev) => [
      ...prev,
      {
        id: "",
        mainProjectId: "",
        budgetAll: "",
      },
    ]);
  };

  const handleMissionSelect = (value) => {
    // const isPresent = selectedItems.indexOf(value);
    const isPresent = selectedItems.some(
      (item) => item.mission_id === value.mission_id
    );
    if (isPresent) {
      const remaining = selectedItems.filter(
        (item) => item.mission_id !== value.mission_id
      );
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems) => [...prevItems, value]);
    }
  };

  const classes = useStyles();

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">
                    โรค/ภัยสุขภาพ{" "}
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    เพิ่ม โรค/ภัยสุขภาพ
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <span className="text-header-x2 ddc-color">
                      ข้อมูลโรค/ภัยสุขภาพ
                    </span>
                    <br /> <br />
                    <div className="row mt-3">
                      <div className="col-12 col-md-2 pt-2">
                        <span className="text-color-form align-middle">
                          รหัสโรค/ภัยสุขภาพ
                        </span>
                      </div>
                      <div className="col-12 col-md-2 pt-1">
                        <input
                          className="form-control shadow-none"
                          type="text"
                          name="hazardId"
                          id="hazardId"
                          ref={register}
                          defaultValue={hazardId}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-md-2 pt-2">
                        <span className="text-color-form align-middle">
                          ชื่อโรค/ภัยสุขภาพ
                        </span>
                      </div>
                      <div className="col-12 col-md-10 pt-1">
                        <input
                          type="text"
                          className="form-control shadow-none"
                          name="name"
                          id="name"
                          ref={register}
                        />
                        {/* <select
                          className="form-control shadow-none name"
                          name="name"
                          ref={register}
                        >
                          <option value="">กรุณาเลือก</option>
                          {hazard.map((item, i) => (
                            <option key={i} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select> */}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-md-2 pt-2">
                        <span className="text-color-form align-middle">
                          วันที่เริ่ม
                        </span>
                      </div>
                      <div className="col-8 col-md-2 pt-1">
                        <div className="form-control-wrapper form-control-icon-right">
                          <input
                            type="text"
                            defaultValue={startDate}
                            name="start_date"
                            id="start_date"
                            ref={register}
                            data-date-format="dd/MM/yyyy"
                            className="datepicker start_date form-control shadow-none"
                          />
                          <i className="far fa-calendar-alt"></i>
                        </div>
                      </div>

                      <div className="col-12 col-md-1 pt-2">
                        <span className="text-color-form align-middle">
                          วันที่สิ้นสุด
                        </span>
                      </div>
                      <div className="col-8 col-md-2 pt-1">
                        <div className="form-control-wrapper form-control-icon-right">
                          <input
                            type="text"
                            defaultValue={endDate}
                            name="end_date"
                            id="end_date"
                            ref={register}
                            data-date-format="dd/MM/yyyy"
                            className="datepicker end_date form-control shadow-none"
                          />
                          <i className="far fa-calendar-alt"></i>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-md-2 pt-2">
                        <span className="text-color-form align-middle">
                          กลุ่มภารกิจ
                        </span>
                      </div>
                      <div className="col-12 col-md-10 pt-1">
                        <List className={classes.root} subheader={<li />}>
                          {mission?.map((val, i) => {
                            // const labelId = `checkbox-list-label-${val.value}`;
                            return (
                              <li key={i}>
                                <FormControlLabel
                                  control={
                                    <Controller
                                      name="mission"
                                      render={({ field }) => {
                                        return (
                                          <Checkbox
                                            checked={selectedItems.some(
                                              (item) =>
                                                item.mission_id === val.value
                                            )}
                                            onChange={() =>
                                              handleMissionSelect({
                                                mission_id: val.value,
                                                id: null,
                                              })
                                            }
                                            {...field}
                                          />
                                        );
                                      }}
                                      control={control}
                                    />
                                  }
                                  label={val.label}
                                  key={val.value}
                                />
                              </li>
                            );
                          })}
                        </List>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-2 pt-2">
                        <span className="text-color-form align-middle">
                          แนบคำสั่ง
                        </span>
                      </div>
                      <div className="col-12 col-sm-1 col-md-2 pt-3 mt-1">
                        <label>
                          <input
                            type="file"
                            ref={hiddenImgInput}
                            onChange={(e) => handleUploadProcess("pic1", e)}
                            style={{ display: "none" }}
                          />
                          <Button
                            variant="contained"
                            onClick={(e) => handleUploadImg("pic1", e)}
                            className="btn-no-border btn-color-search"
                          >
                            <i className="fas fa-paperclip pr-2"></i> แนบไฟล์
                          </Button>
                        </label>
                      </div>
                      <div className="col-12 col-sm-1 col-md-2 pt-3 mt-3">
                        {fileUpload !== "" && (
                          <a
                            href={
                              config.get("apiUrl") + "/api/file/" + fileUpload
                            }
                            target="_blank"
                          >
                            ดูไฟล์แนบ
                          </a>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-2 pt-2">
                        <span className="text-color-form align-middle">
                          สถานะ
                        </span>
                      </div>
                      <div className="col-12 col-sm-1 pt-1">
                        <Switch
                          name="active"
                          id="active"
                          onChange={(e) => setValue("active", e)}
                          checked={status}
                          ref={register}
                          className="react-switch"
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <span className="text-header-x2 ddc-color">งบประมาณ</span>
                    <br /> <br />
                    <div className="row">
                      <div className="col-12 col-md-12 pt-3">
                        <div className="text-right">
                          <Button
                            type="button"
                            className="btn-no-border btn-color-search btn btn-contained"
                            onClick={handleAddBudget}
                          >
                            + เพิ่ม
                          </Button>
                        </div>

                        <table
                          id="data-table"
                          className="table table-striped table-bordered display w-100 margin-bottom-30"
                        >
                          <thead style={{ backgroundColor: "#f3f3f4" }}>
                            <tr>
                              <th className="text-center">ลำดับ</th>
                              <th className="text-center">โครงการ</th>
                              <th className="text-center">
                                งบจัดสรรโครงการ (บาท)
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {listBudget.map((val, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                  {/* <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name={`budget.${i}.project`}
                                    ref={register}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      setListBudget((perv) => {
                                        const newCourse = [...perv];

                                        newCourse[i] = {
                                          ...newCourse[i],
                                          mainProjectId: newValue,
                                        };
                                        return newCourse;
                                      });
                                    }}
                                    defaultValue={val.mainProjectId}
                                  /> */}
                                  <select
                                    className="form-control shadow-none"
                                    name={`budget.${i}.project`}
                                    ref={register}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      setListBudget((perv) => {
                                        const newCourse = [...perv];

                                        newCourse[i] = {
                                          ...newCourse[i],
                                          mainProjectId: newValue,
                                          budgetAll: project.find(
                                            (val) =>
                                              val.mainProjectId === newValue
                                          ).planBudget,
                                        };
                                        return newCourse;
                                      });
                                    }}
                                    value={val.mainProjectId}
                                  >
                                    <option value="">กรุณาเลือก</option>
                                    {project?.map((item, i) => (
                                      <option
                                        key={i}
                                        value={item.mainProjectId}
                                      >
                                        {item.projectName}
                                      </option>
                                    ))}
                                  </select>
                                </td>

                                <td>
                                  <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name={`budget.${i}.budget`}
                                    ref={register}
                                    // onChange={(e) => {
                                    //   const newValue = e.target.value;
                                    //   setListBudget((perv) => {
                                    //     const newCourse = [...perv];

                                    //     newCourse[i] = {
                                    //       ...newCourse[i],
                                    //       budgetAll: newValue,
                                    //     };
                                    //     return newCourse;
                                    //   });
                                    // }}
                                    defaultValue={val.budgetAll}
                                    value={val.budgetAll}
                                    disabled
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-2 pt-3">
                        <Button
                          type="button"
                          onClick={handleSubmit(onSubmit)}
                          variant="contained"
                          className="btn-no-border btn-color-submit"
                        >
                          <i className="far fa-save pr-2"></i>
                          บันทึก
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>

      <Modal show={showMsg} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">
          {textMsg}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HazardMissionForm;
