/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import Switch from "react-switch";
import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { registerLocale } from "react-datepicker";
import th from "date-fns/locale/th";
import config from "react-global-configuration";
import { useKeycloak } from "@react-keycloak/web";
import $ from "jquery";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import DateInput from "../util/DateInput";
import moment from "moment";
import { useHistory } from "react-router-dom";

registerLocale("th", th);

const MainMasMission = () => {
  const [keycloak] = useKeycloak();
  let history = useHistory();

  const isRole = useCallback(
    (roles) => {
      if (keycloak && roles) {
        return roles.some((r) => {
          const realm = keycloak.hasRealmRole(r);
          const resource = keycloak.hasResourceRole(r);
          return realm || resource;
        });
      }
      return false;
    },
    [keycloak]
  );

  // const { register, handleSubmit, setValue, getValues } = useForm();

  const [dataTable, setDataTable] = useState(null);
  // const [showModal, setShowModal] = useState(false);

  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  const [mission, setMission] = useState([]);

  const [misId, setMisId] = useState("");

  // const handleShow = () => setShowModal(true);

  const [showMsg, setShowMsg] = useState(false);
  const alertModalCls = () => setShowMsg(false);
  const alertModalOn = () => setShowMsg(true);
  const [textMsg, setTextMsg] = useState("");

  // const [activeSarabun, setActiveSarabun] = useState(true);

  // const handleChangeActive = (nextActive) => {
  //   setActiveSarabun(nextActive);
  //   setValue("active", nextActive);
  // };

  useEffect(() => {
    fetchData();
  }, [keycloak.token]);

  const fetchData = async () => {
    const resMission = await axios.get(
      config.get("apiUrl") + "/api/master/mission",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setMission(resMission.data.data.records);

    initDataTable();
  };

  //add
  const addUser = async () => {
    // handleShow();
    let path = `/admin-manage-mas-mission`;
    history.push({ pathname: path });
  };

  const manageMission = (id) => {
    let path = `/admin-manage-mas-mission`;
    history.push({ pathname: path, state: { id: id } });
  };

  //showEdit
  // const getDetail = async (data) => {
  //   if (!(await isRole(["admin"]))) {
  //     alertModalOn();
  //     setTextMsg("ไม่สามารถทำรายการได้");
  //     return;
  //   }

  //   if (data !== "") {
  //     let rsData = await axios.get(
  //       config.get("apiUrl") + "/api/master/mission/detail/" + data,
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           Authorization: `bearer ${keycloak.token}`,
  //         },
  //       }
  //     );

  //     setValue("id", rsData.data.masMisId);
  //     setValue("name", rsData.data.masMisName);
  //     startDateChange(rsData.data.startDate);
  //     endDateChange(rsData.data.endDate);
  //     setActiveSarabun(rsData.data.masMisActive == "1" ? true : false);
  //     setValue("active", rsData.data.masMisActive == "1" ? true : false);

  //     handleShow();
  //   }
  // };

  // const startDateChange = (date) => {
  //   setStartDate(date);
  //   setValue("startDate", date);
  //   if (date > endDate) {
  //     setEndDate(date);
  //     setValue("endDate", date);
  //   }
  // };

  // const endDateChange = (date) => {
  //   setEndDate(date);
  //   setValue("endDate", date);
  //   if (date < startDate) {
  //     setStartDate(date);
  //     setValue("startDate", date);
  //   }
  // };

  // const handleClose = () => {
  //   setShowModal(false);

  //   setValue("id", "");
  //   setValue("name", "");
  //   setValue("startDate", new Date());
  //   setStartDate(new Date());
  //   setValue("endDate", new Date());
  //   setEndDate(new Date());
  //   setValue("status", true);
  //   setActiveSarabun(true);
  // };

  // const onSubmit = async (data) => {
  //   let params = {
  //     mission_id: parseInt(data.id),
  //     name: data.name,
  //     start_date: moment(data.startDate).format("YYYY-MM-DD"),
  //     end_date: moment(data.endDate).format("YYYY-MM-DD"),
  //     active: activeSarabun ? "1" : "0",
  //   };

  //   // if (params.parent_id === "" || params.name === "") {
  //   //   alertModalOn();
  //   //   setTextMsg("กรอกข้อมูลให้ครบ");
  //   // } else {
  //   let rsSave = await axios.post(
  //     config.get("apiUrl") + "/api/master/mission/save",
  //     params,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `bearer ${keycloak.token}`,
  //       },
  //     }
  //   );
  //   if (rsSave.data.status === "success") {
  //     alertModalOn();
  //     setTextMsg("บันทึกข้อมูลเรียบร้อย");
  //     alertModalCls();
  //     handleClose();
  //     dataTable.ajax.reload();
  //   }
  // };

  const searchDataTable = () => {
    if (dataTable) {
      dataTable.ajax
        .url(
          config.get("apiUrl") +
            "/api/master/mission/data/table?" +
            "misId=" +
            misId
        )
        .load();
    }
  };

  const initDataTable = () => {
    $.fn.dataTable.ext.errMode = "none";
    setDataTable(
      $("#data-table").DataTable({
        order: [0, "asc"],
        ajax: {
          contentType: "application/json",
          url:
            config.get("apiUrl") +
            "/api/master/mission/data/table?" +
            "misId=ALL",
          type: "POST",
          data: function (d) {
            return JSON.stringify(d);
          },
          headers: {
            Authorization: `bearer ${keycloak.token}`,
          },
        },
        processing: true,
        serverSide: true,
        columns: [
          {
            width: "100px",
            orderable: true,
            data: "misId",
            className: "text-center",
            responsivePriority: 1,
          },
          {
            width: "150px",
            orderable: true,
            data: "name",
            className: "text-left",
          },
          {
            width: "100px",
            orderable: false,
            data: "startDate",
            className: "text-center",
            render: function (data, type, row) {
              if (data != null) {
                return moment(data, "YYYY-MM-DD")
                  .add(+543, "year")
                  .format("DD/MM/YYYY");
              } else {
                return `-`;
              }
            },
          },
          {
            width: "100px",
            orderable: false,
            data: "endDate",
            className: "text-center",
            render: function (data, type, row) {
              if (data != null) {
                return moment(data, "YYYY-MM-DD")
                  .add(+543, "year")
                  .format("DD/MM/YYYY");
              } else {
                return `-`;
              }
            },
          },
          {
            width: "100px",
            orderable: false,
            data: "active",
            className: "text-center",
            render: function (data, type, row) {
              if (data == "1") {
                return `ใช้งาน`;
              } else {
                return `ไม่ใช้งาน`;
              }
            },
          },
          {
            width: "100px",
            orderable: false,
            data: "",
            className: "text-center",
            render: function (data, type, row) {
              if (isRole(["admin"])) {
                return `<i class="fa fa-edit mr-2 edit-mission" style="cursor: pointer;"></i>`;
              }
              return `-`;
            },
          },
        ],
        rowCallback: function (row, data) {
          $("td", row)
            .find(".edit-mission")
            .on("click", function (e) {
              manageMission(data.misId);
            });
        },
        lengthChange: true,
        ordering: true,
        info: true,
        autoWidth: false,
        responsive: true,
        language: {
          search: "_INPUT_",
          searchPlaceholder: "ค้นหา...",
          lengthMenu: "_MENU_ รายการ/หน้า",
          sLengthMenu: "_MENU_ รายการ/หน้า",
          sProcessing: "กำลังดำเนินการ...",
          sZeroRecords: "ไม่พบข้อมูล",
          sInfo: "รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว",
          sInfoEmpty: "แสดง 0 ถึง 0 จาก 0 แถว",
          sInfoFiltered: "",
          sSearch: "",
          sUrl: "",
          oPaginate: {
            sFirst: "หน้าแรก",
            sPrevious: "ก่อนหน้า",
            sNext: "ถัดไป",
            sLast: "หน้าสุดท้าย",
          },
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
        },
      })
    );

    // Datable Component CSS.
    document
      .querySelector("div.dataTables_length select")
      .classList.add("datatable-length");
    document
      .querySelector("div.dataTables_filter input")
      .classList.add("datatable-search");
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding" />
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">
                    ผู้ดูแลระบบ
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    กลุ่มภารกิจ
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="col-md-12 page-header-wrapper">
                          <span className="left-border"></span>
                          <span className="page-header-text">กลุ่มภารกิจ</span>
                        </div>
                        <br /> <br />
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              กลุ่มภารกิจ
                            </span>
                          </div>
                          <div className="col-12 col-md-6 pt-1">
                            <select
                              className="form-control shadow-none"
                              name="mission"
                              value={misId}
                              onChange={(e) => setMisId(e.target.value)}
                            >
                              <option value="">กรุณาเลือก</option>
                              {mission.map((item, i) => (
                                <option key={i} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 mt-3 ml-3">
                          <Button
                            type="button"
                            variant="contained"
                            className="btn-no-border btn-color-search"
                            onClick={searchDataTable}
                          >
                            <i className="fas fa-search pr-2"></i>
                            ค้นหา
                          </Button>
                          <Button
                            type="button"
                            variant="contained"
                            className="btn-no-border btn-color-search bg-color ml-3"
                            onClick={addUser}
                            // disabled={IsRole(["admin"]) ? false : true}
                          >
                            <i className="fa fa-plus" />
                            &nbsp;&nbsp;เพิ่มกลุ่มภารกิจ
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="section-wrapper text-normal">
                  <table
                    id="data-table"
                    className="table table-striped table-bordered display w-100 margin-bottom-30"
                  >
                    <thead>
                      <tr>
                        <th className="text-center">รหัสกลุ่มภารกิจ</th>
                        <th className="text-center">ชื่อกลุ่มภารกิจ</th>
                        <th className="text-center">วันที่เริ่มต้น</th>
                        <th className="text-center">วันที่สิ้นสุด</th>
                        <th className="text-center">สถานะ</th>
                        <th className="text-center">แก้ไข</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>

      {/* <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ข้อมูลกลุ่มภารกิจ</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body className="text-color-form text-center">
            <Container fluid>
              <div className="form-group row">
                <div className="col-12 col-md-4 pt-2">
                  <span className="text-color-form align-middle">
                    รหัสกลุ่มภารกิจ
                  </span>
                </div>
                <div className="col-12 col-md-4 pt-1">
                  <input
                    className="form-control shadow-none"
                    type="text"
                    name="id"
                    // onChange={(e) => setName(e.target.value)}
                    // value={misId}
                    ref={register}
                    readOnly
                  />
                </div>
                <div className="col-md-4 pt-1"></div>
                <div className="col-12 col-md-4 pt-2">
                  <span className="text-color-form align-middle">
                    ชื่อกลุ่มภารกิจ
                  </span>
                  <span className="text-danger"> *</span>
                </div>
                <div className="col-12 col-md-8 pt-1">
                  <input
                    className="form-control shadow-none"
                    type="text"
                    name="name"
                    // onChange={(e) => setNickname(e.target.value)}
                    // value={nickname}
                    ref={register({ required: true })}
                  />
                </div>
                <div className="col-12 col-md-4 pt-3">
                  <span className="text-color-form align-middle">
                    วันที่เริ่มต้น
                  </span>
                </div>
                <div className="col-12 col-md-3 pt-3">
                  <DateInput
                    value={startDate}
                    onChange={(date) => startDateChange(date)}
                    label="วันที่เริ่มต้น"
                  />
                </div>
                <div className="col-12 col-md-2 pt-3">
                  <span className="text-color-form align-middle">
                    วันที่สิ้นสุด
                  </span>
                </div>
                <div className="col-12 col-md-3 pt-3">
                  <DateInput
                    value={endDate}
                    onChange={(date) => endDateChange(date)}
                    label="วันที่สิ้นสุด"
                  />
                </div>
                <div className="col-12 col-md-4 pt-2">
                  <span className="text-color-form align-middle">สถานะ</span>
                </div>
                <div className="col-12 col-sm-1 pt-1">
                  <Switch
                    name="status"
                    onChange={handleChangeActive}
                    checked={activeSarabun}
                    ref={register}
                    className="react-switch"
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </div>
              </div>
            </Container>

            <div className="col-12 col-md-3 pt-1">
              <Button
                type="submit"
                variant="contained"
                id="btn-submit"
                className="btn-no-border btn-color-submit-small btn-block"
              >
                <i className="far fa-save pr-2" />
                บันทึก
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal> */}

      <Modal show={showMsg} onHide={alertModalCls}>
        <Modal.Header closeButton>
          <Modal.Title />
        </Modal.Header>
        <Modal.Body className="text-color-form text-center">
          {textMsg}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MainMasMission;
