import axios from "axios";
import config from "react-global-configuration";

export const getToken = async ({ token }) => {
  let body = {
    username: config.get("face48User"),
    password: config.get("face48Pass"),
  };

  let rs = await axios.post(
    config.get("apiUrl") + "/api/face48/authenticate",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    }
  );
  if (rs.data.success) {
    return rs.data.access_token;
  } else {
    console.error(`Face48 API | getToken error: ${rs.data.desc}`);
    return null;
  }
};

export const getPeopleId = async ({ employeeId, keycloak }) => {
  let rs = await axios.get(
    config.get("apiUrl") +
      "/api/face48/people/search" +
      "?companyId=" +
      config.get("face48CompanyId") +
      "&allCompany=false" +
      "&employeeId=" +
      employeeId,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${keycloak.token}`,
      },
    }
  );

  if (rs.data.code === 200) {
    console.log(rs.data.data[0]);
    return rs.data.data[0];
  } else {
    console.error(`Face48 API | getPeopleId error: ${rs.desc}`);
    return null;
  }
};

export const mainSaveEmployee = async ({
  peopleId,
  face48People,
  keycloak,
  face48PeopleId,
}) => {
  if (face48PeopleId === null || face48PeopleId === "") {
    return saveEmployee({
      employeeId: peopleId,
      face48People: face48People,
      keycloak: keycloak,
    });
  } else {
    return updateEmployee({
      peopleId: face48PeopleId,
      face48People: face48People,
      keycloak: keycloak,
    });
  }
};

export const saveEmployee = async ({ employeeId, face48People, keycloak }) => {
  // let body = {
  //   _type: "employee",
  //   employeeId: employeeId,
  //   firstName: firstName,
  //   lastName: lastName,
  //   company: config.get("face48CompanyId"),
  //   accessControlRuleIds: [config.get("face48AccessControlRoleId")],
  // };

  let rs = await axios.post(
    config.get("apiUrl") + "/api/face48/people",
    face48People,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${keycloak.token}`,
      },
    }
  );

  if (rs.data.code === 200) {
    return rs.data.data;
  } else {
    console.error(`Face48 API | saveEmployee error: ${rs.data.desc}`);
    return null;
  }
};

export const updateEmployee = async ({ peopleId, face48People, keycloak }) => {
  let rs = await axios.post(
    config.get("apiUrl") + "/api/face48/people/" + peopleId,
    face48People,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${keycloak.token}`,
      },
    }
  );

  if (rs.data.code === 200) {
    return rs.data.data;
  } else {
    console.error(`Face48 API | saveEmployee error: ${rs.data.desc}`);
    return null;
  }
};

export const deleteEmployee = async ({ peopleId, keycloak }) => {
  let rs = await axios.post(
    config.get("apiUrl") + "/api/face48/people/delete/" + peopleId,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${keycloak.token}`,
      },
    }
  );

  if (rs.data.code === 200) {
    console.log(`Face48 API | deleteEmployee: ${rs.data.desc}`);
  } else {
    console.error(`Face48 API | deleteEmployee error: ${rs.data.desc}`);
  }
};

export const deletePictureEmployee = async ({ peopleId, keycloak }) => {
  let rs = await axios.post(
    config.get("apiUrl") + "/api/face48/delete/" + peopleId,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${keycloak.token}`,
      },
    }
  );

  if (rs.data.code === 200) {
    console.log(`Face48 API | deleteEmployee: ${rs.data.desc}`);
  } else {
    console.error(`Face48 API | deleteEmployee error: ${rs.data.desc}`);
  }
};

export const uploadPhotoEmployee = async ({ peopleId, file, keycloak }) => {
  let formData = new FormData();
  formData.append("photo", file);

  let rs = await axios.post(
    config.get("apiUrl") + `/api/face48/people/photo/${peopleId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    }
  );

  if (rs.data.code === 200) {
    console.log(`Face48 API | uploadPhotoEmployee: ${rs.data.desc}`);
  } else {
    console.error(`Face48 API | uploadPhotoEmployee error: ${rs.data.desc}`);
  }
};

export const uploadAvatarEmployee = async ({ peopleId, file, keycloak }) => {
  let formData = new FormData();
  formData.append("avatar", file);

  let rs = await axios.post(
    config.get("apiUrl") + `/api/face48/people/avatar/${peopleId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    }
  );

  if (rs.data.code === 200) {
    console.log(`Face48 API | uploadAvatarEmployee: ${rs.data.desc}`);
  } else {
    console.error(`Face48 API | uploadAvatarEmployee error: ${rs.data.desc}`);
  }
};

export const checkQualityPhoto = async ({ file, keycloak }) => {
  let formData = new FormData();
  formData.append("image", file);
  return await axios.post(
    config.get("apiUrl") + `/api/face48/check-photo-quality`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    }
  );
};

export const checkQualityPhotoFace = async ({ file, keycloak }) => {
  let formData = new FormData();
  formData.append("image", file);
  return await axios.post(
    config.get("apiUrl") + `/api/face48/new/check-photo-quality`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak.token}`,
      },
    }
  );
};

export const getJob = async ({ keycloak }) => {
  let rs = await axios.get(config.get("apiUrl") + `/api/face48/job-position`, {
    headers: {
      Authorization: `bearer ${keycloak.token}`,
    },
  });

  if (rs.data.code === 200) {
    return rs.data.data;
  } else {
    console.error(`Face48 API | getTitle error: ${rs.data.desc}`);
    return null;
  }
};

export const createJob = async ({ jobPosition, keycloak }) => {
  let body = {
    name: jobPosition,
  };

  let rs = await axios.post(
    config.get("apiUrl") + `/api/face48/job-position`,
    body,
    {
      headers: {
        Authorization: `bearer ${keycloak.token}`,
      },
    }
  );

  if (rs.data.code === 200) {
    return rs.data.data.id;
  } else {
    console.error(`Face48 API | getTitle error: ${rs.data.desc}`);
    return null;
  }
};

export const getTitle = async ({ keycloak }) => {
  console.log(keycloak);
  let rs = await axios.get(config.get("apiUrl") + `/api/face48/title`, {
    headers: {
      Authorization: `bearer ${keycloak.token}`,
    },
  });

  if (rs.data.code === 200) {
    return rs.data.data;
  } else {
    console.error(`Face48 API | getTitle error: ${rs.data.desc}`);
    return null;
  }
};

export const createTitle = async ({ titleEmployee, keycloak }) => {
  let body = {
    name: titleEmployee,
  };

  let rs = await axios.post(config.get("apiUrl") + `/api/face48/title`, body, {
    headers: {
      Authorization: `bearer ${keycloak.token}`,
    },
  });

  if (rs.data.code === 200) {
    return rs.data.data.id;
  } else {
    console.error(`Face48 API | getTitle error: ${rs.data.desc}`);
    return null;
  }
};
