/* eslint-disable */
import React, { useState, useEffect } from "react";
import config from "react-global-configuration";
import { useForm } from "react-hook-form";
import { Container, Modal } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import { useKeycloak } from "@react-keycloak/web";
import { WatDatePicker } from "thaidatepicker-react";
import { Button, TextField } from "@material-ui/core";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import th from "date-fns/locale/th";
registerLocale("th", th);
import isRole from "../util/isRole";
import DateInput from "../util/DateInput";
import { useSelector, useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";

import "datatables.mark.js/dist/datatables.mark.es6.min.js";
import "datatables.net-bs4/js/dataTables.bootstrap4.min";
import "datatables.net-responsive/js/dataTables.responsive.min";
import { userId, setUserId } from "../../app/Admin/PersonnelInfoSlice";

import $ from "jquery";
import { hasAnyRole } from "../util/AuthUtil";

const OrgDataDetail = () => {
  let history = useHistory();
  const [keycloak] = useKeycloak();

  const [dataTable, setDataTable] = useState(null);
  const [textMsg, setTextMsg] = useState("");
  const [workbu, setWorkbu] = useState([]);
  const [parent, setParent] = useState([]);
  const [work, setWork] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [stateParent, setStateParent] = useState();
  const [selectOrg, setSelectOrg] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalChange, setShowModalChange] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [organizelv1, setOrganizeLv1] = useState([]);
  const [organizelv2, setOrganizeLv2] = useState([]);
  const [onCheangeOrganize, setOnCheangeOrganize] = useState("");
  const [onChangeParent, setOnChangeParent] = useState("");
  const [sathCode, setSathCode] = useState("");
  const [remark, setRemark] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dataLocation = searchParams.get("data");
  const stateOpen = searchParams.get("state");
  const parentId = searchParams.get("parent");
  const level = searchParams.get("level");
  console.log("dataLocation", JSON.stringify(dataLocation, null, 2));
  console.log("stateOpen", JSON.stringify(stateOpen, null, 2));
  console.log("parentId", JSON.stringify(parentId, null, 2));
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const dispatch = useDispatch();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleCloseChange = () => {
    setShowModalChange(false);
  };

  const handleSave = async () => {
    console.log({ dataLocation, sathCode, remark });
    const params = {
      orgId: dataLocation,
      sathCode: sathCode,
      remark: remark,
    };
    let rsUpdateUser = await axios.post(
      config.get("apiUrl") + "/api/develop/org/code",
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (rsUpdateUser.status == 200) {
      $("#data-table").DataTable().clear();
      $("#data-table").DataTable().destroy();
      handleClose();

      initDataTable();
    }
    // console.log(rsUpdateUser.status ==200);
  };

  const initChange = () => {
    history.goBack();
  };

  const initOrhChart = () => {
    setSelectOrg({});
    setShowModal(true);
  };

  useEffect(() => {
    fetchData();
  }, [keycloak.token]);

  const loadDataTableOrgChart = async () => {
    $("#data-table-add-special-organization").DataTable({
      // order: [1, 'asc'],
      searching: false,
      ajax: {
        contentType: "application/json",
        url:
          config.get("apiUrl") +
          "/api/develop/org-chart/datatable/" +
          selectOrg.orgId,
        type: "POST",
        data: function (d) {
          return JSON.stringify(d);
        },
        headers: {
          Authorization: `bearer ${keycloak.token}`,
        },
      },
      processing: true,
      serverSide: true,
      columnDefs: [{ width: "100px" }],
      columns: [
        {
          data: "orgId",
          className: "text-center",
          width: "20%",
        },
        {
          data: "orgName",
          className: "text-left",
          width: "20%",
        },
        {
          data: "depth",
          className: "text-center",
          width: "20%",
        },
      ],
      rowCallback: function (row, data) {},
      createdRow: function (row, data, dataIndex) {
        if (String(data["depth"]) == "0") {
          $(row).css("background-color", "#e84c93");
        }
      },
      lengthChange: true,
      ordering: false,
      info: true,
      responsive: true,
      dom: '<"row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>><"datatable-wrap"t><"row"<"col-sm-12 col-md-5"i><"col-sm-12 col-md-7"p>>',
      language: {
        search: "_INPUT_",
        searchPlaceholder: "ค้นหา...",
        lengthMenu: "_MENU_ รายการ/หน้า",
        sLengthMenu: "_MENU_ รายการ/หน้า",
        sProcessing: "กำลังดำเนินการ...",
        sZeroRecords: "ไม่พบข้อมูล",
        sInfo: "รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว",
        sInfoEmpty: "แสดง 0 ถึง 0 จาก 0 แถว",
        sInfoFiltered: "",
        sSearch: "",
        sUrl: "",
        oPaginate: {
          sFirst: "หน้าแรก",
          sPrevious: "ก่อนหน้า",
          sNext: "ถัดไป",
          sLast: "หน้าสุดท้าย",
        },
      },
    });
  };

  const fetchData = async () => {
    let rsWorkbu = await axios.get(
      config.get("apiUrl") + "/api/master/org/1/1001",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    rsWorkbu.data.data.records.sort((a, b) => (a.name > b.name ? 1 : -1));
    setWorkbu(rsWorkbu.data.data.records);

    let org = await axios.get(
      config.get("apiUrl") + "/api/combobox/organize/1",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setOrganizeLv1(org.data);

    let org2 = await axios.get(
      config.get("apiUrl") + "/api/combobox/organize/2",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    setOrganizeLv2(org.data);

    let viewData = await axios.get(
      config.get("apiUrl") + "/api/develop/org/view/" + dataLocation,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${keycloak.token}`,
        },
      }
    );
    if (viewData.status == 200) {
      setSathCode(viewData.data.sathCode);
      setRemark(viewData.data.remark);
    }
    console.log("viewData", viewData);

    if (keycloak.tokenParsed) {
      let rsUser = await axios.get(
        config.get("apiUrl") + "/api/employee/user-login/work-bu1",
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `bearer ${keycloak.token}`,
          },
        }
      );
      if (rsUser.data.status === "success") {
        let empOrgId = rsUser.data.data || "";
        setOrgId(empOrgId);

        initDataTable(empOrgId);
      }
    }
  };

  const initDataTable = (empOrgId = null) => {
    $.fn.dataTable.ext.errMode = "none";

    empOrgId = empOrgId ? empOrgId : orgId;

    setDataTable(
      $("#data-table").DataTable({
        order: [1, "asc"],
        ajax: {
          contentType: "application/json",
          url:
            config.get("apiUrl") +
            "/api/develop/org/datatable/detail?orgId=" +
            dataLocation,
          type: "POST",
          data: function (d) {
            return JSON.stringify(d);
          },
          headers: {
            Authorization: `bearer ${keycloak.token}`,
          },
        },
        processing: true,
        serverSide: true,
        columns: [
          {
            width: "120px",
            orderable: true,
            data: "orgId",
            className: "text-center",
            responsivePriority: 1,
          },
          {
            width: "800px",
            orderable: true,
            data: "orgName",
            className: "text-left",
          },
          {
            width: "150px",
            orderable: true,
            data: "nickname",
            className: "text-center",
            render: function (data, type, row) {
              console.log(data);
              return data == "" || data == null ? "" : "สธ " + data;
            },
          },
          {
            width: "300px",
            orderable: true,
            data: "updateDate",
            className: "text-center",
          },
          {
            width: "100px",
            orderable: false,
            data: "annotation",
            className: "text-center",
            render: function (data, type, row) {
              return data == null ? " - " : data;
              // return `<button class="btn bg-color text-light btn-show" type="button""><i class="far fa-eye mr-2"></i></button>`;
            },
          },
          {
            width: "130px",
            orderable: false,
            data: "source",
            className: "text-center",
            render: function (data, type, row) {
              return data == 1 ? "กำลังใช้งาน" : "ไม่ใช้แล้ว";
              // return `<button class="btn bg-color text-light btn-show" type="button""><i class="far fa-eye mr-2"></i></button>`;
            },
          },
        ],
        rowCallback: function (row, data) {
          $("td", row)
            .find(".btn-show")
            .on("click", function (e) {
              initOrhChart(data);
            });
        },
        lengthChange: true,
        ordering: true,
        info: true,
        autoWidth: false,
        responsive: true,
        language: {
          search: "_INPUT_",
          searchPlaceholder: "ค้นหา...",
          lengthMenu: "_MENU_ รายการ/หน้า",
          sLengthMenu: "_MENU_ รายการ/หน้า",
          sProcessing: "กำลังดำเนินการ...",
          sZeroRecords: "ไม่พบข้อมูล",
          sInfo: "รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว",
          sInfoEmpty: "แสดง 0 ถึง 0 จาก 0 แถว",
          sInfoFiltered: "",
          sSearch: "",
          sUrl: "",
          oPaginate: {
            sFirst: "หน้าแรก",
            sPrevious: "ก่อนหน้า",
            sNext: "ถัดไป",
            sLast: "หน้าสุดท้าย",
          },
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
        },
      })
    );

    // Datable Component CSS.
    document
      .querySelector("div.dataTables_length select")
      .classList.add("datatable-length");
    document
      .querySelector("div.dataTables_filter input")
      .classList.add("datatable-search");
  };

  // const searchDataTable = () => {
  //     if (dataTable) {
  //         dataTable.ajax.url(config.get('apiUrl') + '/api/v2/employee/datatable').load();
  //     }
  // };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="row mb-3 border-bottom">
              <div className="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-breadcrumb">
                    ผู้ดูแลระบบ
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    ข้อมูลแผนผังหน่วยงาน
                  </li>
                  <li className="breadcrumb-item text-breadcrumb-active">
                    รายละเอียด
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12"></div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="section-wrapper text-normal">
                  <span className="text-header-x2 ddc-color">
                    ข้อมูลแผนผังหน่วยงาน
                  </span>
                  <br />
                  <br />
                  {/* <div>
                    <Button
                      type="button"
                      variant="contained"
                      size="large"
                      className="btn-no-border btn-color-searchv2"
                      style={{
                        display: stateOpen == "false" ? "inline-flex" : "none",
                      }}
                      onClick={() => initOrhChart()}
                    >
                      <i
                        className="fas fa-plus"
                        style={{ paddingRight: "5px" }}
                      ></i>
                      เพิ่มข้อมูล
                    </Button>
                  </div> */}
                  <div style={{ width: "50%", display: "inline-block" }}>
                    <Button
                      type="button"
                      variant="contained"
                      size="large"
                      className="btn-no-border btn-color-searchv2"
                      style={{
                        display: stateOpen == "false" ? "inline-flex" : "none",
                      }}
                      onClick={() => initOrhChart()}
                    >
                      <i
                        className="fas fa-edit"
                        style={{ paddingRight: "5px" }}
                      ></i>
                      แก้ไขเลข สธ.
                    </Button>
                  </div>
                  <div
                    style={{ width: "50%", display: "inline-block" }}
                    align="right"
                  >
                    <Button
                      type="button"
                      variant="contained"
                      size="large"
                      className="btn-no-border btn-color-searchv2"
                      // style={{
                      //   display: stateOpen == "false" ? "inline-flex" : "none",
                      // }}
                      onClick={() => initChange()}
                    >
                      <i
                        className="fas fa-back"
                        style={{ paddingRight: "5px" }}
                      ></i>
                      ย้อนกลับ
                    </Button>
                  </div>
                  <br />
                  <br />
                  <table
                    id="data-table"
                    className="table table-striped table-bordered display w-100 margin-bottom-30"
                  >
                    <thead>
                      <tr>
                        <th className="text-center">รหัสหน่วยงาน</th>
                        <th className="text-center">ชื่อหน่วยงาน</th>
                        <th className="text-center">เลข สธ.</th>
                        <th className="text-center">อัพเดท</th>
                        <th className="text-center">หมายเหตุ</th>
                        <th className="text-center">สถานะ</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        onEntered={() => {
          loadDataTableOrgChart();
        }}
      >
        <Modal.Header closeButton className="bg-color">
          <Modal.Title className="nav-text text-shadow-header">
            แก้ไขเลข สธ.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="container">
                <div className="row mt-3">
                  <div className="col-12 col-md-2 pt-2">
                    <span className="text-color-form align-middle">
                      รหัสหน่วยงาน
                    </span>
                  </div>
                  <div className="col-12 col-md-10 pt-1">
                    <select
                      className="form-control shadow-none"
                      id="organizeLevel"
                      onChange={(e) => {
                        setOnCheangeOrganize(e.target.value);
                      }}
                      disabled
                      value={dataLocation || ""}
                    >
                      <option value="">
                        {dataLocation ? dataLocation : "--- กรุณาเลือก ---"}
                      </option>
                    </select>
                  </div>
                </div>

                {/* <div className="row mt-3">
                  <div className="col-12 col-md-2 pt-2">
                    <span className="text-color-form align-middle">
                      สำนักกอง
                    </span>
                  </div>
                  <div className="col-12 col-md-10 pt-1">
                    <select
                      className="form-control shadow-none"
                      id="organizeLevel"
                      onChange={(e) => {
                        setOnCheangeOrganize(e.target.value);
                      }}
                    >
                      <option value="">--- กรุณาเลือก ---</option>
                      {organizelv1.map((item) => {
                        return (
                          <option key={item.orgId} value={item.orgId}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12 col-md-2 pt-2">
                    <span className="text-color-form align-middle">
                      กลุ่มงาน
                    </span>
                  </div>
                  <div className="col-12 col-md-10 pt-1">
                    <select
                      className="form-control shadow-none"
                      id="parentLevel"
                      onChange={(e) => {
                        setOnChangeParent(e.target.value);
                      }}
                    >
                      <option value="">--- กรุณาเลือก ---</option>
                      {organizelv2.map((item) => {
                        return (
                          <option key={item.orgId} value={item.orgId}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12 col-md-2 pt-2">
                    <span className="text-color-form align-middle">
                      ชื่อหน่วยงาน
                    </span>
                  </div>
                  <div className="col-12 col-md-10 pt-1">
                    <input
                      className="form-control shadow-none fname"
                      type="text"
                      name="fname"
                    />
                  </div>
                </div> */}

                <div className="row mt-3">
                  <div className="col-12 col-md-2 pt-2">
                    <span className="text-color-form align-middle">
                      แก้ไขเลข สธ.
                    </span>
                  </div>
                  <div className="col-12 col-md-10 pt-1">
                    <input
                      className="form-control shadow-none fname"
                      type="text"
                      name="fname"
                      placeholder="กรุณาระบุ"
                      value={sathCode}
                      onChange={(e) => setSathCode(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12 col-md-2 pt-2">
                    <span className="text-color-form align-middle">
                      หมายเหตุ
                    </span>
                  </div>
                  <div className="col-12 col-md-10 pt-1">
                    <input
                      className="form-control shadow-none fname"
                      type="text"
                      name="fname"
                      placeholder="กรุณาระบุ"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              type="button"
              className="btn-color-submit"
              onClick={handleSave}
            >
              <i className="fas fa-save" style={{ paddingRight: "5px" }}></i>
              บันทึก
            </Button>
            <Button
              type="button"
              className="btn-color-delete-small"
              onClick={handleClose}
            >
              <i className="fas fa-ban pr-2"></i>
              ปิด
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OrgDataDetail;