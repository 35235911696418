import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import th from 'date-fns/locale/th';
import pink from '@material-ui/core/colors/pink';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from 'material-ui-thai-datepickers';
import styled from 'styled-components';

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: pink,
  },
  zIndex: {
    modal: 10500001
  }
});

const StyledDatePicker = styled(KeyboardDatePicker)`
  .MuiOutlinedInput-input {
    padding: 9.5px 14px;
  }
  .MuiInputLabel-root {
    padding-left: 1.2em;
  }
`;

function DateInput(props) {
  const [selectedDate, handleDateChange] = useState(new Date());

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={th}>
        <StyledDatePicker
          // autoOk
          // variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          pickerHeaderFormat="eee d MMM"
          yearOffset={543}
          value={selectedDate}
          onChange={handleDateChange}
          className="form-control shadow-none"
          cancelLabel='ยกเลิก'
          okLabel='ตกลง'
          {...props}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default DateInput;
