import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { useKeycloak } from "@react-keycloak/web";
// import config from 'react-global-configuration';
// import axios from 'axios';
import { hasAnyRole } from "./util/AuthUtil";

const Navigation = () => {
  const [keycloak] = useKeycloak();
  const [userRole, setUserRole] = useState("none");
  // const [userRoleAdmin, setUserRoleAdmin] = useState('none');

  const [dashboardRole, setDashboardRole] = useState("none");
  const [recoardRole, setRecoardRole] = useState("none");
  const [leaveRole, setLeaveRole] = useState("none");
  const [bossRole, setBossRole] = useState("none");
  const [reportRole, setReportRole] = useState("none");
  const [adminRole, setAdminRole] = useState("none");
  const [developRole, setDevelopRole] = useState("none");

  const isRole = useCallback(
    (roles) => {
      if (keycloak && roles) {
        return roles.some((r) => {
          const realm = keycloak.hasRealmRole(r);
          const resource = keycloak.hasResourceRole(r);
          return realm || resource;
        });
      }
      return false;
    },
    [keycloak]
  );

  const fetchData = useCallback(async () => {
    if (keycloak) {
      // Go use Keycloak authen setMenuAuthen()
      // let rsUser = await axios.get(
      //   config.get('apiUrl') +
      //     '/api/employee/' +
      //     keycloak.tokenParsed.preferred_username,
      //   {
      //     headers: {
      //       'Content-Type': 'application/x-www-form-urlencoded',
      //       Authorization: `bearer ${keycloak.token}`,
      //     },
      //   }
      // );
      // if (rsUser.data.status === 'success') {
      //   //console.log(rsUser.data.data.role_id[0]);
      //   if (rsUser.data.data.role_id[0] === 1) {
      //     setDashboardRole('block');
      //     setRecoardRole('block');
      //     setLeaveRole('block');
      //     setBossRole('block');
      //     setReportRole('block');
      //     setAdminRole('block');
      //     setUserRole('none');
      //     //console.log(userRole);
      //   } else if (rsUser.data.data.role_id[0] === 3) {
      //     setDashboardRole('block');
      //     setRecoardRole('block');
      //     setLeaveRole('block');
      //     setBossRole('none');
      //     setReportRole('block');
      //     setAdminRole('none');
      //     setUserRole('block');
      //   } else if (rsUser.data.data.role_id[0] === 4) {
      //     setDashboardRole('block');
      //     setRecoardRole('block');
      //     setLeaveRole('block');
      //     setBossRole('block');
      //     setReportRole('block');
      //     setAdminRole('none');
      //     setUserRole('block');
      //     //console.log(userRole);
      //   } else {
      //     setDashboardRole('none');
      //     setRecoardRole('none');
      //     setLeaveRole('none');
      //     setBossRole('none');
      //     setReportRole('none');
      //     setAdminRole('none');
      //     setUserRole('block');
      //   }
      // }
    }
  }, [keycloak]);

  const setMenuAuthen = useCallback(() => {
    if (isRole(["user"])) {
      setDashboardRole("block");
      setRecoardRole("block");
      setLeaveRole("block");
      setBossRole("none");
      setReportRole("none");
      setAdminRole("none");
      setUserRole("block");
      setDevelopRole("none");
    }
    if (isRole(["admin-dep"])) {
      setDashboardRole("block");
      setRecoardRole("block");
      setLeaveRole("block");
      setBossRole("none");
      setReportRole("block");
      setAdminRole("none");
      setUserRole("block");
      setDevelopRole("none");
    }
    if (isRole(["admin"])) {
      setDashboardRole("block");
      setRecoardRole("block");
      setLeaveRole("block");
      setBossRole("none");
      setReportRole("block");
      setAdminRole("block");
      setUserRole("none");
      setDevelopRole("none");
    }
    if (isRole(["boss"])) {
      setDashboardRole("block");
      setRecoardRole("block");
      setLeaveRole("block");
      setBossRole("block");
      setReportRole("block");
      setAdminRole("none");
      setUserRole("block");
      setDevelopRole("none");
    }
    if (isRole(["center"])) {
      setDashboardRole("block");
      setRecoardRole("block");
      setLeaveRole("block");
      setBossRole("none");
      setReportRole("none");
      setAdminRole("none");
      setUserRole("block");
      setDevelopRole("none");
    }
    if (isRole(["develop"])) {
      setDashboardRole("block");
      setRecoardRole("block");
      setLeaveRole("block");
      setBossRole("block");
      setReportRole("block");
      setAdminRole("block");
      setUserRole("block");
      setDevelopRole("block");
    }
  }, [isRole]);

  const getLinkForAdminManageUser = () => {
    let url = "";
    if (hasAnyRole(keycloak, ["admin", "admin-dep"])) {
      url = "/admin-personnel-infomation-datatable";
    } else {
      url = "/admin-personnel-infomation";
    }
    return url;
  };

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview("init");
    if (keycloak.tokenParsed) {
      fetchData();
      setMenuAuthen();
    }
  }, [keycloak, fetchData, setMenuAuthen]);

  return (
    <aside className="main-sidebar elevation-4 sidebar-color">
      <Link to="./dashboard" className="brand-link sidebar-link-color">
        <span className="brand-text font-weight-light">&nbsp;</span>
      </Link>
      <Scrollbars style={{ height: "100vh" }}>
        <div className="sidebar sidebar-color">
          <nav className="mt-4">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="true"
            >
              <li className="nav-item" style={{ display: dashboardRole }}>
                <Link
                  to="./dashboard"
                  className="nav-link sidebar-link-color nvClick"
                >
                  <i className="nav-icon fas fa-home sidebar-fa-size-header" />
                  <p className="pl-2">หน้าหลัก</p>
                </Link>
              </li>
              <li
                className="nav-item has-treeview"
                style={{ display: recoardRole }}
              >
                <Link to="#" className="nav-link sidebar-link-color">
                  <i className="nav-icon far fa-clock sidebar-fa-size-header" />
                  <p className="pl-2">
                    บันทึกเวลาทำงาน
                    <i className="fas fa-angle-left right" />
                  </p>
                </Link>
                <ul
                  className="nav nav-treeview"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="true"
                >
                  {/* start ปิดเมนู วันที่ 12/10/2023 */}
                  {/*<li className="nav-item">*/}
                  {/*    <Link*/}
                  {/*        to="./recored-outing"*/}
                  {/*        className="nav-link sidebar-link-color nvClick"*/}
                  {/*    >*/}
                  {/*        <p>ขออนุมัติทำงานนอกสถานที่</p>*/}
                  {/*    </Link>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*    <Link*/}
                  {/*        to="./recored-list"*/}
                  {/*        className="nav-link sidebar-link-color nvClick"*/}
                  {/*    >*/}
                  {/*        <p>*/}
                  {/*            ตรวจสอบขอทำงาน*/}
                  {/*            <br/>*/}
                  {/*            นอกสถานที่*/}
                  {/*        </p>*/}
                  {/*    </Link>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*    <Link*/}
                  {/*        to="./recored-checkin"*/}
                  {/*        className="nav-link sidebar-link-color nvClick"*/}
                  {/*    >*/}
                  {/*        <p>บันทึกเวลาเข้า</p>*/}
                  {/*    </Link>*/}
                  {/*</li>*/}
                  {/*<li className="nav-item">*/}
                  {/*    <Link*/}
                  {/*        to="./recored-checkout"*/}
                  {/*        className="nav-link sidebar-link-color nvClick"*/}
                  {/*    >*/}
                  {/*        <p>บันทึกเวลาออก</p>*/}
                  {/*    </Link>*/}
                  {/*</li>*/}
                  {/* end ปิดเมนู วันที่ 12/10/2023 */}
                  <li className="nav-item">
                    <Link
                      to="./recored-summary"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>สถิติ ขาด ลา มาสาย</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className="nav-item has-treeview"
                style={{ display: leaveRole }}
              >
                <Link to="#" className="nav-link sidebar-link-color">
                  <i className="nav-icon fas fa-suitcase sidebar-fa-size-header" />
                  <p className="pl-2">
                    ระบบลา
                    <i className="right fas fa-angle-left" />
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="./leave-rights"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>ตรวจสอบสิทธิการลา</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="./leave-form"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>สร้างหนังสือขออนุมัติลา</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="./leave-status"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>ตรวจสอบสถานะหนังสือ</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="./leave-cancel"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>ขอยกเลิกการลา</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className="nav-item has-treeview"
                style={{ display: bossRole }}
              >
                <Link to="#" className="nav-link sidebar-link-color">
                  <i className="nav-icon far fa-calendar-check sidebar-fa-size-header" />
                  <p className="pl-2">
                    ผู้บังคับบัญชา
                    <i className="fas fa-angle-left right sidebar-fa-size-header" />
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="./supervisor-list"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>รายชื่อผู้ใต้บังคับบัญชา</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="./supervisor-right"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>สิทธิการลาผู้ใต้บังคับบัญชา</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="./supervisor-approve"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>อนุมัติทำงานนอกสถานที่</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="./supervisor-approve-leave"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>อนุมัติการลา</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className="nav-item has-treeview"
                style={{ display: reportRole }}
              >
                <Link to="#" className="nav-link sidebar-link-color">
                  <i className="nav-icon fas fa-file-invoice sidebar-fa-size-header" />
                  <p className="pl-2">
                    รายงาน
                    <i className="fas fa-angle-left right" />
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="./admin-dashboard"
                      className="nav-link sidebar-link-color nvClick"
                      style={{
                        display: !isRole(["admin"]) && "none",
                      }}
                    >
                      <p>ภาพรวบระบบ</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="./report-time-attendance-summary"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>สรุปจำนวนบุคลากรขาด ลา มาสาย</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="./report-leave-summary"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>สรุปจำนวนการขอลา</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="./report-leave"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>การลาของบุคลากรรายบุคคล</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="./report-working-in-out"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>ตารางเข้างาน ออกงาน</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className="nav-item has-treeview"
                style={{ display: adminRole }}
              >
                <Link to="#" className="nav-link sidebar-link-color">
                  <i className="nav-icon fas fa-users-cog sidebar-fa-size-header" />
                  <p className="pl-2">ผู้ดูแลระบบ</p>
                  <i className="fas fa-angle-left right" />
                </Link>
                {/*<ul className="nav nav-treeview">*/}
                {/*  <li className="nav-item">*/}
                {/*    <Link*/}
                {/*        to="./admin"*/}
                {/*        className="nav-link sidebar-link-color nvClick"*/}
                {/*    >*/}
                {/*      <p>การจัดการข้อมูล</p>*/}
                {/*    </Link>*/}
                {/*  </li>*/}
                {/*  <li className="nav-item" style={{display:'none'}}>*/}
                {/*    <Link*/}
                {/*        to="./admin-user-management"*/}
                {/*        className="nav-link sidebar-link-color nvClick"*/}
                {/*    >*/}
                {/*      <p>จัดการสิทธิการเข้าใช้งานระบบ</p>*/}
                {/*    </Link>*/}
                {/*  </li>*/}
                {/*</ul>*/}
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to={getLinkForAdminManageUser()}
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>ข้อมูลบุคลากร</p>
                    </Link>
                    <Link
                      to="./admin-work-schedule"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>ตารางเวลาการทำงาน</p>
                    </Link>
                    <Link
                      to="./admin-personnel-type"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>ประเภทบุคลากร</p>
                    </Link>
                    <Link
                      to="./admin-annual-holiday"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>วันหยุดประจำปี</p>
                    </Link>
                    <Link
                      to="/admin-leave-type"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>ประเภทการลา</p>
                    </Link>
                    <Link
                      to="./admin-main-mas-organization"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>ข้อมูลองค์กร</p>
                    </Link>
                  </li>
                  <li className="nav-item" style={{ display: "none" }}>
                    <Link
                      to="./admin-user-management"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>จัดการสิทธิการเข้าใช้งานระบบ</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="./admin-card-process"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>ประมวลผลสแกนบัตร</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="./admin-main-mas-expert"
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>ความเชี่ยวชาญ</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"./admin-main-mas-mission"}
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>กลุ่มภารกิจ</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"./admin-main-hazard-mission"}
                      className="nav-link sidebar-link-color nvClick"
                    >
                      <p>โรค/ภัยสุขภาพ</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item" style={{ display: userRole }}>
                <Link
                  to={getLinkForAdminManageUser()}
                  className="nav-link sidebar-link-color nvClick"
                >
                  <i className="nav-icon fas fa-users-cog sidebar-fa-size-header" />
                  <p className="pl-2">แก้ไขข้อมูล</p>
                </Link>
              </li>
              <li className="nav-item" style={{ display: developRole }}>
                <Link
                  to={"./develop-org-data-table"}
                  className="nav-link sidebar-link-color nvClick"
                >
                  <i className="nav-icon fas fa-sitemap sidebar-fa-size-header" />
                  <p className="pl-2">แผนผังหน่วยงาน</p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </Scrollbars>
    </aside>
  );
};

export default Navigation;
